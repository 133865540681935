import { browserHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Map } from 'immutable';

import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import Page from 'components/Pages/container';

import MessageBlock from 'components/MessageBlock';

import DineInLocation from 'components/DineInLocation';

import TabOrderSummary from 'components/DineIn/TabOrderSummary';

import { selectServiceId } from 'selectors/browse';
import { selectCurrentMenuTypes, selectDineInPasscode } from 'selectors/root';
import { selectSession, selectTabOrder, SessionState } from 'selectors/session';
import { fetchTab } from 'actions/tab';
import { fetchSession } from 'actions/session';
import dineInMessages from 'components/dineInMessages';
import messages from 'components/DineInConfirmation/messages';
import { CartIcon } from 'components/Icons';
import useInterval from 'hooks/useInterval';
import TabLoading from '../../Tabs/TabLoading';
import { StyledButton } from './styles';

interface Props {
  fetchSession: () => Promise<void>;
  fetchTab: () => Promise<void>;
}

const TabOrder: React.FC<Props> = ({ fetchSession, fetchTab }) => {
  const [loading, setLoading] = useState(true);
  const [firstRender, setFirstRender] = useState(true);

  const serviceId = useSelector(selectServiceId);
  const order = useSelector(selectTabOrder);
  const session = useSelector<Map<string, any>, SessionState>(state => selectSession(state, { serviceId }));
  const passcode = useSelector(selectDineInPasscode);
  const menuTypes = useSelector(selectCurrentMenuTypes);

  const returnToMenu = useCallback(() => browserHistory.replace(`/service/${serviceId}`), [serviceId]);
  const noOrder = useCallback(
    () => browserHistory.replace(`/service/${serviceId}/order/no-order`),
    [serviceId]
  );

  // initial fetch
  useEffect(() => {
    if (firstRender) {
      try {
        !session.size ? fetchSession().then(fetchTab) : fetchTab();
        setFirstRender(false);
      } catch (error) {
        // if all else fails, return home. We should probably do something else here, like redirect to no tab order, but this keeps the storyshots happy for now
        browserHistory.push('/');
      }
    }
  }, [fetchSession, fetchTab, firstRender, session.size]);

  // listen for changes on the order
  useEffect(() => {
    // redirect to thankyou on tab closed
    const state = order.get('state');
    if (state && state === 'closed') browserHistory.replace('/thankyou/tab');

    const items = order.get('items');
    if (items) {
      if (!items.size) {
        // if menus exist, return to menu, otherwise noOrder page
        menuTypes.size ? returnToMenu() : noOrder();
      } else setLoading(false);
    }
  }, [noOrder, order, returnToMenu, serviceId, menuTypes.size]);

  // poll tab once loaded
  useInterval(fetchTab, 5000, !loading);

  return (
    <Page
      settingsPage={true}
      titleMessage={!loading ? dineInMessages.currentTab : null}
      hideBackButton={true}
      Icon={!loading ? CartIcon : null}
    >
      {loading && <TabLoading />}
      {!loading && (
        <>
          <DineInLocation />
          <TabOrderSummary order={order} />
          {menuTypes.size > 0 && (
            <StyledButton
              to={`/service/${serviceId}`}
              label={<FormattedMessage {...dineInMessages.addItems} />}
              primaryButton={true}
            />
          )}
          <StyledButton
            to={`/checkout/tab/${session.get('id')}`}
            forceReload={true}
            label={<FormattedMessage {...dineInMessages.payBill} />}
          />
          {!passcode && menuTypes.size > 0 && (
            <MessageBlock body={<FormattedMessage {...messages.passcodeMsg} />} type="info" />
          )}
        </>
      )}
    </Page>
  );
};

export default connect(null, { fetchSession, fetchTab })(TabOrder);
