import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Formik } from 'formik';

import { EnterPasscode } from 'components/Passcode';
import { AddressSelection, AddressInRadius } from 'components/Address';
import { kioskModeEnabled } from 'selectors/features';
import { setDetail } from 'actions/storage';
import { getFormValues } from 'selectors/order';
import Field from 'components/Form/Field';
import FieldLabel from 'components/Form/Fields/Label';
import TelephoneField from 'components/Form/Fields/Telephone';
import Notes from 'components/Form/Fields/Notes';
import NewPassword from 'components/Form/Fields/NewPassword';
import CollectionPoints from 'components/Form/Fields/CollectionPoints';
import CustomFieldsMultiChoice from 'components/Form/Fields/CustomFieldsMultiChoice';
import LocationName from 'components/Form/Fields/LocationName';
import Donations from 'components/Donations';
import Cutlery from 'components/Cutlery';
import Payment from 'components/Payment';
import OrderVoucherField from 'components/OrderReview/OrderSummary/OrderVoucherField';
import OrderSummaryField from 'components/OrderReview/OrderSummary/CurrentOrderSummary';
import FulfilmentDateTime from 'components/Form/Fields/FulfilmentDateTime';
import Message from 'components/Form/Fields/Message';
import DropdownField from 'components/Form/Fields/DropdownField';
import TermsCheckbox from 'components/TermsAndConditions/termsCheckbox';
import AllergenCheckbox from 'components/AllergenCheckbox';
import ConsentCheckbox from 'components/ConsentCheckbox';
import ThirdPartyPermission from 'components/ThirdPartyPermission';
import FulfilmentNotes from 'components/FulfilmentNotes';
import ActionButton from 'components/ActionButton';
import MessageBlock from 'components/MessageBlock';
import globalMessages from 'components/globalMessages';
import EarnableLoyaltyPoints from 'components/Loyalty/EarnableLoyaltyPoints';
import styled from 'styled-components';
import TabOrderSummaryField from '../OrderReview/OrderSummary/TabOrderSummaryField';
import { validateFields } from './utils';

import { FormContent } from './styles';
import { updateOrderDetails } from '../../actions/order';
import FormattedPrice from '../FormattedPrice';

const components = {
  Notes,
  NewPassword,
  TelephoneField,
  CollectionPoints,
  CustomFieldsMultiChoice,
  FulfilmentDateTime,
  Message,
  DropdownField,
  AddressSelection,
  Donations,
  Cutlery,
  Payment,
  OrderSummaryField,
  OrderVoucherField,
  TabOrderSummaryField,
  LocationName,
  ConsentCheckbox,
  TermsCheckbox,
  ThirdPartyPermission,
  FulfilmentNotes,
  AddressInRadius,
  EnterPasscode,
  AllergenCheckbox,
  EarnableLoyaltyPoints,
};

export const StickyButton = styled.div`
  position: sticky;
  bottom: 10px;
  z-index: 100;
`;

const OrderInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border-top: 1px solid ${({ theme }) => theme.color.borderColor};
`;

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.formik = React.createRef();
    this.fieldRefs = [];
  }

  state = { showError: false, showSuccess: false };

  componentDidMount() {
    this.formik.current.runValidations();
  }

  componentDidUpdate(prevProps) {
    if (!this.formik.current) return;

    if (JSON.stringify(prevProps.fields) !== JSON.stringify(this.props.fields)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        showError: false,
      });
      this.formik.current.resetForm(this.props.values);
      this.formik.current.runValidations(this.props.values);
    }

    if (JSON.stringify(prevProps.errors) !== JSON.stringify(this.props.errors)) {
      this.serverValidate().then(status => this.formik.current.setStatus(status));
    }
  }

  scrollToError = () => {
    if (!this.formik.current) return;
    const { errors, status } = this.formik.current.state;
    if (Object.keys(errors).length || (status && Object.keys(status).length)) {
      const field = this.props.fields.find(field => errors[field.name] || (status && status[field.name]));
      if (field && this.fieldRefs[field.name]) {
        this.fieldRefs[field.name].scrollIntoView({ block: 'center' });
      } else this.form.scrollIntoView();
    }
  };

  hasRequiredField = Object.keys(this.props.fields).some(key => this.props.fields[key].required === true);

  onChange = (value, field, setFieldValue, setFieldTouched) => {
    setFieldValue(field.name, value);
    if (this.props.onChange) this.props.onChange(value, field);
    setFieldTouched(field.name, true);
    if (field.storeDetail) this.props.setDetail(field.name, value, field.expiry);
  };

  onBlur = (value, field, setFieldTouched) => {
    if (this.props.handleBlur) this.props.handleBlur(value, field);
    if (setFieldTouched) setFieldTouched(field.name, true);
    if (field.updateOrderOnBlur) this.props.updateOrderDetails();
  };

  validate = values => this.props.validateFields(values, this.props.fields, this.props.intl.formatMessage);

  serverValidate = () =>
    new Promise(resolve => {
      const status = {};
      Object.keys(this.props.errors).forEach(key => {
        const field = this.props.fields.find(field => field.name === key || field.errorKey === key);
        if (field && field.name !== 'items') {
          status[field.name] = this.props.errors[key].map(e => e.message);
        }
      });
      resolve(status);
    });

  render() {
    return (
      <div
        ref={c => {
          this.form = c;
        }}
      >
        <Formik
          ref={this.formik}
          initialValues={this.props.values}
          validateOnChange={true}
          validate={values => this.validate(values)}
          onSubmit={(values, { setSubmitting }) => {
            this.setState({ showError: false, showSuccess: false });
            this.props.onSubmit(values, res => {
              if (res?.success && this.props.successMessage) {
                this.setState({
                  showSuccess: true,
                });
              }
              if ((!res?.success || res?.error) && this.props.errorMessage) {
                this.setState({
                  showError: true,
                });
                setTimeout(() => this.scrollToError(), 40);
              }
              setSubmitting(false);
            });
          }}
          isInitialValid={false}
          render={({
            values,
            errors,
            touched,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
            handleSubmit,
            status,
          }) => (
            <form
              autoComplete={this.props.kioskMode ? 'off' : 'on'}
              className={this.props.className}
              onSubmit={e => {
                this.scrollToError();
                handleSubmit(e);
              }}
            >
              <FormContent widthLimited={this.props.widthLimited}>
                {this.props.fields.map((field, i) => {
                  switch (field.type) {
                    case 'SubHeader':
                      return (
                        <div key={i}>
                          <FieldLabel {...field} requiredFields={this.hasRequiredField} />
                        </div>
                      );
                    case 'component':
                      return (
                        <div
                          ref={c => {
                            this.fieldRefs[field.name] = c;
                          }}
                          key={i}
                        >
                          <Field
                            field={field}
                            value={values[field.name] ?? ''}
                            error={errors[field.name]}
                            status={status && status[field.name]}
                            touched={touched[field.name]}
                            onBlur={value => {
                              this.onBlur(value, field, setFieldTouched);
                            }}
                            onChange={value => this.onChange(value, field, setFieldValue, setFieldTouched)}
                            component={components[field.component]}
                            isSubmitting={isSubmitting}
                            showLabel={field.showLabel}
                          />
                        </div>
                      );
                    default: {
                      return (
                        <div
                          ref={c => {
                            this.fieldRefs[field.name] = c;
                          }}
                          key={i}
                        >
                          <Field
                            field={field}
                            value={values[field.name] || ''}
                            error={errors[field.name]}
                            status={status && status[field.name]}
                            touched={touched[field.name]}
                            onBlur={e => {
                              handleBlur(e);
                              this.onBlur(e.target.value, field);
                            }}
                            onChange={e =>
                              this.onChange(e.target.value, field, setFieldValue, setFieldTouched)
                            }
                            showLabel={true}
                          />
                        </div>
                      );
                    }
                  }
                })}

                {this.state.showSuccess && (
                  <MessageBlock
                    header={this.props.successHeader}
                    body={this.props.successMessage}
                    type="success"
                  />
                )}
                {this.state.showError && (
                  <MessageBlock
                    header={this.props.errorHeader || <FormattedMessage {...globalMessages.error} />}
                    body={this.props.errorMessage}
                    type="error"
                  />
                )}

                {!this.state.showError && this.props.showManualError && (
                  <MessageBlock
                    header={<FormattedMessage {...globalMessages.error} />}
                    body={this.props.errorMessage}
                    type="error"
                  />
                )}

                {this.props.children}
              </FormContent>
              <StickyButton>
                <OrderInfoContainer className="primaryBackground">
                  <div>
                    <FormattedMessage {...globalMessages.total} />
                  </div>
                  <div>
                    <FormattedPrice value={this.props.orderTotal} />
                  </div>
                </OrderInfoContainer>
                <ActionButton
                  primaryButton={true}
                  loading={isSubmitting}
                  buttonType="submit"
                  disabled={isSubmitting || this.props.disabled}
                  label={
                    isSubmitting
                      ? this.props.submitButtonLoadingLabel || (
                          <FormattedMessage {...globalMessages.loading} />
                        )
                      : this.props.submitButtonLabel
                  }
                  buttonIcon={this.props.submitButtonIcon || null}
                />
              </StickyButton>
            </form>
          )}
        />
      </div>
    );
  }
}

Form.propTypes = {
  className: PropTypes.string,
  values: PropTypes.object,
  fields: PropTypes.array.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  handleBlur: PropTypes.func,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  children: PropTypes.node,
  onSubmit: PropTypes.func.isRequired,
  errorHeader: PropTypes.node,
  errorMessage: PropTypes.node,
  successHeader: PropTypes.node,
  successMessage: PropTypes.node,
  submitButtonLoadingLabel: PropTypes.node,
  submitButtonIcon: PropTypes.node,
  submitButtonLabel: PropTypes.node,
  validateFields: PropTypes.func,
  setDetail: PropTypes.func.isRequired,
  kioskMode: PropTypes.bool,
  noButtonIcon: PropTypes.bool,
  widthLimited: PropTypes.bool,
  disabled: PropTypes.bool,
  showManualError: PropTypes.bool,
  updateOrderDetails: PropTypes.func,
  orderTotal: PropTypes.number,
};

const mapStateToProps = (state, { fields, values }) => ({
  kioskMode: kioskModeEnabled(state),
  values: getFormValues(state, fields, values),
  validateFields: (values, fields, formatMessage) => validateFields(state, values, fields, formatMessage),
});

const mapDispatchToProps = dispatch => ({
  setDetail: (name, value, expiry) => dispatch(setDetail(name, value, expiry)),
  updateOrderDetails: details => dispatch(updateOrderDetails(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form));
