import styled from 'styled-components';

import { StyledInput } from 'assets/styles/sharedStyles';
import SearchIcon from 'components/Icons/SearchIcon';

export const StyledSearch = styled.div`
  margin: 0;
  position: relative;
`;

export const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  left: 10px;
  top: 8px;
  z-index: ${({ theme }) => theme.zIndex.three};

  html[dir='rtl'] & {
    right: 10px;
    left: 0;
  }
`;

export const SearchingIcon = styled.div`
  position: absolute;
  right: 50px;
  top: 10px;
  z-index: ${({ theme }) => theme.zIndex.six};

  html[dir='rtl'] & {
    right: inherit;
    left: 50px;
  }
`;

export const StyledCloseIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 7px;
  z-index: ${({ theme }) => theme.zIndex.six};

  html[dir='rtl'] & {
    right: inherit;
    left: 10px;
  }
`;

export const SearchInput = styled.input`
  ${StyledInput}

  padding: 7px;
  padding-left: 40px;
  border-color: ${({ theme }) => theme.color.borderGray};
  overflow: hidden;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #999;
  }

  html[dir='rtl'] & {
    padding-left: 9px;
    padding-right: 40px;
  }
`;

export const ResultsContainer = styled.div`
  position: absolute;
  top: 65px;
  left: -45px;
  z-index: ${({ theme }) => theme.zIndex.ten};
  width: calc(100% + 25px);
  margin: 0 10px;
  box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.3);
  background-color: ${({ theme }) => theme.color.primaryBackground};
  color: ${({ theme }) => theme.color.text};

  ::before {
    position: absolute;
    display: inline-block;
    border-left: 15px solid transparent;
    border-bottom: 20px solid ${({ theme }) => theme.color.inputBackground};
    border-right: 15px solid transparent;
    transform: rotate(0deg) translateX(-10px);
    left: 80px;
    top: -20px;
    content: '';
    filter: drop-shadow(-1px -3px 2px rgba(0, 0, 0, 0.2));
  }
`;

export const Results = styled.ul`
  padding: 20px 10px;
  max-height: 70vh;
  overflow: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  list-style: none;
`;

export const NoResult = styled.div`
  padding: 5px;
`;
