import React, { ReactNode } from 'react';
import { image } from 'utils';

import FormattedPrice from 'components/FormattedPrice';
import styled from 'styled-components';
import {
  ProductListingWrapper,
  DescriptionWrapper,
  ProductDetails,
  ProductImageWrapper,
  TextWrapper,
  NameWrapper,
} from './styles';
import { Badge } from './Badge';
import { ChilliIcon } from '../Chilli/ChilliIcon';

interface Props {
  name: string;
  description: ReactNode;
  imageSrc: string;
  price: number;
  allergens: ReactNode;
  disabledMessage?: ReactNode;
  onClick?: () => void;
  quantity?: number;
  spiceLevel: 0 | 1 | 2 | 3;
}

export const DisabledMessage = styled.span`
  color: ${({ theme }) => theme.color.danger};
`;

const ProductListing: React.FC<Props> = ({
  name,
  description,
  imageSrc,
  price,
  allergens,
  onClick,
  quantity,
  spiceLevel,
  disabledMessage,
}) => (
  <ProductListingWrapper onClick={onClick}>
    {imageSrc && (
      <ProductImageWrapper>
        {quantity > 0 && <Badge quantity={quantity} />}
        <img src={image(imageSrc, { height: 400 })} alt={name} />
      </ProductImageWrapper>
    )}
    <DescriptionWrapper>
      <TextWrapper>
        <NameWrapper>
          <p className="name">{name}</p> <ChilliIcon amount={spiceLevel} />
        </NameWrapper>

        <p className="description">{description}</p>
      </TextWrapper>
      <ProductDetails>
        {disabledMessage ? (
          <DisabledMessage>{disabledMessage}</DisabledMessage>
        ) : (
          <FormattedPrice value={price} />
        )}
        {allergens}
      </ProductDetails>
    </DescriptionWrapper>
  </ProductListingWrapper>
);

export default ProductListing;
