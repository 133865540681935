import styled from 'styled-components';

import AllergenIcon from 'components/AllergenIcon';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0 3px;
`;

export const StyledAllergenIcon = styled(AllergenIcon)`
  color: ${({ theme }) => theme.color.brandPrimary};
  background-color: ${({ theme }) => theme.buttons.primaryBg};
  margin: 3px;
  padding: 2px;
  height: 25px;
  width: 25px;

  &:last-child {
    margin-right: 0;
  }
`;
