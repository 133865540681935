import styled from 'styled-components';
import { fontSizeBig } from 'assets/styles/fonts';

interface PlusMinusButtonProps {
  icon: 'minus' | 'plus';
}

export const Header = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-block: 1rem;
  cursor: pointer;
  html[dir='rtl'] & {
    flex-direction: row-reverse;
  }
  & > p {
    color: ${({ theme }) => theme.color.primaryText};
    ${fontSizeBig}
  }
`;

export const PlusMinusButton = styled.button<PlusMinusButtonProps>`
  display: grid;
  & > * {
    grid-area: 1/1;
    transition: transform 200ms ease-out;
    &:nth-child(1) {
      transform: rotate(${({ icon }) => (icon === 'minus' ? '0deg' : '-180deg')});
      html[dir='rtl'] & {
        transform: rotate(${({ icon }) => (icon === 'minus' ? '0deg' : '180deg')});
      }
    }
    &:nth-child(2) {
      transform: rotate(${({ icon }) => (icon === 'minus' ? '0deg' : '-90deg')});
      html[dir='rtl'] & {
        transform: rotate(${({ icon }) => (icon === 'minus' ? '0deg' : '90deg')});
      }
    }
  }
`;
