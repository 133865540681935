import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { selectGratuityMessageByLocale } from 'selectors/root';
import NotesIcon from 'components/Icons/NotesIcon';
import ProductPrice from 'components/Product/price';
import { promotionType, gratuityType } from 'selectors/orderItemTypes';

import messages from '../../../Pages/User/Orders/messages';
import { CellsContainer, Cell, ProductName, SubCell, Modifier, Price, Italic } from './styles';

const OrderItem = ({ item, gratuityMessageByLocale }) => {
  const modifiers = item.get('modifiers');
  const hasNotes = item.get('notes');
  const gratuityName = gratuityMessageByLocale || item.get('name');

  return (
    <CellsContainer>
      <Cell fillSpace={true}>
        <ProductName>
          <FormattedMessage
            {...messages.lineItem}
            values={{
              formattedNumber: <FormattedNumber value={item.get('quantity')} />,
              separator: <span className="lowercase">x</span>,
              promotion: item.get('type') === promotionType && <FormattedMessage {...messages.promotion} />,
              name: item.get('type') === gratuityType ? gratuityName : item.get('name'),
            }}
          />
        </ProductName>

        {modifiers &&
          modifiers.map((itemModifier, modifierIndex) =>
            itemModifier?.get('values')?.map((itemValue, index) => (
              <Modifier key={index}>
                {itemValue.get('value')}
                {modifierIndex + 1 < modifiers.size && <span>, </span>}
              </Modifier>
            ))
          )}

        {hasNotes ? (
          <SubCell>
            <NotesIcon />
            <Italic>
              <FormattedMessage {...messages.has_notes} />
            </Italic>
          </SubCell>
        ) : null}
      </Cell>

      <Price>
        <ProductPrice price={item.get('total')} />
      </Price>
    </CellsContainer>
  );
};

OrderItem.propTypes = {
  item: PropTypes.instanceOf(Map),
  gratuityMessageByLocale: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default connect(state => ({
  gratuityMessageByLocale: selectGratuityMessageByLocale(state),
}))(OrderItem);
