import styled from 'styled-components';
import { Link } from 'react-router';

export const FilteredMessage = styled.div`
  padding: 10px;
  background-color: ${({ theme }) => theme.color.secondaryBackground};
  color: ${({ theme }) => theme.color.text};
  text-align: center;
  position: relative;
  margin-top: 5px;
  border: 1px solid ${({ theme }) => theme.color.borderColor};

  @media (min-width: 768px) {
    margin: 5px 5px 0;
  }
`;

export const StyledLink = styled(Link)`
  display: inline-block;
  margin-top: 5px;
`;
