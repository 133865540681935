import styled, { css } from 'styled-components';

export default styled.button`
  border-radius: 5px;
  background: ${({ theme }) => theme.color.secondaryBackground};
  font-size: ${({ theme }) => theme.fonts.fontSizeMedium};
  font-weight: bold;
  color: ${({ theme }) => theme.color.text};
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 7px 10px 0;
  flex-wrap: nowrap;

  svg {
    width: 20px;
    margin-right: 5px;
    padding: 3px 0;
    color: ${({ theme }) => theme.color.brandSecondary};
    fill: ${({ theme }) => theme.color.brandSecondary};

    html[dir='rtl'] & {
      margin-right: 0;
      margin-left: 5px;
    }
  }

  ${props =>
    !props.smallBtn &&
    css`
      :last-of-type {
        margin-right: 0;

        html[dir='rtl'] & {
          margin-right: 7px;
        }
      }

      :first-of-type {
        html[dir='rtl'] & {
          margin-right: 0;
        }
      }
    `}

  ${props =>
    props.smallBtn &&
    css`
      display: block;
      text-align: center;
      margin: 0;
      padding: 7px;
      background: none;
      border: none;

      & svg {
        border: 1px solid currentColor;
        border-radius: 50%;
        height: 35px;
        padding: 5px;
        margin: 0;
      }
    `}
`;
