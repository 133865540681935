import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { removeItem, editItem } from 'actions/order';
import {
  selectOrderTotal,
  selectOrderTaxTotal,
  selectOrderTaxTotals,
  returnStackedPromotions,
  validatedOrderItems,
  getOrderChargeItems,
} from 'selectors/order';
import { shouldShowPriceExcludingTax } from 'selectors/features';
import DineInLocation from 'components/DineInLocation';
import FormattedPrice from 'components/FormattedPrice';
import InfoBox from 'components/InfoBox';
import { addCssPrefixTo } from 'utils';
import EarnableLoyaltyPoints from 'components/Loyalty/EarnableLoyaltyPoints';
import Loading from '../Loading';
import OrderItem from './OrderItem';
import OrderLineItem from './OrderLineItem';
import TaxBreakdown from './TaxBreakdown';
import messages from './messages';
import { ReviewContainer, TaxBreakdownContainer, SubtotalContainer } from './styles';

const propTypes = {
  editItem: PropTypes.func,
  removeItem: PropTypes.func,
  orderTotal: PropTypes.number,
  taxTotal: PropTypes.number,
  taxBreakdown: PropTypes.instanceOf(List),
  productItems: PropTypes.instanceOf(List),
  promotionalItems: PropTypes.instanceOf(List),
  chargeItems: PropTypes.instanceOf(List),
  isTaxExcluded: PropTypes.bool,
};

const OrderReview = ({
  productItems,
  promotionalItems,
  chargeItems,
  editItem,
  removeItem,
  orderTotal,
  taxTotal,
  taxBreakdown,
  isTaxExcluded,
}) => {
  if (!productItems) return <Loading />;

  return (
    <>
      <DineInLocation />
      {!productItems.size ? (
        <InfoBox message={<FormattedMessage {...messages.emptyOrder} />} />
      ) : (
        <ReviewContainer className={addCssPrefixTo('REVIEW_CONTAINER')}>
          {productItems.map((item, index) => (
            <OrderItem
              key={index}
              item={item}
              onEdit={() => editItem(index)}
              onRemove={() => removeItem(index, item)}
            />
          ))}

          {promotionalItems.map((item, index) => (
            <OrderItem key={index} item={item} />
          ))}

          {chargeItems.map((item, index) => (
            <OrderLineItem
              key={index}
              message={item.get('name')}
              price={<FormattedPrice value={item.get('unit_price')} />}
            />
          ))}

          {taxTotal !== 0 && !isTaxExcluded && (
            <TaxBreakdownContainer>
              <TaxBreakdown taxBreakdown={taxBreakdown} taxTotal={taxTotal} />
            </TaxBreakdownContainer>
          )}

          <SubtotalContainer>
            <OrderLineItem
              message={<FormattedMessage {...messages.subTotal} />}
              price={<FormattedPrice value={orderTotal} />}
            />
          </SubtotalContainer>

          {taxTotal !== 0 && isTaxExcluded && (
            <TaxBreakdownContainer>
              <TaxBreakdown taxBreakdown={taxBreakdown} taxTotal={taxTotal} isTaxExcluded={true} />
            </TaxBreakdownContainer>
          )}
        </ReviewContainer>
      )}
      <EarnableLoyaltyPoints />
    </>
  );
};

OrderReview.propTypes = propTypes;

const mapStateToProps = (state, { productItems, orderTotal }) => ({
  productItems: productItems || validatedOrderItems(state),
  promotionalItems: returnStackedPromotions(state),
  chargeItems: getOrderChargeItems(state),
  orderTotal: orderTotal || selectOrderTotal(state),
  taxTotal: selectOrderTaxTotal(state),
  taxBreakdown: selectOrderTaxTotals(state),
  isTaxExcluded: shouldShowPriceExcludingTax(state),
});

const getState = dispatch =>
  new Promise(resolve => {
    dispatch((dispatch, getState) => {
      resolve(getState());
    });
  });

const mapDispatchToProps = dispatch => ({
  removeItem: (index, item) => dispatch(removeItem(index, item)),
  editItem: async index => {
    const state = await getState(dispatch);
    return dispatch(editItem(index, state));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderReview);
