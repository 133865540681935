const buildProductsList = (crossSellsList, activeMenus) => {
  if (crossSellsList?.size === 0) return crossSellsList;

  return crossSellsList
    .map(crossSellsItem => {
      const activeMenu = activeMenus.find(menu => menu.get('id') === crossSellsItem.get('menuId'));

      if (!activeMenu) return null;

      const sectionId = activeMenu
        .get('sections')
        .find(
          section =>
            section
              .get('section_products')
              .find(product => product.get('id') === crossSellsItem.get('id')) !== undefined
        )
        ?.get('id');

      return crossSellsItem.set('sectionId', sectionId);
    })
    .filter(product => product !== null);
};

export default buildProductsList;
