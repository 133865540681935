import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import AuraLogo from '../Icons/AuraLogo';
import { selectLoyaltyTypeByService } from '../../selectors/loyalty';
import { selectServiceId } from '../../selectors/browse';

export const Container = styled.div`
  cursor: pointer;
  width: 90px;
  height: 48px;
  position: relative;
  padding: 5px;

  svg {
    fill: #fff;
  }
`;

const propTypes = {
  type: PropTypes.oneOf(['aura']),
};

const LoyaltyButton = ({ type }) => {
  let Icon;

  switch (type) {
    case 'aura':
      Icon = AuraLogo;
      break;
    default:
      return null;
  }

  return (
    <Container>
      <Icon width="80px" height="38px" />
    </Container>
  );
};

LoyaltyButton.propTypes = propTypes;

export default connect(state => {
  const serviceId = selectServiceId(state);

  return {
    type: selectLoyaltyTypeByService(state, serviceId),
  };
})(LoyaltyButton);
