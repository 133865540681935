import { rgba } from 'polished';

const fonts = {
  inputLabelFontSize: '0.75em',
  inputTextFontSize: '1em',
  fontSizeBig: '1em',
  fontSizeBigOne: '1.125em',
  fontSizeMedium: '0.750em',
  fontSizeMediumOne: '0.875em',
  fontSizeSmall: '0.625em',
  baseFontName: 'Lato',
  baseFontSize: '16px',
  headlineFontName: 'inherit',
  headline1FontSize: '24px',
  headline2FontSize: '24px',
  headline3FontSize: '21px',
  headline4FontSize: '18px',
};

const zIndex = {
  one: 1 * 10,
  two: 2 * 10,
  three: 3 * 10,
  four: 4 * 10,
  five: 5 * 10,
  six: 6 * 10,
  seven: 7 * 10,
  eight: 8 * 10,
  nine: 9 * 10,
  ten: 10 * 10,
  eleven: 11 * 10,
  twelve: 12 * 10,
  thirteen: 13 * 10,
};

const commonColors = {
  lightGrey: '#999',
  darkGrey: '#666',
  brandPrimary: '#1f907e',
  brandSecondary: '#fff',
  brandTextColor: '#fff',
  brandNeutral: '#f4d578',
  brandCancel: '#b74545',
  errorBackgroundColor: '#ffe8e4',
  borderGray: rgba(0, 0, 0, 0.14),
  link: '#39d',
  info: '#55b4d0',
  infoBackgroundColor: '#e9f0f5',
  inputBorder: rgba(3, 2, 2, 0.2),
  favouriteIcon: '#e47160',
  grayLighter: '#f3f3f3',
  modalBackdrop: rgba(0, 0, 0, 0.7),
  sheetHeader: '#4c4c4e',
  success: '#009E00',
  successBackgroundColor: '#d6f1d6',
  danger: '#ec4f37',
  warning: '#fb0',
  warningBackgroundColor: '#fff4d6',
  darkBodyTextColor: '#f1f1f1',
  darkBorderColor: rgba(255, 255, 255, 0.2),
  darkBoxBackgroundColor: rgba(0, 0, 0, 0.3),
  darkIconFill: '#999',
  green: '#009E00',
  red: '#EE0000',
  white: '#fff',
  systemIcon: '#009E00',
  settings: '#F0AD4E',
  favourites: '#E47160',
  allergens: '#009E00',
  nutrition: '#39d',
  thankyouColour: '#f9f6f6',
};

const lightColors = {
  ...commonColors,
  primaryBackground: '#fff',
  secondaryBackground: '#ededed',
  footerBg: rgba(255, 255, 255, 0.8),
  footerColor: '#666',
  infoBackground: '#ededed',
  infoBorder: '#d7d7d7',
  primaryText: '#666',
  text: '#666',
  borderColor: rgba(0, 0, 0, 0.14),
  inputBackground: 'transparent',
  inputBorder: rgba(0, 0, 0, 0.14),
  iconFill: '#999',
  disabledInput: '#ccc',
  disabledInputText: '#000',
};

const darkColors = {
  ...commonColors,
  primaryBackground: '#232323',
  secondaryBackground: '#3c3c3c',
  footerBg: rgba(35, 35, 35, 0.8),
  footerColor: '#fff',
  infoBackground: '#484c4b',
  infoBorder: '#626565',
  primaryText: '#fff',
  text: '#bbb',
  borderColor: '#707070',
  inputBackground: '#313636',
  inputBorder: '#313636',
  iconFill: '#fff',
  disabledInput: '#ccc',
  disabledInputText: '#616161',
};

const commonButtons = {
  defaultBg: 'transparent',
  defaultColor: '#009E00',
  primaryBg: '#009E00',
  primaryColor: '#fff',
  dangerBg: 'transparent',
  dangerColor: commonColors.danger,
  quantityMinus: commonColors.danger,
  quantityPlus: commonColors.success,
  buttonHomepageBg: commonColors.brandPrimary,
  buttonHomepageColor: commonColors.brandSecondary,
  buttonHomepageBorder: commonColors.brandSecondary,
};

const lightButtons = {
  ...commonButtons,
  defaultBorder: lightColors.borderColor,
  primaryBorder: lightColors.borderColor,
  dangerBorder: lightColors.borderColor,
};

const darkButtons = {
  ...commonButtons,
  defaultBorder: darkColors.borderColor,
  primaryBorder: darkColors.borderColor,
  dangerBorder: darkColors.borderColor,
};

export default {
  fonts,
  zIndex,
  buttons: lightButtons,
  color: lightColors,
};

export const getTheme = (branding, darkModeEnabled, dir) => {
  const getNonNull = obj => Object.fromEntries(Object.entries(obj).filter(entry => !!entry[1]));

  const merge = (a, b) => ({ ...a, ...b });

  const themeButtons = darkModeEnabled ? darkButtons : lightButtons;
  const themeColors = darkModeEnabled ? darkColors : lightColors;

  const brandingButtons = getNonNull(branding.buttons);
  const brandingColors = getNonNull(branding.color);
  const brandingFonts = getNonNull(branding.fonts);

  return {
    buttons: merge(themeButtons, brandingButtons),
    color: merge(themeColors, brandingColors),
    dir,
    fonts: merge(fonts, brandingFonts),
    zIndex,
  };
};
