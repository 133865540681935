import styled from 'styled-components';
import { Link } from 'react-router';

export const BackLinkStyled = styled(Link)`
  margin-right: 10px;
`;

export const Label = styled.span`
  font-size: 0.714em;
  line-height: 1.1;
  text-transform: uppercase;
  padding: 0 5px;
  display: none;

  &:lang(ru) {
    font-size: 0.65em;
  }
`;
