import { NotesIcon } from 'components/Icons';
import SystemIcon from 'components/SystemIcon';
import TextArea from 'components/TextArea';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import productMessages from 'components/Product/messages';
import { NotesFlexBox } from './styles';
import AdditionalButton from '../../../Product/ProductAdditional/ProductAdditionalButton';

interface Props {
  onChange: (value: string) => void;
  value: string;
}

export const Notes: React.FC<Props> = ({ onChange, value }) => {
  const [showNotes, setShowNotes] = useState(false);

  if (!showNotes) {
    return (
      <AdditionalButton onClick={() => setShowNotes(true)}>
        <SystemIcon Icon={NotesIcon} width="24" height="24" />

        <span>
          <FormattedMessage {...productMessages.addNotes} />
        </span>
      </AdditionalButton>
    );
  }

  return (
    <NotesFlexBox>
      <TextArea
        autoFocus={true}
        value={value}
        onChange={onChange}
        onBlur={() => {
          if (!value) setShowNotes(false);
        }}
        label={<FormattedMessage {...productMessages.addNotes} />}
        className="form-control"
      />
    </NotesFlexBox>
  );
};
