import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import { setIsSettingsPage } from 'actions/UI';

import SystemIcon from 'components/SystemIcon';
import BackButton from 'components/BackButton';
import { HeaderStyled, HeaderContainer, HeaderTitle, BackButtonContainer } from './styles';

const propTypes = {
  backButton: PropTypes.instanceOf(Map),
  hideBackButton: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  Icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  iconFill: PropTypes.string,
  setIsSettingsPage: PropTypes.func,
  settingsPage: PropTypes.bool,
  pageTitleRef: PropTypes.object,
  theme: PropTypes.object,
};

const defaultProps = {
  backButton: new Map(),
  settingsPage: false,
};

const PageHeader = ({
  backButton,
  hideBackButton,
  setIsSettingsPage,
  Icon,
  iconFill,
  pageTitleRef,
  settingsPage,
  title,
  theme,
}) => {
  useEffect(() => {
    setIsSettingsPage(true);

    return () => setIsSettingsPage(false);
  }, [setIsSettingsPage]);

  if (!title) return null;

  return (
    <HeaderStyled className="primaryBackground">
      <HeaderContainer>
        {!hideBackButton && (
          <BackButtonContainer>
            <BackButton fill={theme.color.iconFill} link={backButton.get('href')} />{' '}
          </BackButtonContainer>
        )}
        {Icon && <SystemIcon Icon={Icon} fill={iconFill} />}
        <HeaderTitle innerRef={pageTitleRef} settingsPage={settingsPage}>
          {title}
        </HeaderTitle>
      </HeaderContainer>
    </HeaderStyled>
  );
};

PageHeader.propTypes = propTypes;
PageHeader.defaultProps = defaultProps;

export default connect(null, { setIsSettingsPage })(withTheme(PageHeader));
