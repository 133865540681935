import styled from 'styled-components';

export const MenuButtonStyled = styled.button`
  cursor: pointer;
  float: right;
  top: 0;
  right: 7px;
  padding: 10px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: ${({ theme }) => theme.zIndex.six};
  height: 48px;
  position: relative;

  // html[dir='rtl'] & {
  //   float: left;
  //   right: unset;
  // }
  &.sidebarOpen {
    right: 0px;
  }
`;
