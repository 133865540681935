import styled, { css } from 'styled-components';

export const backgroundStyle = css`
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.color.grayLighter : theme.color.inputBackground};
`;

export const sharedBorderStyles = css`
  border: 1px solid ${({ theme }) => theme.color.borderColor};

  ${props =>
    props.selected &&
    css`
      border-color: ${({ theme }) => theme.color.success};
    `}
`;

export const PaymentChoiceContainer = styled.div`
  ${sharedBorderStyles};
  width: 100%;
  font-size: 15px;
  line-height: 17px;
  text-align: left;
  margin: ${({ grouped }) => (grouped ? '0' : '5px 0 10px')};
  display: flex;
  align-items: center;
  height: auto;

  ${props =>
    props.grouped &&
    css`
      border: none;
      border-radius: 0;
      border-top: 1px solid ${({ theme }) => theme.color.borderColor};
    `}

  html[dir='rtl'] & {
    margin: ${({ grouped }) => (grouped ? '0' : '5px 0 10px')};
  }

  @media (min-width: 375px) {
    font-size: ${({ grouped }) => !grouped && '18px'};
  }

  @media (min-width: 992px) {
    margin: ${({ grouped }) => !grouped && '5px'};
    width: calc(${({ grouped }) => !grouped && '50% - 10px'});

    html[dir='rtl'] & {
      margin: ${({ grouped }) => !grouped && '5px'};
    }
  }
`;

export const PaymentMethodContainer = styled(PaymentChoiceContainer)`
  overflow: hidden;
`;

export const PaymentChoiceLabel = styled.label`
  ${backgroundStyle};
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  padding: ${({ grouped }) => (grouped ? '7px 10px 7px 7px' : '12px')};
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
`;

const sharedImgStyles = css`
  border-right: 1px solid ${({ theme }) => theme.color.borderColor};
  margin-right: 17px;
  padding-right: ${({ grouped }) => (grouped ? '9px' : '5px')};

  html[dir='rtl'] & {
    margin-right: 0;
    margin-left: 17px;
    border-right: none;
    border-left: 1px solid ${({ theme }) => theme.color.borderColor};
    padding-right: 0;
    padding-left: ${({ grouped }) => (grouped ? '9px' : '5px')};
  }
`;

export const PaymentMethodImg = styled.img`
  ${sharedImgStyles}
  padding-right: ${({ grouped }) => (grouped ? '9px' : '5px')};

  html[dir='rtl'] & {
    padding-right: 0;
    padding-left: ${({ grouped }) => (grouped ? '9px' : '5px')};
  }
`;

export const PaymentMethodIcon = styled.div`
  ${sharedImgStyles}
  padding: 0 20px 0 10px;

  html[dir='rtl'] & {
    padding: 0 10px 0 20px;
  }
`;

export const PaymentMethodDetail = styled.p`
  font-size: 11px;
`;
