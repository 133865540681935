import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const BackIcon = ({ className, width, height, fill, onClick }) => (
  <Container onClick={onClick}>
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 115 115"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      id="backArrow"
    >
      <path
        className="icon"
        d="M25.1562 53.9062H97.0312C97.9844 53.9062 98.8985 54.2849 99.5724 54.9588C100.246 55.6328 100.625 56.5469 100.625 57.5C100.625 58.4531 100.246 59.3672 99.5724 60.0412C98.8985 60.7151 97.9844 61.0938 97.0312 61.0938H25.1562C24.2031 61.0938 23.289 60.7151 22.6151 60.0412C21.9411 59.3672 21.5625 58.4531 21.5625 57.5C21.5625 56.5469 21.9411 55.6328 22.6151 54.9588C23.289 54.2849 24.2031 53.9062 25.1562 53.9062Z"
        fill={fill}
      />
      <path
        d="M26.6441 57.5016L56.4507 87.301C57.1255 87.9758 57.5046 88.891 57.5046 89.8454C57.5046 90.7997 57.1255 91.7149 56.4507 92.3897C55.7759 93.0645 54.8607 93.4436 53.9063 93.4436C52.952 93.4436 52.0368 93.0645 51.3619 92.3897L19.0182 60.046C18.6835 59.7122 18.418 59.3156 18.2368 58.879C18.0557 58.4424 17.9624 57.9743 17.9624 57.5016C17.9624 57.0289 18.0557 56.5608 18.2368 56.1242C18.418 55.6876 18.6835 55.2911 19.0182 54.9572L51.3619 22.6135C52.0368 21.9387 52.952 21.5596 53.9063 21.5596C54.8607 21.5596 55.7759 21.9387 56.4507 22.6135C57.1255 23.2883 57.5046 24.2035 57.5046 25.1579C57.5046 26.1122 57.1255 27.0274 56.4507 27.7022L26.6441 57.5016Z"
        fill={fill}
      />
    </svg>
  </Container>
);

BackIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  onClick: PropTypes.func,
};

BackIcon.defaultProps = {
  width: '30px',
  height: '30px',
  fill: 'currentColor',
};

export default BackIcon;
