import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, fromJS } from 'immutable';
import { FormattedMessage } from 'react-intl';

import TextArea from 'components/TextArea';
import Loading from 'components/Loading/index';
import MessageBlock from 'components/MessageBlock';
import { fetchProduct } from 'actions/browse';
import { popSheetInfoAndCustomisation } from 'actions/UI';
import ProductQuantity from 'components/ProductQuantity';
import Modifier from 'components/Modifier';
import {
  getScheduleById,
  backToItemMenuPage,
  selectServiceId,
  selectProducts,
  getProductById,
  getProductAndModifiersFromItem,
} from 'selectors/browse';
import { getOrderItemByIndex } from 'selectors/order';
import { shouldAllowItemNotes } from 'selectors/features';
import { isServiceBrowseOnly } from 'selectors/root';
import { addProductToOrder, updateItem } from 'actions/order';
import { AlertHeader, AlertTitle, AlertContent, AlertSubTitle } from 'assets/styles/sharedStyles';
import NavigationButtons from 'components/Alert/alerts/ProductCustomisation/NavigationButtons';
import productMessages from 'components/Product/messages';
import ModifierProductInfo from 'components/Alert/alerts/ProductCustomisation/ModifierProductInfo';
import { reactPixel, gtmDataLayerPush, addCssPrefixTo } from 'utils';
import { getLocale } from 'selectors/user';

import messages from './messages';
import { QuantityContainer } from './styles';

export class ProductCustomisation extends React.Component {
  static defaultProps = {
    popSheetInfoAndCustomisation: () => undefined,
    showBaseModifiers: true,
    browseOnly: false,
  };

  static propTypes = {
    add: PropTypes.func.isRequired,
    editing: PropTypes.bool.isRequired,
    closeAlert: PropTypes.func.isRequired,
    popSheetInfoAndCustomisation: PropTypes.func,
    item: PropTypes.object.isRequired,
    product: PropTypes.object,
    fetchProduct: PropTypes.func.isRequired,
    getProductById: PropTypes.func.isRequired,
    getProductAndModifiers: PropTypes.func.isRequired,
    selectSchedule: PropTypes.func.isRequired,
    source: PropTypes.string,
    showBaseModifiers: PropTypes.bool,
    backToItemMenuPage: PropTypes.func,
    allowItemNotes: PropTypes.bool,
    browseOnly: PropTypes.bool,
    currentLocale: PropTypes.string,
    selectProducts: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      showError: false,
      errors: [],
      notes: props.item?.get('notes') || '',
      quantity: props.item?.get('quantity') || 1,
      modifiers: props.item?.get('modifiers') || new List(),
      pageNumber: 1,
      modifierInfo: null,
      item: props.item,
    };
  }

  componentDidMount() {
    this.initialiseData();
  }

  async initialiseData() {
    const { getProductById, item, fetchProduct } = this.props;

    // Check if product is in state
    const mainProduct = getProductById(item.get('productId'));
    const baseProduct = getProductById(item.get('base_product_id'));

    try {
      // Fetch the product if not in state or modifiers are missing
      if (mainProduct.isEmpty() || typeof mainProduct.get('modifier_groups') === 'undefined') {
        await fetchProduct(item.get('productId'));
      }

      // Fetch base product if it's also missing
      if (
        (baseProduct.isEmpty() || typeof baseProduct.get('modifier_groups') === 'undefined') &&
        item.get('base_product_id', null) !== null
      ) {
        await fetchProduct(item.get('base_product_id'));
      }
    } catch (e) {
      console.error(e);
    }
  }

  getSelectedModifierValues = modifier => {
    const modifierState = this.state.modifiers.find(m => m.getIn(['modifier', 'id']) === modifier.get('id'));
    return modifierState ? modifierState.get('values').toJS() : [];
  };

  // eslint-disable-next-line class-methods-use-this
  transformModifierValues = value => (typeof value === 'string' ? [{ value }] : value);

  updateModifierValues = (modifier, vals) => {
    const group = modifier.get('groupId');
    const values = this.transformModifierValues(vals, modifier);
    const existing = this.state.modifiers.findKey(
      entry => entry.getIn(['modifier', 'id']) === modifier.get('id')
    );
    let modifiers;
    if (existing !== undefined) {
      modifiers =
        values.length === 0
          ? this.state.modifiers.remove(existing)
          : this.state.modifiers.setIn([existing, 'values'], fromJS(values));
    } else {
      modifiers = this.state.modifiers.push(fromJS({ group, modifier, values }));
    }

    this.setState({ modifiers }, () => {
      this.checkModifierErrors(modifier);
    });

    if (modifier.get('display_type') === 'base') {
      const baseModifierValue = vals[0];
      // eslint-disable-next-line react/no-access-state-in-setstate
      const item = this.state.item.set('productId', baseModifierValue.product.id);

      const updatedModifiers = modifiers.map(modifier => {
        if (modifier.getIn(['modifier', 'display_type']) !== 'base') {
          return modifier.set('values', List());
        }
        return modifier;
      });

      this.setState({
        item,
        modifiers: updatedModifiers,
      });
    }
  };

  checkModifierErrors = modifier => {
    if (this.props.browseOnly) return false;

    const errors = [];
    if (modifier.get('minimum')) {
      const modifierValue = this.state.modifiers.find(
        m => m.getIn(['modifier', 'id']) === modifier.get('id')
      );
      if (!modifierValue?.get('values') || modifierValue.get('values').size < modifier.get('minimum')) {
        errors.push(
          <FormattedMessage {...messages.error_minimum} values={{ minimum: modifier.get('minimum') }} />
        );
      }
    } else if (
      modifier.get('required') &&
      !this.state.modifiers.find(m => m.getIn(['modifier', 'id']) === modifier.get('id'))
    ) {
      errors.push(<FormattedMessage {...messages.error_required} />);
    }
    this.setState({ errors });
    return !!errors.length;
  };

  addToOrder = () => {
    const { source, popSheetInfoAndCustomisation, closeAlert, add, backToItemMenuPage, item, product } =
      this.props;

    if (source === 'customiseFromOnInfo') {
      popSheetInfoAndCustomisation();
      backToItemMenuPage(item);
    } else closeAlert();

    reactPixel.track('AddToCart', {
      content_name:
        product?.get('translations').get(this.props.currentLocale)?.get('name') ?? product?.get('name', ''),
    });
    gtmDataLayerPush('AddToCart', {
      content_name:
        product?.get('translations').get(this.props.currentLocale)?.get('name') ?? product?.get('name', ''),
    });

    // Get rid of those with empty values
    const modifiers = this.state.modifiers.filter(modifier => !modifier.get('values').isEmpty());

    add(this.state.quantity, modifiers, this.state.notes);
  };

  render() {
    const { closeAlert, editing, selectSchedule, allowItemNotes, browseOnly, product } = this.props;

    const { pageNumber, showError, errors, modifierInfo } = this.state;

    const { productModifiers } = this.props.getProductAndModifiers(this.state.item);

    if (typeof product === 'undefined' || typeof product.get('modifier_groups') === 'undefined')
      return <Loading />;

    const modifier = pageNumber <= productModifiers.size ? productModifiers.get(pageNumber - 1) : undefined;
    const selectedValues = modifier ? this.getSelectedModifierValues(modifier) : null;
    const totalPages = browseOnly ? productModifiers.size : productModifiers.size + 1;
    const isDonation = Boolean(this.state.item.get('key', false));

    return (
      <>
        <AlertHeader>
          <AlertTitle>
            <FormattedMessage
              {...messages.product_title}
              values={{
                itemName: (
                  <span className={addCssPrefixTo('product_customisation_product_name')}>
                    {product?.get('name')}
                  </span>
                ),
              }}
            />
          </AlertTitle>
          <AlertSubTitle>
            <FormattedMessage
              {...messages.customise_item_step_indicator}
              values={{ page: pageNumber, total: totalPages }}
            />
          </AlertSubTitle>
        </AlertHeader>
        {modifierInfo && (
          <ModifierProductInfo
            settings={modifier}
            modifierValues={selectedValues}
            modifier={modifierInfo}
            taxCategories={product.get('tax_categories')}
            onCancel={() => this.setState({ modifierInfo: null })}
            updateValues={values => this.updateModifierValues(modifier, values)}
            browseOnly={browseOnly}
          />
        )}
        {!modifierInfo && (
          <>
            <AlertContent>
              {showError && !!errors.length && (
                <MessageBlock
                  header={<FormattedMessage {...messages.error_header} />}
                  body={
                    <ul>
                      {errors.map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  }
                  type="error"
                />
              )}
              {modifier && (
                <Modifier
                  modifier={modifier}
                  values={selectedValues}
                  schedule={selectSchedule(product.get('scheduleId'))}
                  hasError={showError && !!errors.length}
                  updateValues={values => this.updateModifierValues(modifier, values)}
                  showModifierInfo={option => this.setState({ modifierInfo: option })}
                  taxCategories={product.get('tax_categories')}
                  browseOnly={browseOnly}
                />
              )}
              {!modifier && (
                <>
                  {allowItemNotes && !isDonation && (
                    <span>
                      <TextArea
                        label={<FormattedMessage {...productMessages.addNotes} />}
                        value={this.state.notes}
                        onChange={notes => this.setState({ notes })}
                      />
                    </span>
                  )}

                  <QuantityContainer>
                    <ProductQuantity
                      quantity={this.state.quantity}
                      onChange={quantity => this.setState({ quantity })}
                      productId={product?.get('id')}
                    />
                  </QuantityContainer>
                </>
              )}
            </AlertContent>
            <NavigationButtons
              editing={editing}
              cancel={closeAlert}
              pageNumber={pageNumber}
              totalPages={totalPages}
              browseOnly={browseOnly}
              setPageNumber={pageNo => {
                if (pageNo > pageNumber && this.checkModifierErrors(modifier))
                  this.setState({ showError: true });
                else this.setState({ pageNumber: pageNo, showError: false, errors: [] });
              }}
              addToOrder={this.addToOrder}
            />
          </>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch, { itemIndex, item, serviceId, menuType, editing, source }) => {
  const add = editing
    ? (quantity, modifiers, notes) => dispatch(updateItem(itemIndex, quantity, modifiers, notes))
    : (quantity, modifiers, notes) =>
        dispatch(addProductToOrder(item, menuType, serviceId, modifiers, notes, quantity, source));
  return {
    add,
    popSheetInfoAndCustomisation: () => dispatch(popSheetInfoAndCustomisation()),
    fetchProduct: id => dispatch(fetchProduct(id)),
  };
};

const mapStateToProps = (state, { itemIndex, item, editing, serviceId = selectServiceId(state) }) => {
  const props = { item };

  // We're editing
  if (itemIndex !== undefined) {
    props.item = getOrderItemByIndex(state, itemIndex);

    // Bit of a hack but other components are setting productId so fill it
    props.item = props.item.set('productId', props.item.get('product'));
  }

  const { product } = getProductAndModifiersFromItem(state, props.item, editing);

  return {
    ...props,
    product,
    selectSchedule: id => getScheduleById(state, id),
    backToItemMenuPage: item => backToItemMenuPage(state, item),
    allowItemNotes: shouldAllowItemNotes(state),
    browseOnly: isServiceBrowseOnly(state, serviceId),
    currentLocale: getLocale(state),
    selectProducts: () => selectProducts(state),
    getProductById: id => getProductById(state, id),
    getProductAndModifiers: item => getProductAndModifiersFromItem(state, item, editing),
  };
};

const ConnectedProductCustomisation = connect(mapStateToProps, mapDispatchToProps)(ProductCustomisation);

export default ConnectedProductCustomisation;
