import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Map, List } from 'immutable';

import { openProductInfo } from 'actions/UI';
import { addProductToOrder, customiseProduct } from 'actions/order';
import { selectMenuType, selectActiveMenus, getItemPrice } from 'selectors/browse';
import { hideIfInCart, getOrderMenuTypeId, selectOrderServiceId, validatedOrderItems } from 'selectors/order';
import { selectCrossSellsList } from 'selectors/crossSells';
import { useIsMounted } from 'hooks';
import ProductDescription from 'components/Product/ProductDescription';
import SuitableFor from 'components/SuitableFor';
import ProductListing from 'components/ProductListing';
import buildProductsList from './buildProductsList';

import { CrossSellsContainer } from './styles';
import { Title } from '../styles';

const messages = defineMessages({
  title: {
    defaultMessage: 'You may also like',
  },
});

const propTypes = {
  crossSellsList: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(List)]),
  productsInCart: PropTypes.instanceOf(List),
  activeMenus: PropTypes.instanceOf(Map),
  menuType: PropTypes.instanceOf(Map),
  serviceId: PropTypes.string,
  menuTypeId: PropTypes.number,
  openProductInfo: PropTypes.func,
  addToCart: PropTypes.func,
  customise: PropTypes.func,
  getItemPrice: PropTypes.func,
};

const defaultProps = {
  productsInCart: new List(),
  activeMenus: new Map(),
  menuType: new Map(),
  serviceId: undefined,
  menuTypeId: undefined,
  openProductInfo: () => undefined,
  addToCart: () => undefined,
  customise: () => undefined,
};

const OrderCrossSells = ({
  crossSellsList,
  productsInCart,
  activeMenus,
  menuTypeId,
  openProductInfo,
  getItemPrice,
}) => {
  const [crossSellsProducts, setCrossSellsProducts] = useState(new List());
  const [isMounted] = useIsMounted();

  const crossSellsProductsSize = crossSellsProducts?.size;
  const productsInCartSize = productsInCart?.size;
  const productsNotInCart = hideIfInCart(crossSellsProducts, productsInCart).slice(0, 4);
  const productsNotInCartSize = productsNotInCart?.size;

  useEffect(() => {
    if (crossSellsList && isMounted.current) {
      setCrossSellsProducts(buildProductsList(crossSellsList, activeMenus));
    }
  }, [crossSellsList, isMounted, activeMenus]);

  return crossSellsProductsSize && productsInCartSize && productsNotInCartSize ? (
    <CrossSellsContainer>
      <Title className="secondary-font">
        <FormattedMessage {...messages.title} />
      </Title>
      <>
        {productsNotInCart.map(item => {
          item = item.set('productId', item.getIn(['product', 'id']));
          const price = getItemPrice(
            item.getIn(['prices', menuTypeId.toString(), 'price']),
            item.getIn(['product', 'tax_categories'])
          );
          item = item.set('is_upsell', true);
          const product = item.get('product');

          return (
            <lI>
              <ProductListing
                name={product.get('name')}
                description={<ProductDescription product={product.toJS()} />}
                imageSrc={product?.getIn(['images', 0, 'path'])}
                price={price}
                allergens={<SuitableFor product={product} />}
                spiceLevel={product.get('spice_level')}
                onClick={() => openProductInfo(item)}
              />
            </lI>
          );
        })}
      </>
    </CrossSellsContainer>
  ) : null;
};

OrderCrossSells.propTypes = propTypes;
OrderCrossSells.defaultProps = defaultProps;

const mapStateToProps = state => {
  const menuTypeId = getOrderMenuTypeId(state);
  const serviceId = selectOrderServiceId(state);

  return {
    activeMenus: selectActiveMenus(state, menuTypeId, serviceId),
    menuType: selectMenuType(state),
    crossSellsList: selectCrossSellsList(state),
    getItemPrice: (price, taxCategories) => getItemPrice(state, price, taxCategories),
    menuTypeId,
    serviceId,
    productsInCart: validatedOrderItems(state),
  };
};

export default connect(mapStateToProps, {
  openProductInfo,
  addToCart: addProductToOrder,
  customise: customiseProduct,
})(OrderCrossSells);
