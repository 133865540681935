import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage, defineMessages } from 'react-intl';
import FormattedTime from 'components/Intl/formattedTime';

const messages = defineMessages({
  menu: {
    defaultMessage: 'Menu',
  },
  service: {
    defaultMessage: 'Service',
  },
  availableToday: {
    defaultMessage: '{ type } next available at { activeTime }',
  },
  availableTomorrow: {
    defaultMessage: '{ type } next available tomorrow at { activeTime }',
  },
  availableDate: {
    defaultMessage: '{ type } next available { activeDate } at { activeTime }',
  },
});

const NextAvailableMessage = ({ nextAvailableTime }) => {
  const today = new Date().setHours(0, 0, 0, 0);
  let tomorrow = new Date().setDate(new Date().getDate() + 1);
  tomorrow = new Date(tomorrow).setHours(0, 0, 0, 0);
  const nextActiveDate = new Date(nextAvailableTime.date).setHours(0, 0, 0, 0);

  let message = { ...messages.availableDate };
  if (nextActiveDate === today) {
    message = { ...messages.availableToday };
  } else if (nextActiveDate === tomorrow) {
    message = { ...messages.availableTomorrow };
  }

  return (
    <FormattedMessage
      {...message}
      values={{
        activeDate: (
          <FormattedDate
            timeZone="UTC"
            value={nextAvailableTime.time}
            weekday="long"
            day="numeric"
            month="short"
          />
        ),
        activeTime: <FormattedTime timeZone="UTC" value={nextAvailableTime.time} />,
        type: messages[nextAvailableTime.type] ? (
          <FormattedMessage {...messages[nextAvailableTime.type]} />
        ) : (
          ''
        ),
      }}
    />
  );
};

NextAvailableMessage.propTypes = {
  nextAvailableTime: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.string,
      time: PropTypes.number,
      date: PropTypes.string,
    }),
  ]),
};

export default NextAvailableMessage;
