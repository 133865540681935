import styled from 'styled-components';

export const StyledMessage = styled.div`
  background: ${({ theme }) => theme.color.secondaryBackground};
  color: ${({ theme }) => theme.color.text};
  margin: 10px 0;
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    margin: 10px 5px;
  }
`;

export const StyledTimer = styled.div`
  left: 0;
  padding: 8px 10px;
  text-align: center;
  top: 0;
  width: 42px;

  > svg {
    height: auto;
    width: 24px;
  }

  html[dir='rtl'] & {
    left: auto;
    right: 0;
  }
`;

export const MessageWrapper = styled.div`
  padding: 10px;
`;

export const MessageHeader = styled.span`
  display: block;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 5px;
`;
