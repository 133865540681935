import React from 'react';
import PropTypes from 'prop-types';

import { hasNutritionalInformation } from 'selectors/nutritionalInformation';
import { productIsFoodOrBeverage } from 'selectors/browse';
import { addCssPrefixTo } from 'utils';
import { Notes } from 'components/Alert/alerts/ProductOptionInformation/Notes';
import AllergenInformationButton from '../AllergenInformationButton';
import NutritionalInformationButton from '../NutritionalInformationButton';

// eslint-disable-next-line no-unused-vars
const StandardButtons = ({ browseOnly = false, notesState = [], product, additionalButtons }) => {
  const [notes, setNotes] = notesState;

  return (
    <div className={addCssPrefixTo('STANDARD_BUTTONS')}>
      {product.get('allergens')?.length > 0 && productIsFoodOrBeverage(product) && (
        <AllergenInformationButton key="allergen" product={product} />
      )}
      {hasNutritionalInformation(product) && (
        <NutritionalInformationButton key="nutritional" product={product} />
      )}
      {!browseOnly && setNotes && (
        <div className={addCssPrefixTo('PDP_NOTES')}>
          <Notes onChange={setNotes} value={notes} />
        </div>
      )}
      {additionalButtons}
    </div>
  );
};

StandardButtons.propTypes = {
  product: PropTypes.object,
  additionalButtons: PropTypes.node,
  browseOnly: PropTypes.bool,
  notesState: PropTypes.array,
};

export default StandardButtons;
