import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  text-align: center;
  margin: 10px 0;
  flex-wrap: wrap;
  align-items: stretch;
`;

export const OptionContainer = styled.div`
  flex: 1;
  opacity: 40%;

  &.selected {
    opacity: 100%;
  }
`;

export const BaseModifierIconContainer = styled.div`
  text-align: center;
  height: 26px;
  margin: 4px;
`;

export const BaseModifierIcon = styled.img`
  height: 26px;
`;

export const Option = styled.button`
  background-color: ${({ theme }) => theme.buttons.defaultColor};
  color: ${({ theme }) => theme.buttons.defaultBg};
  border: 1px solid ${({ theme }) => theme.buttons.defaultBorder};
  width: 95%;
  margin: 0 5px;
  font-weight: bold;
  padding: 5px;
`;

export const OptionBar = styled.div`
  background-color: ${({ selected, theme }) => (selected ? theme.color.success : theme.color.infoBackground)};
  height: 8px;
  border-radius: 10px;
  margin-top: 5px;
`;
