import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';

import BackIcon from 'components/Icons/BackIcon';

import { selectLastMenuPage } from 'selectors/ui';
import { kioskModeEnabled } from '../../selectors/features';

import { BackLinkStyled, Label } from './styles';

const messages = defineMessages({
  home: {
    defaultMessage: 'Home',
  },
  menus: {
    defaultMessage: 'Menus',
  },
  menu: {
    defaultMessage: 'Menu',
  },
  section: {
    defaultMessage: 'Section',
  },
  services: {
    defaultMessage: 'Services',
  },
  categories: {
    defaultMessage: 'Categories',
  },
  back: {
    defaultMessage: 'Back',
  },
});

const BackButton = ({ link, text, lastMenuPage, isKioskMode, fill }) => {
  const linkToProp = {
    pathname: link || lastMenuPage || '/',
  };
  text = messages[text] ? <FormattedMessage {...messages[text]} /> : text;

  if (isKioskMode && linkToProp.pathname === '/') {
    return null;
  }

  return (
    <BackLinkStyled to={linkToProp}>
      <BackIcon fill={fill} />
      <Label>{text}</Label>
    </BackLinkStyled>
  );
};

BackButton.defaultProps = {
  fill: '#fff',
};

BackButton.propTypes = {
  link: PropTypes.string,
  text: PropTypes.node,
  lastMenuPage: PropTypes.string,
  isKioskMode: PropTypes.bool,
  fill: PropTypes.string,
};

export default connect(state => ({
  lastMenuPage: selectLastMenuPage(state),
  isKioskMode: kioskModeEnabled(state),
}))(BackButton);
