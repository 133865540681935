import styled from 'styled-components';
import { fontSizeMedium } from 'assets/styles/fonts';

export const Container = styled.div`
  text-align: center;
  position: relative;
  width: 100%;
  height: 50px;
  ${fontSizeMedium};
`;

export const Text = styled.span`
  display: inline-block;
  font-style: italic;
  padding: 0 10px;
`;

export const Logo = styled.span`
  background: #fff url('https://wi-q.cloud/i/60x25/wi-q/logo/logo.png');
  height: 25px;
  width: 60px;
  display: inline-block;
  position: relative;
  top: 10px;
`;
