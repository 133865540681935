const setMenuTypeId = (req, res) => {
  const { menuTypeId } = req.body;

  if (isNaN(Number(menuTypeId))) return res.status(400).json({ error: true, message: 'Invalid menuTypeId' });

  req.session.menuTypeId = menuTypeId;
  res.json({ success: true });
};

const setServiceId = (req, res) => {
  req.session.serviceId = req.body.serviceId;
  res.json({ success: true });
};

module.exports = { setMenuTypeId, setServiceId };
