import React, { useState } from 'react';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { openAllergenFilter } from 'actions/UI';
import Search from 'components/Search';
import { selectService } from 'selectors/root';
import {
  selectMenuTypeId,
  selectServiceId,
  selectActiveMenus,
  hideStaffMenu,
  selectSectionProducts,
  productIsFiltered,
} from 'selectors/browse';
import { selectIsFiltered } from 'selectors/allergenFilter';
import Product from 'components/Product';
import globalMessages from 'components/globalMessages';
import { trackEvent } from 'utils/tracking';

import BackButton from 'components/BackButton';
import { withTheme } from 'styled-components';
import { addCssPrefixTo } from 'utils';
import { StyledSearch, StyledSearchBar, FilterBtn, StyledFilterIcon } from './styles';

const propTypes = {
  isFiltered: PropTypes.bool,
  products: PropTypes.array,
  menuTypeId: PropTypes.number,
  serviceId: PropTypes.string,
  openAllergenFilter: PropTypes.func,
  backButton: PropTypes.object,
  theme: PropTypes.object,
};

const MenuSearch = ({
  isFiltered,
  products,
  menuTypeId,
  serviceId,
  openAllergenFilter,
  backButton,
  theme,
}) => {
  const [expandSearch, setExpandSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const FilterText = isFiltered ? (
    <FormattedMessage {...globalMessages.filtered} />
  ) : (
    <FormattedMessage {...globalMessages.filter} />
  );

  return (
    <StyledSearch className={addCssPrefixTo('SEARCH_BAR')}>
      <div className="u-flex u-flexAlignItemsCenter u-flexJustifyBetween container">
        <BackButton fill={theme.color.iconFill} link={backButton.get('href')} />
        <StyledSearchBar>
          <Search
            searchKeys={['name', 'product.description']}
            searchData={products}
            onFocus={() => setExpandSearch(true)}
            onBlur={() => setExpandSearch(false)}
            onClear={() => setExpandSearch(false)}
            onChange={searchTerm => setSearchTerm(searchTerm)}
            filtersActive={isFiltered}
            render={(result, index) => (
              <div
                onClick={() => {
                  const { id: productId, name: productName } = result;
                  trackEvent('search', {
                    search_term: searchTerm,
                    category: 'menu',
                    product_id: productId,
                    product_name: productName,
                    position: index + 1,
                  });
                }}
              >
                <Product
                  showBreadcrumb={true}
                  item={fromJS(result)}
                  menuTypeId={menuTypeId}
                  serviceId={serviceId}
                />
              </div>
            )}
          />
        </StyledSearchBar>

        {expandSearch === false && (
          <FilterBtn
            onClick={openAllergenFilter}
            active={isFiltered}
            className="u-flex u-flexAlignItemsCenter"
          >
            <StyledFilterIcon active={isFiltered} />
            <span>{FilterText}</span>
          </FilterBtn>
        )}
      </div>
    </StyledSearch>
  );
};

MenuSearch.propTypes = propTypes;

export default connect(
  (state, props) => {
    const menuTypeId = selectMenuTypeId(state);
    const service = selectService(state);
    const serviceId = props?.serviceId || selectServiceId(state);
    const menus = selectActiveMenus(state, menuTypeId, serviceId);

    const visibleMenus = menus.filter(menu => !hideStaffMenu(state, menu.get('id')));

    const products = Object.values(visibleMenus.toJS()).reduce(
      (products, menu) =>
        Object.values(menu.sections).reduce((sectionProducts, section) => {
          const activeSectionProducts = selectSectionProducts(state, fromJS(section), service).toJS();
          return sectionProducts.concat(activeSectionProducts);
        }, products),
      []
    );

    return {
      menuTypeId,
      products: products.filter(
        sectionProduct => !productIsFiltered(state, fromJS(sectionProduct.productId))
      ),
      isFiltered: selectIsFiltered(state),
      serviceId,
    };
  },
  { openAllergenFilter }
)(withTheme(MenuSearch));
