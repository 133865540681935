import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';

import ActionButton from 'components/ActionButton';
import { isLoggedIn } from 'selectors/user';
import { openLoginRegisterAlert } from 'actions/UI';
import { kioskModeEnabled } from 'selectors/features';
import LoginIcon from 'components/Icons/LoginIcon';
import RegisterIcon from 'components/Icons/RegisterIcon';
import globalMessages from 'components/globalMessages';

import { addCssPrefixTo } from 'utils';
import { Message, Container, ContinueAsGuest } from './styles';

const messages = defineMessages({
  loginMessage: {
    defaultMessage: 'For a faster checkout, login or register an account.',
  },
  continueAsGuest: {
    defaultMessage: 'or continue as a guest',
  },
});

const propTypes = {
  isLoggedIn: PropTypes.bool,
  kioskMode: PropTypes.bool,
  openLoginRegisterAlert: PropTypes.func,
};

const defaultProps = {
  isLoggedIn: false,
  kioskMode: false,
  openLoginRegisterAlert: () => undefined,
};

const CheckoutLoginRegister = ({ isLoggedIn, kioskMode, openLoginRegisterAlert }) =>
  isLoggedIn || kioskMode ? null : (
    <div className={`${addCssPrefixTo('ACCOUNT_DETAILS')} u-textCenter`}>
      <Message>
        <FormattedMessage {...messages.loginMessage} />
      </Message>

      <Container>
        <ActionButton
          primaryButton={true}
          buttonIcon={<LoginIcon />}
          label={<FormattedMessage {...globalMessages.login} />}
          buttonType="button"
          onClick={() => openLoginRegisterAlert()}
        />

        <ActionButton
          primaryButton={true}
          buttonIcon={<RegisterIcon />}
          label={<FormattedMessage {...globalMessages.signUp} />}
          buttonType="button"
          onClick={() => openLoginRegisterAlert('REGISTER_FORM')}
        />
      </Container>

      <ContinueAsGuest>
        <FormattedMessage {...messages.continueAsGuest} />
      </ContinueAsGuest>
    </div>
  );

CheckoutLoginRegister.propTypes = propTypes;
CheckoutLoginRegister.defaultProps = defaultProps;

export default connect(
  state => ({
    isLoggedIn: isLoggedIn(state),
    kioskMode: kioskModeEnabled(state),
  }),
  { openLoginRegisterAlert }
)(CheckoutLoginRegister);
