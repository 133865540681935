import { useEffect, useState } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectServiceId } from 'selectors/browse';
import { selectLoyaltyTypeByService, selectLoyaltyUser } from 'selectors/loyalty';
import { selectOrder, selectOrderTotal } from 'selectors/order';
import { fetchHelper } from 'utils';

export const auraEarnableError = defineMessage({
  defaultMessage:
    'There is a technical issue with adding your Aura points. Please contact a team member or Customer Service at support@aura-mena.com for support. Thank you!',
});

/**
 * loyalty hook to provide loading state, user info, loyalty type and earnable points
 */
const useLoyalty = (): {
  type: string; // type loyalty better here in the future?
  loading: boolean;
  user?: {
    accountNo: string;
    points: number;
    tier: string; // also type tiers better?
  };
  earnable: number;
  earnableError?: string;
} => {
  const intl = useIntl();

  const serviceId = useSelector(selectServiceId);
  const order = useSelector(selectOrder)?.toJS();
  const orderTotal = useSelector(selectOrderTotal);

  const [loading, setLoading] = useState(false);
  const [earnable, setEarnable] = useState<number>(0);
  const [earnableError, setEarnableError] = useState<string>('');
  const type = useSelector(state => selectLoyaltyTypeByService(state, serviceId));
  const user = useSelector(selectLoyaltyUser);

  // listen for changes on order and update earnable points
  useEffect(() => {
    if (!type) return;
    setLoading(true);
    setEarnable(0);
    fetchHelper(
      `/api/loyalty/order-to-points`,
      'POST',
      { order },
      data => {
        setLoading(false);
        setEarnable(data.points);
      },
      () => {
        setLoading(false);
        setEarnable(0);
        setEarnableError(intl.formatMessage(auraEarnableError));
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTotal, type, user]);

  return {
    type,
    loading,
    user: user?.toJS(),
    earnable,
    earnableError,
  };
};

export default useLoyalty;
