import { browserHistory } from 'react-router';

import { selectAddressByAddressId, selectAddressComponents } from 'selectors/user';
import { selectViolations } from 'selectors/payment';
import {
  selectOrderItems,
  selectOrderFinalTotal,
  getGratuityPrice,
  getGratuity,
  getOrderGratuity,
} from 'selectors/order';
import { selectSessionId, selectTabOrder } from 'selectors/session';
import fetchHelper from 'utils/fetchHelper';
import { selectServiceId, selectMenuTypeId } from 'selectors/browse';
import { setCompletedOrder, expireOrder, checkOrderViolations, updateOrderFromServer } from 'actions/order';
import { removeDetail } from 'actions/storage';
import { openIframeAlert } from 'actions/UI';
import { gratuityType } from 'selectors/orderItemTypes';
import { SUBMIT_PAYMENT_REQUEST, SUBMIT_PAYMENT_COMPLETE } from 'actions/payment/constants';
import { updateSettings } from 'components/Pages/User/updateSettings';
import { getBrowserInfo, setPaymentError } from 'actions/payment';
import {
  getVenueId,
  isDineInOrderingFlow,
  selectAvailablePaymentMethods,
  selectDineInFulfilmentType,
  selectDineInPasscode,
} from 'selectors/root';
import { ERROR_ORDER_ITEMS, ERROR_ORDER_VALIDATION, ERROR_CLOSED_TAB } from 'errorHandling/errorKeys';
import { selectLoyaltySpend } from 'selectors/loyalty';
// eslint-disable-next-line import/no-self-import
import * as submitOrderActions from './submitOrder';

export const submitTabPayment = (formDetails, callback) => (dispatch, getState) => {
  const state = getState();

  const orderTotal = selectTabOrder(state).get('total');
  const gratuity = getGratuity(state);

  const gratuityAmount =
    gratuity?.get('type') === 'fixed' ? gratuity.get('value') : getGratuityPrice(orderTotal, gratuity);

  const details = Object.assign(formDetails, getDineInDetails(state), {
    serviceId: selectServiceId(state),
    menuTypeId: selectMenuTypeId(state),
    browser: getBrowserInfo(),
    methodKey: formDetails.payment,
    details: { gratuity_total: gratuityAmount },
  });

  if (gratuityAmount > 0) details.amount = orderTotal + gratuityAmount;

  const fail = res => dispatch(submitOrderFailure(res, details, callback));

  const success = res => dispatch(submitOrderSuccess(res, details, callback));

  fetchHelper('/api/pay-dine-in-order', 'POST', details, success, fail);
};

export const confirmOrder =
  (details, callback, options = {}) =>
  dispatch => {
    const postPayment = () => {
      dispatch(submitOrderActions.submitOrder(details, callback, options));
    };

    if (details.thirdPartyPermission) {
      dispatch(
        updateSettings({ third_party_permission: details.thirdPartyPermission }, postPayment, postPayment)
      );
    } else {
      postPayment();
    }
  };

export const getGratuityItem = gratuityAmount => ({
  name: 'Gratuity',
  unit_price: Math.round(gratuityAmount),
  quantity: 1,
  type: gratuityType,
});

export const getOrderItems = state => {
  const orderItems = selectOrderItems(state);

  const gratuity = isDineInOrderingFlow(state) ? 0 : getOrderGratuity(state);
  const items = !gratuity ? orderItems : orderItems.push(getGratuityItem(gratuity));

  return items.toJS();
};

export const getAddressDetails = (state, details) => {
  if (details?.addressSelection)
    return selectAddressByAddressId(state, details.addressSelection).toJS() || {};

  if (details?.addressInRadius) {
    const addressComponents = selectAddressComponents(state)?.toJS();

    return {
      address1: addressComponents?.address1,
      town_city: addressComponents?.town_city,
      postcode: addressComponents?.postcode,
      county: addressComponents?.county,
      country: addressComponents?.country,
    };
  }

  return {};
};

export const getDineInDetails = (state, details) => {
  if (!isDineInOrderingFlow(state)) return {};

  const tabId = selectTabOrder(state)?.get('id');

  return {
    fulfilment_method: selectDineInFulfilmentType(state)?.get('id'),
    sessionId: selectSessionId(state),
    passcode: details?.passcode || selectDineInPasscode(state),
    ...(tabId ? { tabId } : {}),
  };
};

export const getRequestBody = (state, details) => {
  const addressDetails = getAddressDetails(state, details);
  const dineInDetails = getDineInDetails(state, details);

  return Object.assign(details, addressDetails, dineInDetails, {
    items: getOrderItems(state),
    methodKey: getMethodKey(state, details),
    serviceId: selectServiceId(state),
    menuTypeId: selectMenuTypeId(state),
    browser: getBrowserInfo(),
    loyaltySpend: selectLoyaltySpend(state),
  });
};

const getMethodKey = (state, details) => {
  const orderTotal = selectOrderFinalTotal(state);

  if (orderTotal === 0) {
    const pms = selectAvailablePaymentMethods(state, orderTotal);

    if (pms.size === 0) {
      return null;
    }

    return pms.first().get('key');
  }

  return details.payment;
};

export const submitOrderSuccess = (res, requestBody, callback) => dispatch => {
  if (res.completedOrder) {
    dispatch(setCompletedOrder(res.completedOrder));
  }
  if (res?.order) {
    dispatch(updateOrderFromServer(res.order));
  }

  if (res.redirectUrl) {
    window.location = res.redirectUrl;
  } else if (res.iframeUrl) {
    dispatch(openIframeAlert(res.iframeUrl));
    callback({ success: true });
  } else if (res.paymentError) {
    dispatch(setPaymentError(true, res.errorMessage));
    dispatch({
      type: SUBMIT_PAYMENT_COMPLETE,
      success: false,
      violations: {},
    });
    callback({ success: true }); // payment error shown, don't show default error message
  }
};

export const submitOrderFailure = (res, requestBody, callback) => dispatch => {
  let errorKey = res?.errorKey || null;

  if (res.violations) {
    dispatch(checkOrderViolations(res.violations, requestBody?.fulfilment_method));
    if (res.violations?.passcode && window.location.pathname !== '/passcode') {
      if (requestBody?.serviceId) dispatch(removeDetail(`passcode_${requestBody.serviceId}`));
      return browserHistory.push('/passcode');
    }
    if (Object.keys(res.violations).filter(key => key === 'items').length) errorKey = ERROR_ORDER_ITEMS;
    if (Object.keys(res.violations).filter(key => key === ERROR_CLOSED_TAB).length)
      errorKey = ERROR_CLOSED_TAB;

    if (!errorKey) errorKey = ERROR_ORDER_VALIDATION;
  }
  if (res.clearOrder) {
    dispatch(expireOrder());
  }

  const violations = Object.assign({}, res?.violations);
  delete violations.items;
  dispatch({
    type: SUBMIT_PAYMENT_COMPLETE,
    success: false,
    violations,
    errorKey,
  });
  callback({ error: true });

  if (errorKey === ERROR_CLOSED_TAB) {
    return browserHistory.push('/thankyou/tab');
  }
};

export const submitOrder = (details, callback) => (dispatch, getState) => {
  const state = getState();
  const isDineInFlow = isDineInOrderingFlow(state);

  if (!isDineInFlow) {
    const errors = selectViolations(state);
    if (errors && !!errors.size) {
      callback({ error: true });
      return false;
    }

    // This block exists for testing whether a delay fixes the issues described with
    // over payment. https://wiqlimited.atlassian.net/browse/SUP-1168
    const venueId = getVenueId(state);
    if (venueId === 117) {
      setTimeout(() => {
        dispatch({
          type: SUBMIT_PAYMENT_REQUEST,
          methodId: details.payment,
        });
      }, 2000);
    } else {
      dispatch({
        type: SUBMIT_PAYMENT_REQUEST,
        methodId: details.payment,
      });
    }
  }

  const requestBody = getRequestBody(state, details);

  const success = res => dispatch(submitOrderSuccess(res, requestBody, callback));
  const fail = res => dispatch(submitOrderFailure(res, requestBody, callback));

  let submitUrl = '/api/submit-order';
  if (isDineInFlow) submitUrl = '/api/submit-dine-in-order';

  fetchHelper(submitUrl, 'POST', requestBody, success, fail);
};
