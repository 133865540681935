import styled from 'styled-components';
import { addCssPrefixTo } from '../../utils';

export const MenuContainer = styled.ul.attrs({
  className: `container ${addCssPrefixTo('MENU_CONTAINER')}`,
})`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ isGridView }) => (isGridView ? '10px' : '20px')};
  list-style: none;
`;
