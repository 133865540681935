import styled from 'styled-components';
import { fontSizeBig } from 'assets/styles/fonts';
import { H1 } from 'components/Typography';

interface DetailsProps {
  pageView?: boolean;
}

interface FlexBoxProps {
  hasImage?: boolean;
}

interface Theme {
  color: {
    primaryText: string;
  };
}

export const Details = styled.div<DetailsProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;

  html[dir='rtl'] & {
    text-align: right;
  }
`;

export const FlexBox = styled.div<FlexBoxProps>`
  @media (max-width: 779px) {
    flex-grow: 1;
  }

  @media (min-width: 767px) {
    padding: 0 5px;
    width: ${({ hasImage }) => hasImage && '50%'};
  }
`;

export const ProductName = styled.div<{ theme: Theme }>`
  color: ${({ theme }) => theme.color.primaryText};
  display: flex;
  gap: 2px;
  align-items: center;
`;

export const StyledH1 = styled(H1)`
  display: inline-block;
  line-height: 1.2;
`;

export const Price = styled.p<{ theme: Theme }>`
  text-align: left;
  color: ${({ theme }) => theme.color.primaryText};
  ${fontSizeBig};
`;

export const Description = styled.p`
  text-align: left;
  white-space: pre-line;
`;
