import { fromJS, List, Map } from 'immutable';

import {
  getItemPrice,
  getModifierPrice,
  getProductById,
  noFoodInCart,
  selectActiveMenus,
  selectMenuTypeId,
  selectProducts,
  selectServiceId,
} from 'selectors/browse';
import {
  getCollectionPointsByServiceId,
  getServiceDefaultGratuity,
  getServiceId,
  getServiceLocationNames,
  getTaxCategoryById,
  isAvailablePaymentMethodByKey,
  selectFirstAvailableTimeSlot,
  selectFulfilmentMethod,
  selectPaymentMethods,
  selectService,
  selectVenueTimezone,
} from 'selectors/root';
import { getDetail, getDetails } from 'selectors/storage';
import { selectUser, selectUserThirdPartySetting } from 'selectors/user';
import { disableCovers, shouldShowGratuity, shouldShowPriceExcludingTax } from 'selectors/features';
import permissionState from 'components/ThirdPartyPermission/permissionState';
import countriesMap from 'utils/countriesMap';
import { convertDateToVenueTimezone } from 'utils/date';
import {
  deliveryChargeType,
  gratuityType,
  productType,
  promotionType,
  serviceChargeType,
  trayChargeType,
} from 'selectors/orderItemTypes';

export const orderQuantity = (state, serviceId) => {
  const items = selectOrderItems(state, serviceId);
  if (!items.size) return 0;
  return items.reduce((inOrder, item) => {
    if (item.get('type') !== productType) return inOrder;
    return inOrder + item.get('quantity');
  }, 0);
};

export const selectOrder = (
  state,
  serviceId = selectServiceId(state),
  menuTypeId = selectMenuTypeId(state)
) => {
  const orderIndex = state
    .get('orderList')
    .findIndex(
      order =>
        parseInt(order.get('serviceId'), 10) === parseInt(serviceId, 10) &&
        order.get('menuTypeId') === menuTypeId
    );
  if (orderIndex === -1) return undefined;
  return state.getIn(['orderList', orderIndex]);
};

export const selectOrderItems = (
  state,
  serviceId = selectServiceId(state),
  menuTypeId = selectMenuTypeId(state)
) => {
  const order = selectOrder(state, serviceId, menuTypeId);
  return order?.size && order.get('items')?.size
    ? order.get('items').filter(item => item.get('type') !== gratuityType)
    : new List();
};

export const selectGratuityOrderItems = (
  state,
  serviceId = selectServiceId(state),
  menuTypeId = selectMenuTypeId(state)
) => selectOrderItems(state, serviceId, menuTypeId).filter(item => item.get('type') !== trayChargeType);

export const selectLastItemIndex = state => {
  const lastItemId = selectLastItemId(state);
  const order = selectOrder(state);
  if (!order) return undefined;
  const currentProducts = order.get('items').filter(item => item.get('type') === productType);
  return (
    currentProducts.size && currentProducts.findKey(item => item.get('section_product_id') === lastItemId)
  );
};

export const getServiceEstimatedWait = state => {
  const service = selectService(state);
  return service?.get('estimated_wait_minutes') || null;
};

export const selectOrderServiceCountry = (state, serviceId) =>
  state.getIn(['venue', 'services', serviceId, 'country']);

export const selectLastItemId = state => selectOrder(state) && selectOrder(state).get('lastItemId');
export const getOrderMenuTypeId = state => selectOrder(state) && selectOrder(state).get('menuTypeId');

export const getCheckoutUrl = (
  state,
  serviceId = selectServiceId(state),
  menuTypeId = selectMenuTypeId(state)
) => {
  if (!serviceId || !menuTypeId) return '/';
  return `/checkout/${serviceId}/${menuTypeId}`;
};

export const showCheckoutGratuity = state => !!shouldShowGratuity(state);

export const getGratuity = state => {
  const selectedGratuity = selectOrder(state)?.get('gratuity');
  if (typeof selectedGratuity !== 'undefined') return selectedGratuity;

  const serviceId = getServiceId(state);
  return new Map({
    type: 'percentage',
    value: getServiceDefaultGratuity(state, serviceId),
  });
};

export const selectOrderServiceId = state => selectOrder(state) && selectOrder(state).get('serviceId');
export const selectOrderFulfilmentMethodId = state =>
  selectOrder(state) && selectOrder(state).get('fulfilmentMethod');
export const selectOrderSyncStatus = state => selectOrder(state) && selectOrder(state).get('orderSync');
export const selectOrderIsSyncing = state =>
  selectOrderSyncStatus(state)?.get('status') === 'syncing' || false;
export const selectPromotionCodeDetail = state => {
  if (!canShowCouponField(state)) return null;

  return getDetail(state, 'promotion_coupon');
};
export const selectPromotionCouponValidating = state =>
  selectOrder(state) && selectOrder(state).get('validatingPromotionCoupon');
export const selectPromotionCouponApplied = state =>
  selectOrder(state) && selectOrder(state).get('promotion_coupon');

export const selectOrderState = state => selectOrder(state) && selectOrder(state).get('state');

export const selectCollectionPoints = (state, serviceId = selectOrderServiceId(state)) => {
  const collectionPoints = getCollectionPointsByServiceId(state, serviceId);
  return collectionPoints.map(option => {
    if (!option.get('value') && option.get('name')) return option.set('value', option.get('name'));
    return option;
  });
};

export const getDefaultCollectionPoint = state => {
  const collectionPoints = selectCollectionPoints(state);
  if (collectionPoints && collectionPoints.size === 1) {
    return collectionPoints.getIn(['0', 'id']);
  }
};

export const validateTimeSlot = (state, time, timeslotDate = new Date()) => {
  if (!time) return '';
  const formatedTimeslotDate =
    typeof timeslotDate === 'object' || (typeof timeslotDate === 'string' && timeslotDate?.includes(':'))
      ? timeslotDate
      : `${timeslotDate} 00:00:00`;

  if (time.indexOf(':') !== -1) {
    const date = new Date(formatedTimeslotDate);
    const timeParts = time.split(':');
    date.setHours(timeParts[0]);
    date.setMinutes(timeParts[1]);
    date.setSeconds(0);
    const venueTimezone = selectVenueTimezone(state);
    const venueDate = convertDateToVenueTimezone(new Date(), venueTimezone);
    if (venueDate > date) return '';
  }
  return time;
};

export const selectNotesFromItem = (state, itemIndex) =>
  selectOrderItems(state).getIn([itemIndex, 'notes']) || '';

export const getTaxNotIncludedInPrice = item =>
  item.get('tax_total') > 0 && !item.get('tax_included_in_price') ? item.get('tax_total') : 0;

export const selectOrderTaxTotal = state =>
  selectOrderTaxTotals(state).reduce(
    (currentTaxTotal, taxBreakdown) => currentTaxTotal + (taxBreakdown.get('total') || 0),
    0
  );

export const selectOrderTaxTotals = state => {
  const order = selectOrder(state);
  return selectTaxTotals(state, order);
};

export const selectTaxTotals = (state, order) => {
  if (!order || !order?.get('tax_totals')) return new List();
  return order?.get('tax_totals').map(taxBreakdown => {
    const taxCategory = getTaxCategoryById(state, taxBreakdown.get('tax_category_id'));
    return fromJS({
      ...taxBreakdown.toJS(),
      name: taxCategory?.get('name'),
    });
  });
};

export const selectTaxTotal = taxTotals =>
  taxTotals.reduce((currentTaxTotal, taxBreakdown) => currentTaxTotal + (taxBreakdown.get('total') || 0), 0);

export const selectOrderTotal = (state, { gross = false, gratuityTotal = false } = {}) => {
  const orderItems = gratuityTotal ? selectGratuityOrderItems(state) : selectOrderItems(state);
  return orderItems.reduce((orderTotal, orderItem) => {
    const itemPrice = orderItem.get('unit_price');
    if (gross && itemPrice < 0) return orderTotal;
    const orderItemPrice = getOrderItemTotal(state, orderItem);
    return orderTotal + orderItemPrice;
  }, 0);
};

export const getOrderItemTotal = (state, orderItem) => {
  const itemPrice = getItemPrice(state, orderItem.get('unit_price'), orderItem.get('tax_category_ids'));

  const itemQuantity = orderItem.get('quantity') || 1;
  const modifierPrice = !orderItem.get('modifiers')
    ? 0
    : orderItem
        .get('modifiers')
        .reduce(
          (modifierTotal, modifier) =>
            modifierTotal +
            (modifier
              .get('values')
              .reduce(
                (valueTotal, value) =>
                  valueTotal + getModifierPrice(state, value, orderItem.get('tax_category_ids')),
                0
              ) || 0),
          0
        );

  return (itemPrice + modifierPrice) * itemQuantity;
};

// this is a plain function, not a selector. Should probably live somewhere else
export const getGratuityPrice = (orderTotal, gratuity) => {
  if (!gratuity) return 0;
  if (gratuity.get('type') === 'fixed') return gratuity.get('value');

  return orderTotal * (gratuity.get('value') / 100);
};

export const getOrderGratuity = state => {
  if (!showCheckoutGratuity(state)) return 0;

  const orderTotal = selectOrderTotal(state, { gratuityTotal: true });
  return getGratuityPrice(orderTotal, getGratuity(state));
};

export const selectOrderFinalTotal = state =>
  getFinalTotal(state, selectOrderTotal(state), selectOrderTaxTotal(state), getOrderGratuity(state));

export const getFinalTotal = (state, orderTotal, taxTotal, gratuity) => {
  if (shouldShowPriceExcludingTax(state)) return orderTotal + taxTotal + gratuity;
  return orderTotal + gratuity - (state.getIn(['loyalty', 'loyaltySpend']) || 0);
};

export const getOrderItemByIndex = (state, index) => selectOrderItems(state).get(index);

export const quantityOfItemsInOrder = (state, id) => {
  const items = selectOrderItems(state);

  if (!items.size) return 0;
  return items.reduce((inOrder, item) => {
    if (item.get('product') === id) return inOrder + item.get('quantity');
    return inOrder;
  }, 0);
};

export const validatedOrderItems = (
  state,
  serviceId = selectServiceId(state),
  menuTypeId = selectMenuTypeId(state)
) => {
  const activeMenus = selectActiveMenus(state, menuTypeId, serviceId);
  const filteredItems = getOrderProductItems(state, serviceId, menuTypeId);

  if (!activeMenus) return filteredItems;
  return filteredItems.filter(
    orderItem =>
      isProductOnMenus(state, orderItem.get('section_product_id'), activeMenus) || orderItem.get('key')
  );
};

export const donationOrderItems = state => validatedOrderItems(state)?.filter(item => item.get('key'));
export const donationOrderItemsSize = state => donationOrderItems(state)?.size;

export const selectProductsInCartIds = state =>
  validatedOrderItems(state)
    .filter(item => item.get('type') === productType)
    .map(item => item.get('product'));
export const selectProductsInCartIdsWhereReportingCatNull = state =>
  selectProductsInCartIds(state)
    .map(id => getProductById(state, id))
    .filter(prod => !prod.get('reporting_category'))
    .map(prod => prod.get('id'))
    .filter(prod => prod);

export const expiredOrderItems = (
  state,
  serviceId = selectServiceId(state),
  menuTypeId = selectMenuTypeId(state)
) => {
  const activeMenus = selectActiveMenus(state, menuTypeId, serviceId);
  const filteredItems = getOrderProductItems(state, serviceId, menuTypeId);

  if (!activeMenus) return new List();
  return filteredItems.filter(
    orderItem =>
      !isProductOnMenus(state, orderItem.get('section_product_id'), activeMenus) && !orderItem.get('key')
  );
};

export const isProductOnMenus = (state, productId, menus) =>
  menus.some(menu => isProductOnMenu(state, productId, menu));

const isProductOnMenu = (state, productId, menu) =>
  menu
    .get('sections')
    .some(section =>
      section.get('section_products').find(sectionProduct => sectionProduct.get('id') === productId)
    );

export const orderItems = state =>
  selectOrderItems(state).reduce((items, item) => {
    const existingIndex = items.findKey(existingItem => existingItem.get('id') === item.get('id'));

    if (existingIndex !== undefined) {
      return items.update(existingIndex, i => i.update('quantity', q => q + 1));
    }
    return items.push(
      selectProducts(state)
        .find(i => i.get('id') === item.get('id'))
        .set('quantity', 1)
    );
  }, new List());

export const getOrderProductItems = (
  state,
  serviceId = selectServiceId(state),
  menuTypeId = selectMenuTypeId(state)
) => getOrderItemsByType(state, [productType], serviceId, menuTypeId);

export const getOrderPromotionItems = state => getOrderItemsByType(state, [promotionType]);

export const getOrderChargeItems = (
  state,
  serviceId = selectServiceId(state),
  menuTypeId = selectMenuTypeId(state)
) =>
  getOrderItemsByType(state, [trayChargeType, deliveryChargeType, serviceChargeType], serviceId, menuTypeId);

export const getOrderItemsByType = (
  state,
  types = [],
  serviceId = selectServiceId(state),
  menuTypeId = selectMenuTypeId(state)
) =>
  selectOrderItems(state, serviceId, menuTypeId)
    ? selectOrderItems(state, serviceId, menuTypeId).filter(item => types.indexOf(item.get('type')) !== -1)
    : new List();

export const returnStackedPromotions = state =>
  getOrderPromotionItems(state)
    .groupBy(promotion => promotion.get('promotionId')) // Group promotions by IDs
    .toList()
    .map(promotion =>
      promotion.reduce(
        // Convert grouped promotions (map) to List
        // Reduce each list of promotions to a single promotion, with prices summed
        (collated, item) => collated.set('unit_price', collated.get('unit_price') + item.get('unit_price'))
      )
    );

export const venueRequiresCouponCode = state =>
  new List(state.get('promotions'))
    .filter(promotion => promotion.get('enabled'))
    .some(promotion => promotion.get('coupon_based'));

export const hasDiscountProviderEnabled = state =>
  state.getIn(['venue', 'organisation', 'hasDiscountProviderEnabled'], false);

export const canShowCouponField = state =>
  hasDiscountProviderEnabled(state) || venueRequiresCouponCode(state);

export const selectAcceptedCardTypes = state => {
  const paymentMethods = selectPaymentMethods(state);

  const allCardTypes = paymentMethods
    .filter(method => !!method.get('card_types')) // get only methods with card_types property
    .map(method => method.get('card_types')) // map to get an array of card_types arrays
    .reduce((allCardTypes, cardTypes) => allCardTypes.concat(cardTypes), new List()) // flatten the array
    .toSet()
    .toList(); // get only unique values

  return allCardTypes;
};

export const getDefaultTelephoneLocale = state => {
  const serviceId = selectOrderServiceId(state);
  const serviceCountry = selectOrderServiceCountry(state, serviceId);
  const countryCode = countriesMap[serviceCountry] || serviceCountry;
  return countryCode ? countryCode.toLowerCase() : countryCode;
};

export const getFormValues = (state, fields, overrideValues = {}) => {
  const user = selectUser(state);
  const details = getDetails(state) || new Map();

  const values = {};
  fields.forEach(field => {
    if (field.name) {
      let value = overrideValues[field.name] || user.get(field.name) || details.get(field.name);

      if (field.name === 'fulfilmentDateTime' && value) {
        value = validateTimeSlot(state, value.time, value.date);
      }

      if (field.name === 'payment' && value && !isAvailablePaymentMethodByKey(state, value)) {
        value = '';
      }

      if (field.locationKey && !getServiceLocationNames(state, field.locationKey).size && isNaN(value)) {
        value = '';
      }

      if (field.name === 'covers' && (disableCovers(state) || noFoodInCart(state))) {
        value = 1;
      }

      if (field.name === 'collectionPoint' && value) {
        const collectionPoints = selectCollectionPoints(state);
        const singleCollectionPointId = collectionPoints.getIn(['0', 'id']);

        if (collectionPoints?.size === 1 && value !== singleCollectionPointId) {
          value = singleCollectionPointId;
        }
      }

      if (!value) {
        value = getFieldDefaultValue(state)(field);
      }

      values[field.name] = value;
    }
  });

  return values;
};

const getFieldDefaultValue = state => field => {
  if (field.name === 'collectionPoint') {
    return getDefaultCollectionPoint(state) || '';
  }
  if (field.name === 'fulfilmentDateTime') {
    return selectFirstAvailableTimeSlot(state);
  }
  if (field.name === 'allergenCheckbox') {
    return false;
  }
  if (field.name === 'user_marketing_consent') {
    return false;
  }
  if (field.name === 'thirdPartyPermission')
    return selectUserThirdPartySetting(state) || permissionState.DECLINED;
  if (field.component === 'DropdownField') {
    if (field.options && field.options.length === 1) {
      return field.options[0].value || '';
    }
  }
  if (field.name === 'service') {
    return getServiceId(state) || '';
  }
  return '';
};

export const hideIfInCart = (productsList, productsInCart) =>
  productsList.filter(
    item =>
      productsInCart.filter(product => product.get('product') === item.getIn(['product', 'id'])).size === 0
  );

export const getCouponUpdatedBalance = state => {
  const items = getOrderPromotionItems(state).groupBy(promotion => promotion.get('promotionId'));
  const promotionIdentifierCode = getDetail(state, 'promotionCode') || '';
  const list = items.get(promotionIdentifierCode) || [];

  const deductions = list.reduce((acc, item) => {
    acc += item.get('total');
    return acc;
  }, 0);

  const couponBalance = getDetail(state, 'promotionCouponBalance') || 0;
  const remaining = couponBalance + deductions;

  return remaining < 0 ? 0 : remaining;
};

export const getCoversOptions = state => {
  const fulfilment = selectFulfilmentMethod(state);
  const methodLocationType = fulfilment.get('location_type');

  const covers = getFormValues(state, [{ name: 'covers' }]);
  const location = {};

  switch (methodLocationType) {
    case 'table':
      location.tableNumber = getFormValues(state, [{ name: 'tableNumber' }]).tableNumber;
      break;
    case 'sunbed':
      location.sunbedNumber = getFormValues(state, [{ name: 'sunbedNumber' }]).sunbedNumber;
      break;
    case 'room':
      location.roomNumber = getFormValues(state, [{ name: 'roomNumber' }]).roomNumber;
      break;
    default:
      break;
  }

  const values = [...Object.values(covers), ...Object.values(location)];
  const haveNoValue = val => ['', undefined, null].includes(val);

  if (values.some(haveNoValue)) return {};
  return { ...covers, ...location };
};
