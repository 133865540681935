import { fromJS } from 'immutable';
import { browserHistory } from 'react-router';

import {
  ORDER_REVIEW,
  ORDER_STATE_FAILED,
  ORDER_STATE_REJECTED,
  PRODUCT_CUSTOMISATION,
  PRODUCT_OPTINS,
} from 'appConstants';
import { FULFILMENT_TIME_UNAVAILABLE, NO_USER_OR_EMAIL } from 'errorHandling/errorCodes';
import {
  ERROR_INSUFFICIENT_STOCK,
  ERROR_ORDER_ITEM_NOT_FOUND,
  ERROR_OUT_OF_STOCK,
  ERROR_PRODUCT_DISABLED,
} from 'errorHandling/errorKeys';
import { OPEN_ALERT } from 'actions/UI/constants';
import fetchHelper from 'utils/fetchHelper';
import {
  openClearNotesAlert,
  openConfirmLocationAlert,
  openRemoveProductAlert,
  openTermsAndConditionsAlert,
  openUnavailableItemsAlert,
} from 'actions/UI';
import { selectMenuTypeId, selectServiceId } from 'selectors/browse';
import { isLocationConfirmed } from 'selectors/storage';
import { hasProductCustomisationAccordion, shouldConfirmLocation } from 'selectors/features';
import {
  getCoversOptions,
  getFormValues,
  selectOrderItems,
  selectPromotionCodeDetail,
} from 'selectors/order';
import { selectFulfilmentMethod } from 'selectors/root';
import { removeDetail, setDetail } from 'actions/storage';
import { getFulfilmentMethodTimeSlots, setVenueOffline } from 'actions/venue';
import { fetchProducts, updateProducts } from 'actions/browse';
import { promotionType } from 'selectors/orderItemTypes';
import { isLoggedIn } from 'selectors/user';
import { forceLogout, resetLocale } from 'actions/user';
import { setLoyaltyUser } from 'actions/loyalty';
import {
  ADD_DONATION_TO_ORDER,
  ADD_PRODUCT_TO_ORDER,
  CLEAR_ORDER,
  DECREASE_DONATION_QUANTITY,
  DECREASE_ITEM_QUANTITY,
  FAILED_ORDER_UPDATE,
  GET_ORDERING_DEVICES,
  REGISTER_ORDERING_DEVICE,
  REMOVE_DONATION_FROM_ORDER,
  REMOVE_PRODUCT_FROM_ORDER,
  REPLACE_ORDER,
  RETRY_ORDER_UPDATE,
  SET_COMPLETED_ORDER,
  SET_FULFILMENT_METHOD,
  SET_GRATUITY,
  SET_ITEM_NOTES,
  SET_ORDER_VIOLATIONS,
  SET_ORDERING_DEVICE,
  SET_PROMOTION_APPLIED,
  TERMS_AND_CONDITIONS_ALERT,
  UNREGISTER_ORDERING_DEVICE,
  UPDATE_COMPLETED_ORDER_NUMBER,
  UPDATE_COMPLETED_ORDER_STATE,
  UPDATE_ITEM,
  UPDATE_ORDER_FROM_SERVER,
  VALIDATING_PROMOTION,
} from './constants';

export const removeItem =
  (index, item, source = 'order review') =>
  dispatch => {
    const confirmRemove = () => {
      dispatch({
        type: REMOVE_PRODUCT_FROM_ORDER,
        index,
        item,
        syncOrder: true,
        source,
      });
    };
    dispatch(openRemoveProductAlert(confirmRemove, index, item));
  };

export const removeDonationFromOrder =
  (id, source = 'donation') =>
  dispatch =>
    dispatch({
      type: REMOVE_DONATION_FROM_ORDER,
      id,
      syncOrder: true,
      source,
    });

export const editItem = (itemIndex, state) => ({
  type: OPEN_ALERT,
  alertType: hasProductCustomisationAccordion(state) ? PRODUCT_OPTINS : PRODUCT_CUSTOMISATION,
  alertProps: { itemIndex, editing: true, fullScreen: window.innerWidth < 768 },
});

export const decreaseItemQuantity = id => ({
  type: DECREASE_ITEM_QUANTITY,
  id,
  syncOrder: true,
});

export const decreaseDonationQuantity = id => dispatch =>
  dispatch({
    type: DECREASE_DONATION_QUANTITY,
    id,
    syncOrder: true,
  });

export const setItemNotes = (itemIndex, notes) => ({
  type: SET_ITEM_NOTES,
  itemIndex,
  notes,
  syncOrder: true,
});

export const setFulfilmentMethodId = fulfilmentMethodId => ({
  type: SET_FULFILMENT_METHOD,
  fulfilmentMethodId,
});

export const updateItem = (itemIndex, quantity, modifiers, notes) => ({
  type: UPDATE_ITEM,
  quantity,
  itemIndex,
  modifiers,
  notes,
  syncOrder: true,
});

export const customiseProduct = (item, menuType, serviceId, source) => (dispatch, getState) => {
  const state = getState();
  const showAccordion = hasProductCustomisationAccordion(state);

  dispatch({
    type: OPEN_ALERT,
    alertType: showAccordion ? PRODUCT_OPTINS : PRODUCT_CUSTOMISATION,
    alertProps: {
      fullScreen: true,
      item,
      menuType,
      serviceId,
      editing: false,
      source,
      showBaseModifiers: source !== 'customiseFromOnInfo',
    },
  });
};

export const orderReview = () => dispatch => {
  if (window.innerWidth > 768) {
    return dispatch({
      type: OPEN_ALERT,
      alertType: ORDER_REVIEW,
    });
  }
  browserHistory.push('/review');
};

export const setGratuity = (gratuity, { type: gratuityType = 'percentage' } = {}) => ({
  type: SET_GRATUITY,
  gratuity,
  gratuityType,
});

export const clearItemNotes = (itemIndex, callback) => dispatch => {
  const confirm = () => {
    dispatch({
      type: SET_ITEM_NOTES,
      itemIndex,
      notes: undefined,
      syncOrder: true,
    });
    if (typeof callback === 'function') callback();
  };

  dispatch(openClearNotesAlert(confirm));
};

export const addProductToOrder =
  (item, menuType, serviceId, modifiers, notes, quantity = 1, source = 'menu') =>
  dispatch => {
    const orderItem = item.set('section_product_id', item.get('id'));

    dispatch({
      type: ADD_PRODUCT_TO_ORDER,
      item: orderItem,
      menuType,
      serviceId,
      modifiers,
      notes,
      quantity,
      syncOrder: true,
      source,
    });
  };

export const addDonationToOrder =
  (item, menuType, serviceId, quantity = 1, source = 'donation') =>
  dispatch => {
    dispatch({
      type: ADD_DONATION_TO_ORDER,
      item,
      menuType,
      serviceId,
      quantity,
      syncOrder: true,
      source,
    });
  };

export const replaceOrderItemsInState = items => ({
  type: REPLACE_ORDER,
  items,
  syncOrder: true,
});

export const updateProductsFromViolations = itemViolations => dispatch => {
  const productsToUpdate = [];
  const productsToFetch = [];
  itemViolations.forEach(itemError => {
    if (itemError?.invalidValue) {
      if (itemError?.errorKey === ERROR_OUT_OF_STOCK) {
        productsToUpdate.push({ id: itemError?.invalidValue, quantity_in_stock: 0 });
      }
      if (itemError?.errorKey === ERROR_INSUFFICIENT_STOCK) {
        productsToFetch.push(itemError?.invalidValue);
      }
      if (itemError?.errorKey === ERROR_PRODUCT_DISABLED) {
        productsToUpdate.push({ id: itemError?.invalidValue, enabled: false });
      }
    }
  });
  if (productsToUpdate.length) dispatch(updateProducts(productsToUpdate));
  if (productsToFetch.length) dispatch(fetchProducts(productsToFetch));
};

export const orderItemViolationErrors = itemViolations => (dispatch, getState) => {
  const orderItems = selectOrderItems(getState());
  const newOrderItems = orderItems.slice().toJS();

  const unavailableItems = [];
  const removeItemIndexes = [];

  itemViolations.forEach(itemError => {
    if (itemError.errorKey === ERROR_ORDER_ITEM_NOT_FOUND && itemError?.invalidValue) {
      const removeIndex = newOrderItems.findIndex(item => item.id === itemError.invalidValue);
      if (removeIndex !== -1) {
        removeItemIndexes.push(itemError.removeIndex);
      }
    } else if (itemError?.itemIndex !== undefined) {
      removeItemIndexes.push(itemError.itemIndex);
      unavailableItems.push({ ...newOrderItems[itemError.itemIndex], errorReason: itemError?.errorKey });
    }
  });

  if (removeItemIndexes.length) {
    removeItemIndexes.reverse().forEach(index => {
      newOrderItems.splice(index, 1);
    });
    dispatch(replaceOrderItemsInState(fromJS(newOrderItems)));
  }

  if (unavailableItems.length) {
    dispatch(openUnavailableItemsAlert(() => {}, fromJS(unavailableItems)));
  }
  dispatch(updateProductsFromViolations(itemViolations));
};

export const handleFulfilmentTimeViolation = (violations, fulfilmentMethodId) => dispatch => {
  violations.forEach(violation => {
    if (violation?.code === FULFILMENT_TIME_UNAVAILABLE) {
      dispatch(removeDetail('fulfilmentDateTime'));
      if (fulfilmentMethodId) dispatch(getFulfilmentMethodTimeSlots(fulfilmentMethodId));
    }
  });
};

export const checkOrderViolations =
  (violations, fulfilmentMethodId = null) =>
  (dispatch, getState) => {
    const state = getState();

    if (violations?.venue_offline) {
      dispatch(setVenueOffline(true));
      return browserHistory.push('/');
    }
    if (violations?.items) {
      dispatch(orderItemViolationErrors(violations.items));
    }
    if (violations?.['fulfilment.time']) {
      dispatch(handleFulfilmentTimeViolation(violations['fulfilment.time'], fulfilmentMethodId));
    }
    if (violations?.email && isLoggedIn(state) && violations.email?.[0].code === NO_USER_OR_EMAIL) {
      dispatch(forceLogout());
    }
  };

export const updateOrderFromServer = order => ({
  type: UPDATE_ORDER_FROM_SERVER,
  items: order?.items,
  taxTotals: order?.tax_totals,
  state: order?.state,
});

export const failedOrderUpdate = () => ({
  type: FAILED_ORDER_UPDATE,
});

export const retryOrderUpdate = () => ({
  type: RETRY_ORDER_UPDATE,
  syncOrder: true,
});

export const setIsPromotionApplied = (promotionCoupon, promotionRes) => dispatch => {
  dispatch({
    type: SET_PROMOTION_APPLIED,
    couponApplied: promotionCoupon?.length ? promotionRes : false,
  });
};

export const getPromotionRes = (res, promotionCoupon) => {
  let promotionRes = false;

  if (res?.promotion_coupon?.code) {
    promotionRes = true;
  }

  if (promotionCoupon !== res?.promotion_coupon?.code) {
    promotionRes = false;
  }

  if (!res?.promotion_coupon) {
    const promotion = res?.items.find(item => item.type === promotionType && item?.discountProviderCoupon);

    if (promotion && promotionCoupon === promotion?.discountProviderCoupon) {
      promotionRes = true;
    }
  }

  return promotionRes;
};

export const updateOrderDetails =
  (details = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const menuTypeId = selectMenuTypeId(state);
    const serviceId = selectServiceId(state);
    const fulfilment = selectFulfilmentMethod(state);
    const fulfilmentId = fulfilment?.get('id');
    const methodLocationType = fulfilment?.get('location_type');
    const promotionCoupon = selectPromotionCodeDetail(state);
    const email = getFormValues(state, [{ name: 'email' }]);

    if (fulfilment?.get('request_covers')) Object.assign(details, { ...getCoversOptions(state) });

    const body = {
      ...details,
      ...email,
      promotion_coupon: promotionCoupon,
      fulfilment_method: fulfilmentId.toString(),
      menuTypeId,
      serviceId,
      methodLocationType,
    };

    const success = res => {
      dispatch(updateOrderFromServer(res));

      dispatch({
        type: SET_ORDER_VIOLATIONS,
        success: false,
      });
      dispatch(setDetail('promotionCouponBalance', res?.promotion_coupon?.balance || 0));
      dispatch(setDetail('promotionCode', res?.promotion_coupon?.promotion_code));
      dispatch(setIsPromotionApplied(promotionCoupon, getPromotionRes(res, promotionCoupon)));
    };

    const fail = res => {
      if (res.clearOrder) {
        dispatch(expireOrder());
      } else if (res.violations) {
        dispatch(checkOrderViolations(res.violations));
        dispatch({
          type: SET_ORDER_VIOLATIONS,
          success: false,
          violations: res.violations,
        });
      } else {
        dispatch({
          type: SET_ORDER_VIOLATIONS,
          success: false,
        });
      }
      dispatch(setIsPromotionApplied(promotionCoupon, false));
      dispatch(setDetail('promotionCouponBalance', res?.promotion_coupon?.balance || 0));
    };

    if (promotionCoupon !== null && promotionCoupon?.length) {
      dispatch({
        type: VALIDATING_PROMOTION,
      });
    }

    fetchHelper('/api/orders/details', 'POST', body, success, fail);
  };

export const expireOrder = () => (dispatch, getState) => {
  const orderItems = selectOrderItems(getState());
  const success = () => dispatch(clearOrder());

  if (orderItems.size) {
    dispatch(openUnavailableItemsAlert(success, orderItems));
  }
};

export const expireSession = () => dispatch => {
  fetch(`/api/loyalty/user/sign_out`, {
    method: 'POST',
  }).then(res => {
    if (res.ok) dispatch(setLoyaltyUser());
    dispatch(clearOrder());
    dispatch(resetLocale());
    window.location.replace('/');
  });
};

export const clearOrder = () => (dispatch, getState) => {
  const state = getState();
  const success = () => {
    dispatch({
      type: CLEAR_ORDER,
    });
  };

  const body = {
    serviceId: selectServiceId(state),
    menuTypeId: selectMenuTypeId(state),
  };

  fetchHelper('/api/clear-order', 'POST', body, success);
};

export const viewTerms = () => dispatch => {
  const confirm = () => {
    dispatch({
      type: TERMS_AND_CONDITIONS_ALERT,
    });
  };
  dispatch(openTermsAndConditionsAlert(confirm));
};

export const confirmLocation = () => (dispatch, getState) => {
  const state = getState();

  if (shouldConfirmLocation(state) && !isLocationConfirmed(state)) {
    dispatch(openConfirmLocationAlert());
  }
};

export const fetchProcessingStatus = callback => dispatch => {
  const success = res => {
    if (res?.redirectUrl) return browserHistory.push(res.redirectUrl);
    if (res?.newOrderNumber) dispatch(updateCompletedOrderNumber(res.newOrderNumber));
    if (res?.orderState) dispatch(updateCompletedOrderState(res.orderState));
    if (res?.completedOrder) dispatch(setCompletedOrder(res.completedOrder));
    return callback({ success: true, ...res });
  };

  const fail = res => {
    if (res?.redirectUrl) return browserHistory.push(res.redirectUrl);
    callback({ success: false, ...res });
  };

  fetchHelper(`/api/processing-status`, 'GET', null, success, fail);
};

export const setCompletedOrder = completedOrder => dispatch => {
  dispatch({
    type: CLEAR_ORDER,
  });
  dispatch({
    type: SET_COMPLETED_ORDER,
    completedOrder,
  });
};

export const updateCompletedOrderNumber = newOrderNumber => ({
  type: UPDATE_COMPLETED_ORDER_NUMBER,
  orderNumber: newOrderNumber,
});

export const updateCompletedOrderState = orderState => dispatch => {
  if ([ORDER_STATE_FAILED, ORDER_STATE_REJECTED].includes(orderState)) {
    dispatch({
      type: CLEAR_ORDER,
    });
  }
  dispatch({
    type: UPDATE_COMPLETED_ORDER_STATE,
    orderState,
  });
};

export const getOrderingDevices = success => dispatch => {
  dispatch({ type: GET_ORDERING_DEVICES });

  fetchHelper('/api/ordering-devices', 'GET', null, success);
};

const setOrderingDevice =
  (id, registeredAt = null, success, fail) =>
  dispatch => {
    dispatch({ type: SET_ORDERING_DEVICE, id });

    fetchHelper('/api/set-ordering-device', 'PUT', { id, registeredAt }, success, fail);
  };

export const registerOrderingDevice = (id, success, fail) => dispatch => {
  dispatch({ type: REGISTER_ORDERING_DEVICE, id });

  dispatch(setOrderingDevice(id, new Date(), success, fail));
};

export const unregisterOrderingDevice = (id, success, fail) => dispatch => {
  dispatch({ type: UNREGISTER_ORDERING_DEVICE, id });

  dispatch(setOrderingDevice(id, null, success, fail));
};
