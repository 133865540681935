import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import { Motion, spring } from 'react-motion';

import { usePrevious } from 'hooks';
import { closeAlert, clearAlerts } from 'actions/UI';
import { selectAlertStack } from 'selectors/ui';
import {
  DONATIONS_ALERT,
  CLEAR_NOTES,
  MIXED_MENU,
  FEEDBACK_THANKS,
  LOGOUT_CHECK,
  UNAVAILABLE_ITEMS_ALERT,
  LANGUAGE_ALERT,
  VENUES_LIST_ERROR_ALERT,
  DELETE_ADDRESS_ALERT,
  DELETE_PAYMENT_SOURCE_ALERT,
  DELETE_PAYMENT_SOURCE_ERROR_ALERT,
  MENU_TYPE_PROMPT,
  REMOVE_PRODUCT,
  TERMS_AND_CONDITIONS_ALERT,
  LOGIN_REGISTER_ALERT,
  LOGGED_OUT_ALERT,
  CONFIRM_LOCATION_ALERT,
  INACTIVE_ALERT,
  KIOSK_ALERT,
  IFRAME_ALERT,
  CLEAR_ORDER_ALERT,
  EXPORT_SINGLE_ORDER_ALERT,
  PRODUCT_OPTION_INFORMATION,
  PRODUCT_CUSTOMISATION,
  PRODUCT_OPTINS,
  ALLERGEN_INFORMATION,
  NUTRITIONAL_INFORMATION,
  ALLERGEN_FILTER,
  ORDER_INFORMATION,
  ORDER_REVIEW,
  ADD_ADDRESS_ALERT,
  RADIUS_ALERT,
  DELIVERY_PARTNERS_ALERT,
  EXPORT_ORDERS_ALERT,
  CARD_CONFIRMED_ALERT,
  SELECT_LANGUAGE_ALERT,
  LOYALTY_MODAL,
} from 'appConstants';
import CrossIcon from 'components/Icons/CrossIcon';

import DonationsAlert from '../alerts/DonationsAlert';
import OrderInformation from '../alerts/OrderInformation';
import AllergenFilter from '../alerts/AllergenFilter';
import AllergenInformation from '../alerts/AllergenInformation';
import ProductCustomisation from '../alerts/ProductCustomisation';
import ProductOptins from '../alerts/ProductOptins';
import NutritionalInformation from '../alerts/NutritionalInformation';
import ProductOptionInformation from '../alerts/ProductOptionInformation';
import OrderAlert from '../alerts/OrderAlert';
import ClearNotesAlert from '../alerts/clearNotes';
import LogoutCheckAlert from '../alerts/logoutCheck';
import MenuTypePrompt from '../alerts/MenuTypePrompt';
import RemoveProductAlert from '../alerts/RemoveProduct';
import MixedMenuTypeAlert from '../alerts/mixedMenuTypes';
import UnavailableItemsAlert from '../alerts/UnavailableItemsAlert';
import FeedbackThanksAlert from '../alerts/FeedbackThanksAlert';
import TermsAndConditionsAlert from '../alerts/TermsAndConditionsAlert';
import LoginRegisterAlert from '../alerts/LoginRegisterAlert';
import LoggedOutAlert from '../alerts/LoggedOutAlert';
import ConfirmLocationAlert from '../alerts/ConfirmLocationAlert';
import LanguageAlert from '../alerts/LanguageAlert';
import LanguageSelectAlert from '../alerts/LanguageSelectAlert';
import InactiveAlert from '../alerts/InactiveAlert';
import KioskAlert from '../alerts/KioskAlert';
import ConfirmClearOrder from '../alerts/confirmClearOrder';
import VenuesListErrorAlert from '../alerts/VenuesListErrorAlert';
import ExportSingleOrderAlert from '../alerts/ExportSingleOrderAlert';
import AddAddressAlert from '../alerts/AddAddressAlert';
import DeleteAddressAlert from '../alerts/DeleteAddressAlert';
import DeletePaymentSourceAlert from '../alerts/DeletePaymentSourceAlert';
import DeletePaymentSourceErrorAlert from '../alerts/DeletePaymentSourceErrorAlert';
import RadiusAlert from '../alerts/RadiusAlert';
import DeliveryPartnersAlert from '../alerts/DeliveryPartnersAlert';
import IframeAlert from '../alerts/IframeAlert';
import ExportOrdersAlert from '../alerts/ExportOrdersAlert';
import CardConfirmedAlert from '../alerts/CardConfirmedAlert';

import { ModalBackdrop, Alertbox, Container, CloseBtn } from '../styles';
import LoyaltyModal from '../alerts/LoyaltyModal';

const ALERT_COMPONENTS = {
  /* standard alerts */
  [CLEAR_NOTES]: ClearNotesAlert,
  [MIXED_MENU]: MixedMenuTypeAlert,
  [FEEDBACK_THANKS]: FeedbackThanksAlert,
  [LOGOUT_CHECK]: LogoutCheckAlert,
  [UNAVAILABLE_ITEMS_ALERT]: UnavailableItemsAlert,
  [LANGUAGE_ALERT]: LanguageAlert,
  [VENUES_LIST_ERROR_ALERT]: VenuesListErrorAlert,
  [DELETE_ADDRESS_ALERT]: DeleteAddressAlert,
  [DELETE_PAYMENT_SOURCE_ALERT]: DeletePaymentSourceAlert,
  [DELETE_PAYMENT_SOURCE_ERROR_ALERT]: DeletePaymentSourceErrorAlert,
  [CARD_CONFIRMED_ALERT]: CardConfirmedAlert,

  /* component alerts */
  [DONATIONS_ALERT]: DonationsAlert,
  [MENU_TYPE_PROMPT]: MenuTypePrompt,
  [REMOVE_PRODUCT]: RemoveProductAlert,
  [TERMS_AND_CONDITIONS_ALERT]: TermsAndConditionsAlert,
  [LOGIN_REGISTER_ALERT]: LoginRegisterAlert,
  [LOGGED_OUT_ALERT]: LoggedOutAlert,
  [CONFIRM_LOCATION_ALERT]: ConfirmLocationAlert,
  [INACTIVE_ALERT]: InactiveAlert,
  [KIOSK_ALERT]: KioskAlert,
  [IFRAME_ALERT]: IframeAlert,
  [CLEAR_ORDER_ALERT]: ConfirmClearOrder,
  [EXPORT_SINGLE_ORDER_ALERT]: ExportSingleOrderAlert,
  [SELECT_LANGUAGE_ALERT]: LanguageSelectAlert,
  [PRODUCT_OPTION_INFORMATION]: ProductOptionInformation,
  [PRODUCT_CUSTOMISATION]: ProductCustomisation,
  [PRODUCT_OPTINS]: ProductOptins,
  [ALLERGEN_INFORMATION]: AllergenInformation,
  [NUTRITIONAL_INFORMATION]: NutritionalInformation,
  [ALLERGEN_FILTER]: AllergenFilter,
  [ORDER_INFORMATION]: OrderInformation,
  [ORDER_REVIEW]: OrderAlert,
  [ADD_ADDRESS_ALERT]: AddAddressAlert,
  [RADIUS_ALERT]: RadiusAlert,
  [DELIVERY_PARTNERS_ALERT]: DeliveryPartnersAlert,
  [EXPORT_ORDERS_ALERT]: ExportOrdersAlert,
  [LOYALTY_MODAL]: LoyaltyModal,
};

const propTypes = {
  alertStack: PropTypes.instanceOf(List),
  alertStackSize: PropTypes.number,
  alertStackSizeIndex: PropTypes.number,
  clearAlerts: PropTypes.func,
  closeAlert: PropTypes.func,
  location: PropTypes.object,
};

const AlertManager = ({
  alertStack,
  alertStackSize,
  alertStackSizeIndex,
  clearAlerts,
  closeAlert,
  location,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentAlertIndex, setCurrentAlertIndex] = useState(null);
  const [prevLocationPathname] = usePrevious(location?.pathname);

  useEffect(() => {
    setIsOpen(Boolean(alertStackSize));
    setCurrentAlertIndex(alertStackSize ? alertStackSizeIndex : null);

    if (prevLocationPathname && prevLocationPathname !== location?.pathname && Boolean(alertStackSize)) {
      clearAlerts();
    }
  }, [alertStackSize, alertStackSizeIndex, prevLocationPathname, clearAlerts, location?.pathname]);

  const close = method => {
    if (typeof method === 'function') method();

    closeAlert(currentAlertIndex);
  };

  // If it is Server return null
  if (typeof window === 'undefined') return null;

  return (
    <Motion style={{ currentOpacity: spring(isOpen ? 1 : 0, { stiffness: 140, damping: 20 }) }}>
      {({ currentOpacity }) => (
        <ModalBackdrop style={{ opacity: currentOpacity, display: alertStackSize ? 'block' : 'none' }}>
          {alertStack.map((alert, index) => {
            const Alert = ALERT_COMPONENTS[alert.get('component')];
            const alertProps = alert.get('props', new Map());
            const fullScreen = alert.getIn(['props', 'fullScreen'], false);
            const hideCloseBtn = alert.getIn(['props', 'hideCloseBtn'], false);
            const transparent = alert.getIn(['props', 'transparent']);
            const slim = alert.getIn(['props', 'slim']);

            return (
              <Alertbox
                key={index} // eslint-disable-line react/no-array-index-key
                hide={index !== currentAlertIndex}
                fullScreen={fullScreen}
                slim={slim}
                transparent={transparent}
              >
                {!hideCloseBtn && !fullScreen && (
                  <CloseBtn onClick={close}>
                    <CrossIcon width="16px" height="16px" />
                  </CloseBtn>
                )}

                <Container fullScreen={fullScreen}>
                  {Alert && <Alert closeAlert={close} {...alertProps} />}
                </Container>
              </Alertbox>
            );
          })}
        </ModalBackdrop>
      )}
    </Motion>
  );
};

AlertManager.propTypes = propTypes;

export default connect(
  state => ({
    alertStack: selectAlertStack(state),
    alertStackSize: selectAlertStack(state)?.size,
    alertStackSizeIndex: selectAlertStack(state)?.size >= 1 ? selectAlertStack(state)?.size - 1 : null,
  }),
  { closeAlert, clearAlerts }
)(withRouter(AlertManager));
