import React from 'react';

import OrderReview from 'components/OrderReview';
import OrderCrossSells from 'components/OrderReview/OrderCrossSells';

import { OrderContainer } from './styles';

const Order = () => (
  <>
    <OrderContainer>
      <OrderReview />
    </OrderContainer>
    <OrderCrossSells />
  </>
);

export default Order;
