import styled, { css } from 'styled-components';
import LoadingSpinner from 'components/Loading/LoadingSpinner';
import { H3 } from 'components/Typography';
import { StyledAllergenIcon } from 'components/SuitableFor/styles';

export const Container = styled.div`
  background: no-repeat center;
  background-size: cover;
  overflow: hidden;
  min-height: 200px;
  padding-top: 10px;
  position: relative;
  text-align: center;
  background-color: ${({ theme }) => theme.color.inputBackground};
  color: ${({ theme }) => theme.color.text};
  justify-content: center;
  display: flex;
  height: 100%;

  ${props =>
    props.hasImage &&
    css`
      color: #fff;

      ${StyledAllergenIcon} {
        background: #fff;
        opacity: 0.9;
        border-radius: 50%;
        padding: 2px;
      }
    `}

  ${props =>
    !props.hasImage &&
    css`
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    `}

  ${props =>
    props.loading &&
    css`
      opacity: 0.8;
    `}

  @media (min-width: 768px) {
    min-height: 235px;
  }
`;

export const DisabledMessage = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgb(255 40 40 / 70%);
  color: #fff;
`;

export const ProductInfo = styled.div`
  padding: 0 8px 15px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  flex-direction: row;
  flex: 1 0 auto;

  ${props =>
    props.hasImage &&
    css`
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 15px 8px;
    `}

  html[dir='rtl'] & {
    text-align: right;
  }
`;

export const ProductName = styled(H3)`
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0.4em;
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const DescriptionContainer = styled.p`
  font-size: 1em;
  line-height: 1.2em;
`;

export const StyledPrice = styled.p`
  line-height: 1;
`;

export const Spinner = styled(LoadingSpinner)`
  margin: 0 auto;
  position: absolute;
  top: 25px;
  left: calc(50% - 30px);
`;

export const StyledTimerIcon = styled.div`
  position: absolute;
  right: 10px;
  top: calc(50% - 20px);

  html[dir='rtl'] & {
    right: auto;
    left: 10px;
  }

  svg {
    filter: drop-shadow(0 0 1px #000);
  }
`;
