import React from 'react';
import { connect } from 'react-redux';
import dompurify from 'dompurify';
import { FormattedMessage, defineMessages } from 'react-intl';

import Checkbox from 'components/Checkbox';
import { selectMessageByLocale } from 'selectors/root';

import { StyledCheckbox } from './styles';

const messages = defineMessages({
  allergenCheckbox: {
    defaultMessage: 'I understand and can confirm the allergens within my order.',
  },
});

type Props = {
  value?: string | boolean;
  onChange?: (checked: boolean) => void;
  onBlur?: (checked: boolean) => void;
  allergenCheckboxMessageByLocale: string | boolean;
};

const AllergenCheckbox = ({ value, onChange, onBlur, allergenCheckboxMessageByLocale }: Props) => (
  <StyledCheckbox>
    <Checkbox
      id="allergenCheckbox"
      checked={value}
      onChange={event => {
        onChange(event.target.checked);
        onBlur(event.target.checked);
      }}
    >
      {allergenCheckboxMessageByLocale ? (
        <span
          className="secondary-font"
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(allergenCheckboxMessageByLocale, { ALLOWED_TAGS: ['b', 'br'] }),
          }}
        />
      ) : (
        <FormattedMessage {...messages.allergenCheckbox} tagName="span" />
      )}
    </Checkbox>
  </StyledCheckbox>
);

export default connect(state => ({
  allergenCheckboxMessageByLocale: selectMessageByLocale(state, 'allergen_checkout_checkbox_message'),
}))(AllergenCheckbox);
