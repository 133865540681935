import styled, { css } from 'styled-components';

import { H1 } from 'components/Typography';
import { fontSizeMedium } from 'assets/styles/fonts';
import PrimaryButton from 'components/PrimaryButton';

export const HomePagePrimaryButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.buttons.buttonHomepageBg};
  color: ${({ theme }) => theme.buttons.buttonHomepageColor};
  border-color: ${({ theme }) => theme.buttons.buttonHomepageBorder};
`;

interface LabelProps {
  asPlaceholder?: boolean;
  required?: boolean;
  haserror?: boolean;
}

export const Label = styled.label<LabelProps>`
  color: ${({ theme }) => theme.color.text};
  font-weight: normal;
  padding-bottom: 20px;

  ${props =>
    props.required &&
    css`
      &::after {
        color: lighten(${({ theme }) => theme.color.danger}, 12%);
        content: '*';
        display: inline-block;
        font-size: inherit;
        padding-left: 3px;
      }
    `}

  ${props =>
    props.haserror &&
    css`
      color: ${({ theme }) => theme.color.danger};
    `}
`;

export const StyledDropdown = css`
  border: 1px solid ${({ theme }) => theme.color.borderColor};
  border-top: none;
  background: ${({ theme }) => theme.color.primaryBackground};
  color: ${({ theme }) => theme.color.text};
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
`;

interface StyledInputProps {
  haserror?: boolean;
  disabled?: boolean;
}

export const StyledInput = css<StyledInputProps>`
  border: 1px solid ${({ theme }) => theme.color.inputBorder};
  color: ${({ theme }) => theme.color.primaryText};
  background: ${({ theme }) => theme.color.inputBackground};
  display: block;
  font-size: ${({ theme }) => theme.fonts.inputTextFontSize};
  padding: 18px 9px 6px;
  position: relative;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.two};

  ${props =>
    props.haserror &&
    css`
      border-color: ${({ theme }) => theme.color.danger};
    `}

  &:-webkit-autofill {
    color: ${({ theme }) => theme.color.text};
  }

  html[dir='rtl'] & {
    text-align: right;
  }

  ${props =>
    props.disabled &&
    css`
      ${DisabledInput};
    `}
`;

export const DisabledInput = css`
  cursor: not-allowed;
  opacity: 0.4;
  background: ${({ theme }) => theme.color.disabledInput};
  color: ${({ theme }) => theme.color.disabledInputText};
`;

export const HelpMessage = styled.span<{ $error?: boolean }>`
  color: ${({ theme }) => theme.color.lightGrey};
  color: ${props => props.$error && 'red'};

  display: block;
  padding: 0 5px;
  font-style: italic;
  ${fontSizeMedium}
`;

export const SuccessMessage = styled.p`
  color: ${({ theme }) => theme.color.success};
  font-size: 14px;
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.color.danger};
  font-size: 14px;
`;

export const Container = styled.div`
  margin: 5px 0 10px;
  position: relative;
  width: 100%;
  display: block;
`;

export const AlertTitle = styled(H1)`
  line-height: normal;
  padding: 10px;
  color: ${({ theme }) => theme.color.primaryText};
`;

export const AlertSubTitle = styled.span`
  padding: 10px;
`;

export const AlertContent = styled.div<{ contrast: boolean }>`
  position: relative;
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 10px;
  color: ${({ theme }) => theme.color.text};

  @media (min-width: 767px) {
    padding: 20px;
  }
`;

export const AlertFooter = styled.div`
  flex: none;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: flex-end;
`;

export const AlertButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const AlertHeader = styled.div`
  margin: 10px 10px 0;

  @media (min-width: 767px) {
    margin: 20px 20px 0;
  }
`;

export const AlertMessage = styled.div`
  font-size: 16px;
  color: #999;
  padding: 10px 0;

  @media (min-width: 768px) {
    max-width: 450px;
    margin: 0 auto;
    padding: 0 0 10px;
  }
`;
