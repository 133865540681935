import React from 'react';
import { useSelector } from 'react-redux';
import { getLocale } from 'selectors/user';

interface ProductDescriptionProps {
  product: {
    description?: string;
    short_description?: string;
    translations: {
      [locale: string]: {
        short_description?: string;
        description?: string;
      };
    };
  };
}

const ProductDescription: React.FC<ProductDescriptionProps> = ({ product }) => {
  const currentLocale: string = useSelector(getLocale);

  return (
    <>
      {product?.translations?.[currentLocale]?.short_description ??
        product?.short_description ??
        product?.translations?.[currentLocale]?.description ??
        product?.description}
    </>
  );
};

export default ProductDescription;
