import { browserHistory } from 'react-router';
import { expireSession } from 'actions/order';
import { selectItemBackButton } from 'selectors/browse';
import { showExitButton } from 'selectors/features';

import {
  DONATIONS_ALERT,
  PRIVACY_SETTINGS_NOTIFICATION,
  NUTRITIONAL_INFORMATION,
  ALLERGEN_INFORMATION,
  PRODUCT_OPTION_INFORMATION,
  ORDER_INFORMATION,
  CLEAR_NOTES,
  MIXED_MENU,
  REMOVE_PRODUCT,
  FEEDBACK_THANKS,
  LOGOUT_CHECK,
  UNAVAILABLE_ITEMS_ALERT,
  TERMS_AND_CONDITIONS_ALERT,
  CONFIRM_LOCATION_ALERT,
  INACTIVE_ALERT,
  CLEAR_ORDER_ALERT,
  LOGGED_OUT_ALERT,
  LOGIN_REGISTER_ALERT,
  KIOSK_ALERT,
  IFRAME_ALERT,
  LANGUAGE_ALERT,
  ALLERGEN_FILTER,
  VENUES_LIST_ERROR_ALERT,
  EXPORT_SINGLE_ORDER_ALERT,
  ADD_ADDRESS_ALERT,
  DELETE_ADDRESS_ALERT,
  DELETE_PAYMENT_SOURCE_ALERT,
  DELETE_PAYMENT_SOURCE_ERROR_ALERT,
  RADIUS_ALERT,
  DELIVERY_PARTNERS_ALERT,
  EXPORT_ORDERS_ALERT,
  CARD_CONFIRMED_ALERT,
  SELECT_LANGUAGE_ALERT,
} from 'appConstants';
import {
  OPEN_ALERT,
  SET_IS_SETTINGS_PAGE,
  OPEN_NOTIFICATION,
  POP_INFO_AND_CUSTOMISATION,
  UPDATE_LOGIN_REGISTER_ALERT,
  CLOSE_ALERT,
  CLEAR_ALERTS,
  CLOSE_NOTIFICATION,
  SET_LAST_MENU_PAGE,
  CLEAR_LAST_MENU_PAGE,
} from './constants';

export const closeAlert = alertIndex => ({ type: CLOSE_ALERT, alertIndex });

export const clearAlerts = () => ({ type: CLEAR_ALERTS });

export const setLastMenuPage = menuPage => ({
  type: SET_LAST_MENU_PAGE,
  menuPage,
});

export const clearLastMenuPage = () => ({ type: CLEAR_LAST_MENU_PAGE });

export const openNutritionalInfo = product => ({
  type: OPEN_ALERT,
  alertType: NUTRITIONAL_INFORMATION,
  alertProps: { product, fullScreen: true },
});

export const openAllergenInfo = product => ({
  type: OPEN_ALERT,
  alertType: ALLERGEN_INFORMATION,
  alertProps: { product, fullScreen: true },
});

export const openProductInfo = item => (dispatch, getState) => {
  if (window.innerWidth >= 768) {
    return dispatch({
      type: OPEN_ALERT,
      alertType: PRODUCT_OPTION_INFORMATION,
      alertProps: { item },
    });
  }

  const state = getState();
  const url = selectItemBackButton(state, item);
  browserHistory.push({
    pathname: `${url}/product/${item.get('productId')}`,
  });
};

export const openOrderInfo = orderReference => ({
  type: OPEN_ALERT,
  alertType: ORDER_INFORMATION,
  alertProps: { orderReference },
});

export const openClearNotesAlert = confirmMethod => ({
  type: OPEN_ALERT,
  alertType: CLEAR_NOTES,
  alertProps: { confirmMethod },
});

export const openMixedTypeAlert = confirmMethod => ({
  type: OPEN_ALERT,
  alertType: MIXED_MENU,
  alertProps: { confirmMethod },
});

export const openRemoveProductAlert = (confirmRemove, index, item) => ({
  type: OPEN_ALERT,
  alertType: REMOVE_PRODUCT,
  alertProps: { confirmRemove, index, item },
});

export const openFeedbackThanksAlert = confirmMethod => ({
  type: OPEN_ALERT,
  alertType: FEEDBACK_THANKS,
  alertProps: { confirmMethod },
});

export const setIsSettingsPage = isSettingsPage => ({
  type: SET_IS_SETTINGS_PAGE,
  payload: isSettingsPage,
});

export const openConfirmLogoutAlert = confirmMethod => ({
  type: OPEN_ALERT,
  alertType: LOGOUT_CHECK,
  alertProps: { confirmMethod },
});

export const openUnavailableItemsAlert = (confirmMethod, items) => ({
  type: OPEN_ALERT,
  alertType: UNAVAILABLE_ITEMS_ALERT,
  alertProps: { confirmMethod, items },
});

export const openTermsAndConditionsAlert = confirmMethod => ({
  type: OPEN_ALERT,
  alertType: TERMS_AND_CONDITIONS_ALERT,
  alertProps: { confirmMethod },
});

export const openConfirmLocationAlert = confirmMethod => ({
  type: OPEN_ALERT,
  alertType: CONFIRM_LOCATION_ALERT,
  alertProps: { confirmMethod },
});

export const openInactiveAlert = confirmMethod => ({
  type: OPEN_ALERT,
  alertType: INACTIVE_ALERT,
  alertProps: { confirmMethod },
});

export const openClearOrderAlert = () => (dispatch, getState) => {
  dispatch({
    type: OPEN_ALERT,
    alertType: CLEAR_ORDER_ALERT,
    alertProps: {
      confirmMethod: () => {
        dispatch(expireSession());
        if (showExitButton(getState())) window.location = '/exitkiosk';
      },
    },
  });
};

export const openPrivacySettingsNotification = confirmMethod => ({
  type: OPEN_NOTIFICATION,
  notificationType: PRIVACY_SETTINGS_NOTIFICATION,
  notificationProps: { confirmMethod },
});

export const closeNotification = notificationIndex => ({ type: CLOSE_NOTIFICATION, notificationIndex });

export const popSheetInfoAndCustomisation = () => ({
  type: POP_INFO_AND_CUSTOMISATION,
});

export const openLoggedOutAlert = confirmMethod => ({
  type: OPEN_ALERT,
  alertType: LOGGED_OUT_ALERT,
  alertProps: { confirmMethod },
});

export const openLoginRegisterAlert = (section = 'LOGIN_FORM', props = {}) => ({
  type: OPEN_ALERT,
  alertType: LOGIN_REGISTER_ALERT,
  alertProps: { section, ...props },
});

export const changeLoginRegisterAlert = section => ({
  type: UPDATE_LOGIN_REGISTER_ALERT,
  section,
});

export const openKioskModeAlert = () => ({
  type: OPEN_ALERT,
  alertType: KIOSK_ALERT,
  alertProps: { notScrollable: true },
});

export const openIframeAlert = iframeUrl => ({
  type: OPEN_ALERT,
  alertType: IFRAME_ALERT,
  alertProps: { iframeUrl, hideCloseBtn: true },
});

export const openLanguageAlert = () => ({
  type: OPEN_ALERT,
  alertType: LANGUAGE_ALERT,
});

export const openSelectLanguageAlert = () => ({
  type: OPEN_ALERT,
  alertType: SELECT_LANGUAGE_ALERT,
  alertProps: {
    hideCloseBtn: true,
    slim: true,
    transparent: true,
  },
});

export const openAllergenFilter = () => ({
  type: OPEN_ALERT,
  alertType: ALLERGEN_FILTER,
  alertProps: {
    fullScreen: true,
  },
});

export const openVenuesListErrorAlert = () => ({
  type: OPEN_ALERT,
  alertType: VENUES_LIST_ERROR_ALERT,
});

export const openExportSingleOrderAlert = orderReference => ({
  type: OPEN_ALERT,
  alertType: EXPORT_SINGLE_ORDER_ALERT,
  alertProps: { orderReference },
});

export const openAddAddressAlert = confirmMethod => ({
  type: OPEN_ALERT,
  alertType: ADD_ADDRESS_ALERT,
  alertProps: { confirmMethod },
});

export const openDeleteAddressAlert = (addressLabel, addressId, confirmMethod) => ({
  type: OPEN_ALERT,
  alertType: DELETE_ADDRESS_ALERT,
  alertProps: { addressLabel, addressId, confirmMethod },
});

export const openDeletePaymentSourceAlert = (name, reference, confirmMethod) => ({
  type: OPEN_ALERT,
  alertType: DELETE_PAYMENT_SOURCE_ALERT,
  alertProps: { name, reference, confirmMethod },
});

export const openDeletePaymentSourceErrorAlert = name => ({
  type: OPEN_ALERT,
  alertType: DELETE_PAYMENT_SOURCE_ERROR_ALERT,
  alertProps: { name },
});

export const openRadiusAlert = confirmMethod => ({
  type: OPEN_ALERT,
  alertType: RADIUS_ALERT,
  alertProps: { confirmMethod },
});

export const openDonationsAlert = donation => ({
  type: OPEN_ALERT,
  alertType: DONATIONS_ALERT,
  alertProps: { donation },
});

export const openDeliveryPartnersAlert = venueId => ({
  type: OPEN_ALERT,
  alertType: DELIVERY_PARTNERS_ALERT,
  alertProps: { venueId },
});

export const openExportOrdersAlert = (dateFrom, dateTo) => ({
  type: OPEN_ALERT,
  alertType: EXPORT_ORDERS_ALERT,
  alertProps: { dateFrom, dateTo },
});

export const openCardConfirmedAlert = () => ({
  type: OPEN_ALERT,
  alertType: CARD_CONFIRMED_ALERT,
});
