import React from 'react';
import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import LoginIcon from 'components/Icons/LoginIcon';
import TermsIcon from 'components/Icons/TermsIcon';
import MenusIcon from 'components/Icons/MenusIcon';
import LogoutIcon from 'components/Icons/LogoutIcon';
import WaiterIcon from 'components/Icons/WaiterIcon';
import OrdersIcon from 'components/Icons/OrdersIcon';
import LanguageIcon from 'components/Icons/LanguageIcon';
import { isLoggedIn } from 'selectors/user';
import { openLoginRegisterAlert } from 'actions/UI';
import { confirmLogout as logoutAction } from 'actions/user';
import { selectMenus, menusOnService } from 'selectors/browse';
import { shouldShowSplashCallWaiter, kioskModeEnabled, viewOrderInsteadOfFooter } from 'selectors/features';
import {
  getLocales,
  selectServices,
  getAllowedCallTheWaiterServices,
  selectIsOrganisation,
  selectFooterLinks,
  isOfflineVenueMode,
  isServiceBrowseOnly,
  isDineInOrderingFlow,
} from 'selectors/root';
import { orderReview } from 'actions/order';
import { selectIsSettingsPage } from 'selectors/ui';
import { selectSidebarIsOpen } from 'selectors/sidebar';
import globalMessages from 'components/globalMessages';
import { isExternalLink } from 'utils/externalLinks';
import { FoodAndDrinkIcon } from 'components/Icons';
import { getIcon, trackEvent } from 'utils';
import addCssPrefixTo from 'utils/addCssPrefixTo';
import ActionButton from 'components/ActionButton';
import { FormattedMessage } from 'react-intl';
import { selectOrderItems, selectOrderTotal } from 'selectors/order';
import FormattedPrice from 'components/FormattedPrice';
import styled from 'styled-components';
import FooterItem from './FooterItem';
import messages from './messages';
import { StyledFooter, IconMenu, StyledCartButton, ViewOrderFooter } from './styles';
import TabOrderIcon from '../Icons/TabOrderIcon';

const propTypes = {
  isLoggedIn: PropTypes.bool,
  isHomepage: PropTypes.bool,
  shouldShowSplashCallWaiter: PropTypes.bool,
  isSettingsPage: PropTypes.bool,
  locationParams: PropTypes.object,
  services: PropTypes.instanceOf(Map),
  menus: PropTypes.instanceOf(Map),
  callTheWaiterServices: PropTypes.instanceOf(Map),
  openLoginRegisterAlert: PropTypes.func,
  logout: PropTypes.func,
  openOrder: PropTypes.func,
  localesLength: PropTypes.number,
  isOrganisation: PropTypes.bool,
  kioskMode: PropTypes.bool,
  footerLinks: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(List)]),
  sidebarOpen: PropTypes.bool,
  isOfflineVenue: PropTypes.bool,
  serviceBrowseOnly: PropTypes.bool,
  isDineInOrderFlow: PropTypes.bool,
};

const defaultProps = {
  locationParams: {},
  isSettingsPage: false,
  serviceBrowseOnly: false,
};

const FooterMenu = ({
  callTheWaiterServices,
  isLoggedIn,
  isSettingsPage,
  isHomepage,
  openLoginRegisterAlert,
  logout,
  localesLength,
  locationParams: { serviceId, menuId },
  services,
  menus,
  shouldShowSplashCallWaiter,
  openOrder,
  isOrganisation,
  kioskMode,
  footerLinks,
  sidebarOpen,
  isOfflineVenue,
  serviceBrowseOnly,
  isDineInOrderFlow,
}) => {
  const footerMenuClass = !isSettingsPage ? 'secondaryBackground primaryColor secondaryBorderColor' : '';

  let showCallAWaiter = false;
  if (shouldShowSplashCallWaiter && isHomepage && callTheWaiterServices.size >= 1) showCallAWaiter = true;
  if (serviceId && callTheWaiterServices.find(service => service.get('id').toString() === serviceId))
    showCallAWaiter = true;

  const onClickCart = () => {
    openOrder();
    trackEvent('initiate_checkout', {
      category: 'checkout',
      location: 'footer',
    });
  };

  const showViewOrderButton = useSelector(viewOrderInsteadOfFooter);
  const orderTotal = useSelector(selectOrderTotal);
  const itemCount = useSelector(selectOrderItems).reduce((acc, item) => {
    if (item.get('type') === 'product') {
      return acc + item.get('quantity');
    }
    return acc;
  }, 0);

  const OrderInfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border-top: 1px solid ${({ theme }) => theme.color.borderColor};
  `;

  if (showViewOrderButton) {
    if (!itemCount) return null;

    return (
      <ViewOrderFooter
        sidebarOpen={sidebarOpen}
        className={`${footerMenuClass} ${addCssPrefixTo('FOOTER_MENU')}`}
      >
        <OrderInfoContainer className="primaryBackground">
          <div>
            <FormattedMessage values={{ itemCount }} {...globalMessages.xItems} />
          </div>
          <div>
            <FormattedPrice value={orderTotal} />
          </div>
        </OrderInfoContainer>
        <ActionButton
          primaryButton={true}
          to="/review"
          label={<FormattedMessage {...globalMessages.viewOrder} />}
        />
      </ViewOrderFooter>
    );
  }

  return (
    <StyledFooter
      sidebarOpen={sidebarOpen}
      settingsPage={isSettingsPage}
      className={`${footerMenuClass} ${addCssPrefixTo('FOOTER_MENU')}`}
    >
      <IconMenu>
        {isHomepage &&
          localesLength > 1 &&
          !kioskMode /* kiosk mode uses the new language selection component */ && (
            <FooterItem Icon={LanguageIcon} path="/language" message={messages.language} />
          )}
        {isHomepage && localesLength <= 1 && (
          <FooterItem Icon={TermsIcon} path="/terms_conditions" message={messages.termsPage} />
        )}
        {!isLoggedIn && !kioskMode && (
          <FooterItem
            Icon={LoginIcon}
            path=""
            onClick={() => openLoginRegisterAlert()}
            message={messages.login}
          />
        )}
        {isLoggedIn && <FooterItem Icon={LogoutIcon} path="" onClick={logout} message={messages.logout} />}
        {!!serviceId &&
          !!menuId &&
          services.size >= 1 &&
          Object.keys(menusOnService(menus, serviceId)).length > 1 && (
            <FooterItem Icon={MenusIcon} path={`/service/${serviceId}`} message={messages.allMenus} />
          )}
        {showCallAWaiter && (
          <FooterItem Icon={WaiterIcon} path="/call-a-waiter" message={messages.callWaiter} />
        )}
        {isLoggedIn && <FooterItem Icon={OrdersIcon} path="/user/orders" message={messages.ordersPage} />}
        {!isOfflineVenue && !isHomepage && !isOrganisation && !serviceBrowseOnly && (
          <>
            <FooterItem
              className={addCssPrefixTo('FOOTER_CART')}
              onClick={onClickCart}
              Icon={<StyledCartButton settingsPage={isSettingsPage} />}
              path=""
              message={isDineInOrderFlow ? globalMessages.cart : globalMessages.checkout}
            />
            {isDineInOrderFlow && serviceId && (
              <FooterItem
                Icon={TabOrderIcon}
                path={`/service/${serviceId}/order`}
                message={globalMessages.viewTab}
              />
            )}
          </>
        )}
        {footerLinks.size >= 1 &&
          footerLinks
            .filter(link => isExternalLink(link.get('url')) || !!serviceId)
            .map((link, index) => (
              <FooterItem
                // eslint-disable-next-line react/no-array-index-key
                key={`icon-${link.get('name')}-${index}`}
                path={link.get('url')}
                Icon={getIcon(link.get('icon'), { defaultIcon: <FoodAndDrinkIcon /> })}
                intlMessage={link.get('name')}
              />
            ))}
      </IconMenu>
    </StyledFooter>
  );
};

FooterMenu.propTypes = propTypes;
FooterMenu.defaultProps = defaultProps;

export default connect(
  (state, { locationParams }) => ({
    services: selectServices(state),
    menus: selectMenus(state),
    localesLength: getLocales(state)?.size,
    isSettingsPage: selectIsSettingsPage(state),
    isLoggedIn: isLoggedIn(state),
    callTheWaiterServices: getAllowedCallTheWaiterServices(state),
    shouldShowSplashCallWaiter: shouldShowSplashCallWaiter(state),
    isOrganisation: selectIsOrganisation(state),
    kioskMode: kioskModeEnabled(state),
    footerLinks: selectFooterLinks(state),
    sidebarOpen: selectSidebarIsOpen(state),
    isOfflineVenue: isOfflineVenueMode(state),
    serviceBrowseOnly: isServiceBrowseOnly(state, locationParams?.serviceId),
    isDineInOrderFlow: isDineInOrderingFlow(state),
  }),
  {
    logout: logoutAction,
    openOrder: orderReview,
    openLoginRegisterAlert,
  }
)(FooterMenu);
