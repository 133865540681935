import styled from 'styled-components';
import TelephoneInput from 'react-telephone-input';

import { Label, StyledInput } from 'assets/styles/sharedStyles';

export const StyledLabel = styled(Label)`
  left: 45px;
`;

export const StyledTelephone = styled(TelephoneInput)`
  input[type='tel'] {
    ${StyledInput}
    box-shadow: none;
    padding-left: 44px;
    height: auto;
    border-radius: unset;
  }

  &.react-tel-input {
    width: 100%;
    margin-bottom: 10px;

    .selected-flag {
      z-index: ${({ theme }) => theme.zIndex.four};
    }

    .flag-dropdown {
      border: none;
      margin: 5px;
    }

    html[dir='rtl'] & {
      > .form-control {
        padding-left: 6px;
        padding-right: 44px;
      }

      .selected-flag {
        padding: 0 8px 0 0;

        .flag .arrow {
          left: auto;
          right: 20px;
        }
      }
    }
  }

  &.react-tel-input .country-list {
    top: 41px;
    background: ${({ theme }) => theme.color.primaryBackground} !important;
    border-color: ${({ theme }) => theme.color.borderColor};
    color: ${({ theme }) => theme.color.text};
    z-index: ${({ theme }) => theme.zIndex.four} !important;

    .country.highlight {
      background: ${({ theme }) => theme.color.secondaryBackground};
      color: ${({ theme }) => theme.color.primaryText};
    }

    html[dir='rtl'] & {
      .country .flag {
        margin-right: 0;
        margin-left: 6px;
      }
    }
  }

  &.react-tel-input .selected-flag {
    height: 39px;
  }

  &.react-tel-input .flag-dropdown:hover .selected-flag {
    background-color: transparent;
  }
`;
