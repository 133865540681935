import styled, { css } from 'styled-components';
import { fontSizeMedium } from 'assets/styles/fonts';

import FilterAltIcon from 'components/Icons/FilterAltIcon';

export const StyledSearch = styled.div`
  color: #666;
  position: sticky;
  left: 0;
  right: 0;
  top: 48px;
  z-index: ${({ theme }) => theme.zIndex.nine};
  height: 63px;
  overflow: visible;
  padding-top: 10px;

  html[dir='rtl'] & {
    padding-left: unset;
    padding-right: 40px;
  }
  @media (min-width: 800px) {
    padding-left: 0;
    margin-left: unset;
    margin-right: unset;
  }
  @media (min-width: 800px) and (max-width: 820px) {
    & > .container {
      padding-left: 1.5%;
    }
  }
  & > .container {
    overflow: visible;
  }
`;

export const StyledSearchBar = styled.div`
  flex-grow: 1;
`;

export const FilterBtn = styled.div`
  color: ${({ theme }) => theme.color.text};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.color.inputBorder};
  padding: 6px;
  margin-left: 5px;
  ${fontSizeMedium};
  font-weight: 400;
  span {
    padding: 0 3px;
    font-size: 16px;
  }

  html[dir='rtl'] & {
    margin-left: 0;
    margin-right: 5px;
  }

  ${props =>
    props.active &&
    css`
      background: ${({ theme }) => theme.color.success};
      color: #fff;
    `}
`;

export const StyledFilterIcon = styled(FilterAltIcon)`
  padding: 0 3px;

  ${props =>
    props.active &&
    css`
      path {
        fill: #fff;
      }
    `}
`;
