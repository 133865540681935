import React from 'react';
import PropTypes from 'prop-types';

const FilterAltIcon = ({ className, width, height, fill }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 148 148"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="icon FilterAltIcon"
      d="M16.958 37C15.7314 37 14.555 37.4873 13.6876 38.3546C12.8203 39.222 12.333 40.3984 12.333 41.625C12.333 42.8516 12.8203 44.028 13.6876 44.8954C14.555 45.7627 15.7314 46.25 16.958 46.25H131.041C132.268 46.25 133.444 45.7627 134.312 44.8954C135.179 44.028 135.666 42.8516 135.666 41.625C135.666 40.3984 135.179 39.222 134.312 38.3546C133.444 37.4873 132.268 37 131.041 37H16.958ZM36.9997 72.4583C36.9997 71.2317 37.487 70.0553 38.3543 69.188C39.2217 68.3206 40.398 67.8333 41.6247 67.8333H106.375C107.601 67.8333 108.778 68.3206 109.645 69.188C110.512 70.0553 111 71.2317 111 72.4583C111 73.685 110.512 74.8613 109.645 75.7287C108.778 76.5961 107.601 77.0833 106.375 77.0833H41.6247C40.398 77.0833 39.2217 76.5961 38.3543 75.7287C37.487 74.8613 36.9997 73.685 36.9997 72.4583ZM61.6663 102.909C61.6663 101.683 62.1536 100.506 63.021 99.639C63.8883 98.7716 65.0647 98.2843 66.2913 98.2843H81.708C82.9346 98.2843 84.111 98.7716 84.9784 99.639C85.8457 100.506 86.333 101.683 86.333 102.909C86.333 104.136 85.8457 105.312 84.9784 106.18C84.111 107.047 82.9346 107.534 81.708 107.534H66.2913C65.0647 107.534 63.8883 107.047 63.021 106.18C62.1536 105.312 61.6663 104.136 61.6663 102.909Z"
      fill={fill}
    />
  </svg>
);

FilterAltIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

FilterAltIcon.defaultProps = {
  width: '24px',
  height: '24px',
  fill: 'currentColor',
};

export default FilterAltIcon;
