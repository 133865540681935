import React from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';

import { kioskModeEnabled } from 'selectors/features';
import { getIsAdmin } from 'selectors/root';
import kioskMessages from 'components/Alert/alerts/KioskAlert/intl';
import { setKioskMode } from 'actions/features';
import { openKioskModeAlert } from 'actions/UI';

import { ProfileBar, ProfileName, Container, Button, Label } from './styles';

const messages = defineMessages({
  welcomeBack: {
    defaultMessage: 'Welcome',
  },
  welcome: {
    defaultMessage: 'Welcome',
  },
  guest: {
    defaultMessage: 'Guest',
  },
  login: {
    defaultMessage: 'Login',
  },
  register: {
    defaultMessage: 'Register',
  },
  logout: {
    defaultMessage: 'Logout',
  },
});

type Props = {
  openLoginRegisterAlert?: (section?: string) => void;
  logout: () => void;
  isLoggedIn: boolean;
  user: Map<string, any>;
  toggleSidebarOpen?: () => void;
  kioskMode?: boolean;
  isAdmin?: boolean;
  openKioskAlert?: () => void;
};

const commonClasses = 'primaryBackground primaryColor secondaryBorderColor';

const Profile = ({
  isLoggedIn,
  user,
  logout,
  toggleSidebarOpen,
  openLoginRegisterAlert,
  kioskMode,
  isAdmin,
  openKioskAlert,
}: Props) => (
  <div>
    <ProfileBar className="primaryBackground primaryColor">
      <div>
        {isLoggedIn ? (
          <FormattedMessage {...messages.welcomeBack} />
        ) : (
          <FormattedMessage {...messages.welcome} />
        )}
      </div>
      <ProfileName>
        {isLoggedIn ? user.get('first_name') || user.get('email') : <FormattedMessage {...messages.guest} />}
      </ProfileName>
    </ProfileBar>

    {!kioskMode && (
      <Container>
        {isLoggedIn ? (
          <>
            <Button className={commonClasses} onClick={logout}>
              <Label>
                <FormattedMessage {...messages.logout} />
              </Label>
            </Button>

            {isAdmin && (
              <Button
                className={commonClasses}
                onClick={() => {
                  setKioskMode();
                  openKioskAlert();
                  toggleSidebarOpen();
                }}
              >
                <Label>
                  <FormattedMessage {...kioskMessages.enableKioskMode} />
                </Label>
              </Button>
            )}
          </>
        ) : (
          <>
            <Button
              className={commonClasses}
              onClick={() => {
                toggleSidebarOpen();
                openLoginRegisterAlert();
              }}
            >
              <Label>
                <FormattedMessage {...messages.login} />
              </Label>
            </Button>

            <Button
              className={commonClasses}
              onClick={() => {
                toggleSidebarOpen();
                openLoginRegisterAlert('REGISTER_FORM');
              }}
            >
              <Label>
                <FormattedMessage {...messages.register} />
              </Label>
            </Button>
          </>
        )}
      </Container>
    )}
  </div>
);

const mapStateToProps = state => ({
  kioskMode: kioskModeEnabled(state),
  isAdmin: getIsAdmin(state),
});

const mapDispatchToProps = dispatch => ({
  openKioskAlert: () => dispatch(openKioskModeAlert()),
  setKioskMode: enabled => dispatch(setKioskMode(enabled)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
