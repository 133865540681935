import styled, { css } from 'styled-components';
import { Link } from 'react-router';

const linkStyles = css`
  cursor: pointer;
  font-weight: 300;
  margin-bottom: 10px;
  padding: 7px 5px;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  font-size: 1em;
  transition: background-color 400ms linear;
  background-color: ${({ theme }) => theme.color.brandPrimary};
  color: ${({ theme }) => theme.color.brandSecondary};

  @media (min-width: 800px) {
    font-size: 1.125em;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  & > figure {
    display: flex;
    justify-content: center;

    & > img {
      width: 50%;
      height: auto;
    }
  }
`;
export const Container = styled.div`
  ${({ containerClass }) =>
    containerClass &&
    css`
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      & > a,
      & > button {
        margin: 0 5px 10px;
        width: calc(50% - 10px);
      }
    `}
`;

export const StyledLink = styled(Link)`
  ${linkStyles}
`;

export const StyledButton = styled.button.attrs(({ dataCy }) => (dataCy ? { 'data-cy': dataCy } : null))`
  ${linkStyles}
`;
