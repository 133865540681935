import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { promotionType } from 'selectors/orderItemTypes';

import messages from './messages';

const ItemName = ({ item }) => (
  <span>
    <FormattedMessage
      {...messages.lineItem}
      values={{
        formattedNumber: <FormattedNumber value={item.get('quantity')} />,
        separator: <span className="lowercase">x</span>,
        promotion: item.get('type') === promotionType && <FormattedMessage {...messages.promotion} />,
        name: item.get('name'),
      }}
    />
  </span>
);

ItemName.propTypes = {
  item: PropTypes.instanceOf(Map).isRequired,
};

export default ItemName;
