import 'isomorphic-fetch';
import debounce from 'lodash/debounce';
import ReactGA from 'react-ga4';

import {
  checkOrderViolations,
  failedOrderUpdate,
  replaceOrderItemsInState,
  updateOrderFromServer,
} from 'actions/order';
import { openUnavailableItemsAlert } from 'actions/UI';
import { fetchHelper, gtmDataLayerPush, reactPixel, trackEvent } from 'utils';
import {
  expiredOrderItems,
  getOrderMenuTypeId,
  selectOrder,
  selectOrderItems,
  selectOrderServiceId,
  validatedOrderItems,
} from 'selectors/order';
import { selectSessionId } from 'selectors/session';
import { getServiceId, isDineInOrderingFlow, selectFulfilmentMethodRadius } from 'selectors/root';
import { isLoggedIn, selectAddressesList } from 'selectors/user';

import * as orderActions from 'actions/order/constants';
import { SET_IS_SERVICE_ACTIVE } from 'actions/venue/constants';
import { SET_IS_MENU_ACTIVE, SET_SERVICE_ID } from 'actions/browse/constants';
import { ORDER_STATE_CONFIRMED } from 'appConstants';
import { selectMenuTypeId } from 'selectors/browse';
import { productType } from 'selectors/orderItemTypes';
import { addAddress, clearPredictionsAndCoordsFromStateAndSession } from 'actions/user';
import { fetchCrossSells } from 'actions/crossSells';
import { setDetail } from 'actions/storage';
import { setDineInPasscode } from 'actions/root';

let orderRequestId = 0;
let allowTracking = true;

const getCrossSells = (state, dispatch) => {
  const productsInCartSize = validatedOrderItems(state)?.size;
  const serviceId = selectOrderServiceId(state);
  const menuTypeId = getOrderMenuTypeId(state);

  if (productsInCartSize > 0 && serviceId && menuTypeId) {
    dispatch(fetchCrossSells(serviceId, menuTypeId));
  }
};

export default ({ dispatch, getState }) =>
  next =>
  action => {
    const orderActionsList = Object.values(orderActions);
    const state = getState();

    if (action.type === '@@CLIENT_INIT') {
      getCrossSells(state, dispatch);
    }

    if (orderActionsList.indexOf(action.type) > -1) {
      action.serviceId = getServiceId(state);
      action.menuTypeId = selectMenuTypeId(state);
    }

    if (action.type === orderActions.SET_COMPLETED_ORDER) {
      const state = getState();
      const completedOrder = action?.completedOrder;
      const fulfilment = completedOrder?.fulfilment;

      if (
        isLoggedIn(state) &&
        selectAddressesList(state)?.size === 0 &&
        fulfilment?.location_type === 'address'
      ) {
        const addressInfo = fulfilment?.location;
        const addressDetails = {
          addressLabel: addressInfo?.addressLabel || '',
          address1: addressInfo?.address1 || '',
          address2: addressInfo?.address2 || '',
          county: addressInfo?.county || '',
          town_city: addressInfo?.town_city || '',
          postcode: addressInfo?.postcode || '',
          addressPin: addressInfo?.building_access || '',
          addressNotes: addressInfo?.additional_instructions || '',
        };

        dispatch(addAddress(addressDetails));
      }

      if (completedOrder?.tab?.passcode && completedOrder?.service) {
        dispatch(setDetail(`passcode_${completedOrder.service}`, completedOrder.tab.passcode));
        dispatch(setDineInPasscode(completedOrder.service, completedOrder.tab.passcode));
      }
    }

    if (action.type === orderActions.SET_COMPLETED_ORDER) {
      const completedOrder = action?.completedOrder;
      const orderAnalyticsData = completedOrder?.GaData;
      const oldTransactionId = state.getIn(['global', 'completedOrder', 'number'], '0');
      const transactionId = completedOrder?.number || '';
      const locationType = completedOrder?.fulfilment?.location_type;
      const fulfilmentMethodRadius = selectFulfilmentMethodRadius(state);

      if (oldTransactionId !== transactionId) {
        allowTracking = true;
      }

      if (allowTracking && completedOrder?.state === ORDER_STATE_CONFIRMED && orderAnalyticsData) {
        allowTracking = false;

        const revenue = orderAnalyticsData.revenue || 0;
        const tax = orderAnalyticsData.tax || 0;
        const currency = orderAnalyticsData.currency || '';
        const discount = orderAnalyticsData.items.find(elem => elem.id === 'promotion')?.price || null;
        const coupon = completedOrder?.promotionCoupon?.code || '';

        if (ReactGA) {
          trackEvent('purchase', {
            category: 'checkout',
            transaction_id: transactionId,
            currency,
            value: revenue,
            tax,
            coupon,
            discount,
            items: orderAnalyticsData.items.map(item => ({
              item_id: item.id,
              item_name: item.name,
              price: item.price,
              quantity: item.quantity,
            })),
          });
        }

        gtmDataLayerPush('Purchase', { currency, transactionTotal: revenue, transactionId });
        reactPixel.track('Purchase', { currency, value: revenue, event_id: transactionId });
      }

      if (locationType === 'address' && fulfilmentMethodRadius) {
        dispatch(clearPredictionsAndCoordsFromStateAndSession());
      }
    }

    const checkForExpiredItems = order => {
      setTimeout(() => {
        // set timeout to ensure services/menus marked as inactive
        const state = getState();

        const expiredItems = expiredOrderItems(state, order.get('serviceId'), order.get('menuTypeId'));
        if (expiredItems.size) {
          const validItems = validatedOrderItems(state, order.get('serviceId'), order.get('menuTypeId'));
          dispatch(replaceOrderItemsInState(validItems));

          // const quantity = expiredItems.reduce((total, item) => {
          //   if (item.get('type') !== productType) return total;
          //   return total + item.get('quantity');
          // }, 0);
          dispatch(openUnavailableItemsAlert(() => {}, expiredItems));
        }
      }, 400);
    };

    if (action.type === SET_SERVICE_ID || action.type === SET_IS_MENU_ACTIVE) {
      const currentOrder = selectOrder(state);
      if (currentOrder) {
        checkForExpiredItems(currentOrder);
      }
    }

    if (action.type === SET_IS_SERVICE_ACTIVE) {
      const currentOrder = selectOrder(state, action.id);
      if (currentOrder) {
        checkForExpiredItems(currentOrder);
      }
    }

    const result = next(action);

    if (action.type === orderActions.CLEAR_ORDER) {
      makeDebouncedRequest.cancel();
    }

    if (action.syncOrder) {
      makeDebouncedRequest(getState(), dispatch, (orderRequestId += 1));
    }
    return result;
  };

const getModifierValues = values => {
  // this is disgusting.
  // Ideally, modifiers should always be in the same format,
  // with some allowance given to api vs client formats
  // although these should also be clearly defined,
  // with transformers labelled `{object}FromApi` and `{object}ToApi`
  const gotValues = [];

  values.forEach(value => {
    const quantity = value.get('quantity');
    const modifierId = value.get('id') || value.get('value');

    quantity
      ? Array(quantity)
          .fill()
          .forEach(() => gotValues.push(modifierId))
      : gotValues.push(modifierId);
  });

  return gotValues;
};

const modifierTransformer = modifier => ({
  group: modifier.get('group'),
  modifier: modifier.getIn(['modifier', 'id']),
  values: getModifierValues(modifier.get('values')),
});

const orderItemTransformer = orderItem => ({
  section_product_id: orderItem.get('section_product_id'),
  id: orderItem.get('id') ? orderItem.get('id') : null,
  notes: orderItem.get('notes'),
  quantity: orderItem.get('quantity'),
  name: orderItem.get('name'),
  prices: orderItem.get('prices'),
  unit_price: orderItem.get('unit_price'),
  product: orderItem.get('product'),
  modifiers: orderItem.get('modifiers') && orderItem.get('modifiers').map(modifierTransformer).toJS(),
  includes_tax: orderItem.get('includes_tax'),
  tax_category_ids: orderItem.get('tax_category_ids'),
  base_product_id: orderItem.get('base_product_id'),
  key: orderItem.get('key'),
  is_upsell: orderItem.get('is_upsell'),
});

const makeRequest = (
  newState,
  dispatch,
  currentOrderRequestId,
  exponentialAttemptNumber = 1,
  lastTimeOut
) => {
  const exponentialMaxAttempts = 6; // 6 attempts 1, 2, 4, 8, 16, 32
  const requestData = {
    serviceId: selectOrderServiceId(newState),
    menuTypeId: getOrderMenuTypeId(newState),
    items: selectOrderItems(newState)
      .filter(orderItem => orderItem.get('type') === productType)
      .map(orderItemTransformer),
  }; // Don't send promotions back to the API so filter them out

  if (!requestData.serviceId || !requestData.menuTypeId) return; // return if no order found

  if (isDineInOrderingFlow(newState, requestData.serviceId)) {
    requestData.sessionId = selectSessionId(newState);
  }

  const success = res => {
    if (orderRequestId === currentOrderRequestId) {
      dispatch(updateOrderFromServer(res));

      getCrossSells(newState, dispatch);
    }
  };
  const fail = res => {
    if (res?.violations) dispatch(checkOrderViolations(res.violations));
    else if (exponentialAttemptNumber >= exponentialMaxAttempts) dispatch(failedOrderUpdate());
    else
      makeExponentialRequest(
        newState,
        dispatch,
        currentOrderRequestId,
        exponentialAttemptNumber,
        lastTimeOut
      );
  };

  if (orderRequestId >= currentOrderRequestId) {
    // don't retry out of date requests
    fetchHelper('/api/orders', 'POST', requestData, success, fail);
  }
};

const makeDebouncedRequest = debounce(
  (newState, dispatch, currentOrderRequestId) => makeRequest(newState, dispatch, currentOrderRequestId, 1),
  2000,
  { leading: false, trailing: true }
);

const makeExponentialRequest = (
  newState,
  dispatch,
  currentOrderRequestId,
  exponentialAttemptNumber,
  lastTimeOut = 1000
) => {
  const timeOutBase = 1000;
  const currentTimeOut = createExponentialTimeOut(timeOutBase, exponentialAttemptNumber);
  const jitterTimeout = getRandomInt(lastTimeOut, currentTimeOut);
  exponentialAttemptNumber += 1;
  setTimeout(() => {
    makeRequest(newState, dispatch, currentOrderRequestId, exponentialAttemptNumber, jitterTimeout);
  }, jitterTimeout);
};

const createExponentialTimeOut = (timeOutBase = 1000, exponentialAttemptNumber) => {
  const exponentialValue = 2 ** exponentialAttemptNumber;
  return timeOutBase * exponentialValue;
};

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; // The maximum is exclusive and the minimum is inclusive
};
