import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { FormattedMessage } from 'react-intl';

import NotesIcon from 'components/Icons/NotesIcon';
import ProductPrice from 'components/Product/price';
import FormattedModifierValue from 'components/FormattedModifierValue';
import { getModifierPrice } from 'selectors/browse';
import { getOrderItemTotal } from 'selectors/order';
import ItemName from 'components/OrderReview/ItemName';
import globalMessages from 'components/globalMessages';

import messages from '../messages';
import {
  OrderLineItemContainer,
  ItemDetails,
  StyledName,
  Modifier,
  ModifierPrice,
  Price,
  TextButtonContainer,
  TextButtonsContainer,
} from './styles';

const OrderItem = ({ item, onEdit, onRemove, orderItemTotal, getModifierPrice }) => (
  <OrderLineItemContainer>
    <ItemDetails>
      <StyledName>
        <ItemName item={item} />
      </StyledName>
      <Price>
        <ProductPrice price={orderItemTotal} />
      </Price>
    </ItemDetails>
    {item.get('modifiers') && (
      <div>
        {item.get('modifiers').map(modifier =>
          modifier.get('values').map((value, index) => (
            <Modifier key={index} className="secondary-font">
              {`${modifier.getIn(['modifier', 'name'])}: `}
              <span className="lowercase">
                {value.get('quantity') > 1 ? `${value.get('quantity')} x ` : ''}
              </span>
              <FormattedModifierValue
                value={value.get('value')}
                type={modifier.getIn(['modifier', 'type'])}
              />
              <ModifierPrice>
                {value.get('price_adjustment') ? (
                  <ProductPrice price={getModifierPrice(value, item.get('tax_category_ids'))} />
                ) : (
                  ''
                )}
              </ModifierPrice>
            </Modifier>
          ))
        )}
      </div>
    )}
    {(item.get('notes') || null) && (
      <div>
        <NotesIcon height="20px" />
        <FormattedMessage {...messages.has_notes} />
      </div>
    )}
    <TextButtonsContainer>
      {onEdit && (
        <TextButtonContainer className="order-summary-buttons" onClick={onEdit}>
          <FormattedMessage {...globalMessages.edit} />
        </TextButtonContainer>
      )}
      {onRemove && (
        <TextButtonContainer className="order-summary-buttons" onClick={onRemove}>
          <FormattedMessage {...globalMessages.remove} />
        </TextButtonContainer>
      )}
    </TextButtonsContainer>
  </OrderLineItemContainer>
);

OrderItem.propTypes = {
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  item: PropTypes.instanceOf(Map).isRequired,
  getModifierPrice: PropTypes.func,
  orderItemTotal: PropTypes.number,
};

const mapStateToProps = (state, { item }) => ({
  getModifierPrice: (modifier, taxCategories) => getModifierPrice(state, modifier, taxCategories),
  orderItemTotal: getOrderItemTotal(state, item),
});

export default connect(mapStateToProps)(OrderItem);
