import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';

import { usePrevious } from 'hooks';
import { viewTerms } from 'actions/order';
import Checkbox from 'components/Checkbox';
import { getDetail } from 'selectors/storage';
import { StyledCheckbox } from './styles';

const messages = defineMessages({
  termsAndConditions: {
    defaultMessage: 'Terms & Conditions',
  },
  termsAndConditionsLabel: {
    defaultMessage: 'I have read and accept the',
  },
});

const propTypes = {
  termsAccepted: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  viewTerms: PropTypes.func,
};

const TermsCheckbox = ({ termsAccepted, value, onChange, onBlur, viewTerms }) => {
  const [prevTermsAccepted] = usePrevious(termsAccepted);

  useEffect(() => {
    if (prevTermsAccepted !== termsAccepted && termsAccepted === true) {
      onChange(termsAccepted);
      onBlur(termsAccepted);
    }
  }, [prevTermsAccepted, termsAccepted, onChange, onBlur]);

  return (
    <StyledCheckbox className="secondary-font">
      <Checkbox
        id="acceptsTerms"
        checked={value || termsAccepted === 'true'}
        onChange={event => {
          onChange(event.target.checked);
          onBlur(event.target.checked);
        }}
      >
        <FormattedMessage {...messages.termsAndConditionsLabel} />
      </Checkbox>
      <button type="button" className="linkUnderlined secondary-font" onClick={viewTerms}>
        <FormattedMessage {...messages.termsAndConditions} />
      </button>
    </StyledCheckbox>
  );
};

TermsCheckbox.propTypes = propTypes;

export default connect(
  state => ({
    termsAccepted: getDetail(state, 'terms'),
  }),
  { viewTerms }
)(TermsCheckbox);
