import styled from 'styled-components';

export const Cart = styled.div`
  cursor: pointer;
  width: 48px;
  height: 48px;
  position: relative;
  padding: 10px;

  svg {
    fill: #fff;
  }
`;

export const CartCount = styled.span`
  border-radius: 50%;
  font-size: 0.786em;
  font-weight: 900;
  line-height: 1;
  min-width: 20px;
  padding: 3px;
  position: absolute;
  left: 65%;
  text-align: center;
  top: 10%;
  background-color: ${({ theme }) => theme.color.brandSecondary};
  color: ${({ theme }) => theme.color.brandPrimary};
`;
