import { fromJS } from 'immutable';

import { getSelectedBaseModifierProduct } from 'selectors/browse';
import { REPORTING_CAT_SERVICES } from 'appConstants';
import {
  UPDATE_ITEM,
  REPLACE_ORDER,
  SET_ITEM_NOTES,
  SET_GRATUITY,
  RETRY_ORDER_UPDATE,
  FAILED_ORDER_UPDATE,
  ADD_PRODUCT_TO_ORDER,
  ADD_DONATION_TO_ORDER,
  SET_FULFILMENT_METHOD,
  DECREASE_ITEM_QUANTITY,
  DECREASE_DONATION_QUANTITY,
  REMOVE_PRODUCT_FROM_ORDER,
  REMOVE_DONATION_FROM_ORDER,
  UPDATE_ORDER_FROM_SERVER,
  VALIDATING_PROMOTION,
  SET_PROMOTION_APPLIED,
} from 'actions/order/constants';
import { productType } from 'selectors/orderItemTypes';
import {
  getSimpleProductExistingIndex,
  getCustomisedProductExistingIndex,
  getDonationExistingIndex,
} from 'utils/mergeOrders';

export const orderSyncReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_ITEM:
    case SET_ITEM_NOTES:
    case DECREASE_ITEM_QUANTITY:
    case DECREASE_DONATION_QUANTITY:
    case ADD_PRODUCT_TO_ORDER:
    case ADD_DONATION_TO_ORDER:
    case REMOVE_PRODUCT_FROM_ORDER:
    case REMOVE_DONATION_FROM_ORDER:
    case RETRY_ORDER_UPDATE:
    case REPLACE_ORDER:
      return {
        status: 'syncing',
      };
    case UPDATE_ORDER_FROM_SERVER:
      return {
        status: 'synced',
      };
    case FAILED_ORDER_UPDATE:
      return {
        status: 'failed',
      };

    default:
      return state;
  }
};

export const initialState = fromJS({
  items: [],
  tax_totals: [],
  lastItemId: undefined,
  menuTypeId: undefined,
  gratuity: undefined,
  fulfilmentMethod: undefined,
  serviceId: undefined,
  state: undefined,
  orderSync: {},
  promotion_coupon: undefined,
  validatingPromotionCoupon: false,
});

export const removeProduct = (action, state) => {
  let key;
  if (action.hasOwnProperty('id')) {
    key = state.findLastKey(item => item.get('id') === action.id);
  }
  if (action.hasOwnProperty('index')) {
    key = action.index;
  }
  if (key >= 0) {
    return state.remove(key);
  }
  return state;
};

export const removeDonation = (action, state) => {
  const key = state.findLastKey(item => item.get('product') === action.id);

  if (key >= 0) {
    return state.remove(key);
  }

  return state;
};

function orderReducer(state = initialState, action) {
  return fromJS({
    items: itemsReducer(state.get('items'), action),
    tax_totals: taxTotalsReducer(state.get('tax_totals'), action),
    lastItemId: lastItemIdReducer(state.get('lastItemId'), action),
    menuTypeId: menuTypeReducer(state.get('menuTypeId'), action),
    gratuity: gratuityReducer(state.get('gratuity'), action),
    fulfilmentMethod: fulfilmentMethodReducer(state.get('fulfilmentMethod'), action),
    serviceId: serviceIdReducer(state.get('serviceId'), action),
    state: orderStateReducer(state.get('state'), action),
    orderSync: orderSyncReducer(state.get('orderSync'), action),
    promotion_coupon: couponReducer(state.get('promotion_coupon'), action),
    validatingPromotionCoupon: loadingCouponReducer(state.get('validatingPromotionCoupon'), action),
  });

  function gratuityReducer(state, action) {
    if (action.type === SET_GRATUITY) {
      return {
        value: action.gratuity,
        type: action.gratuityType,
      };
    }
    return state;
  }
  function fulfilmentMethodReducer(state, action) {
    if (action.type === SET_FULFILMENT_METHOD) return action.fulfilmentMethodId;
    return state;
  }
  function menuTypeReducer(state, action) {
    return action.menuTypeId;
  }
  function serviceIdReducer(state, action) {
    return action.serviceId;
  }
  function orderStateReducer(state, action) {
    if (action.type === UPDATE_ORDER_FROM_SERVER) return action.state;
    return state;
  }
  function lastItemIdReducer(state, action) {
    if (action.type === ADD_PRODUCT_TO_ORDER) return action.item && action.item.get('section_product_id');
    return state;
  }
  function couponReducer(state, action) {
    if (action.type === SET_PROMOTION_APPLIED) return action.couponApplied;
    return state;
  }
  function loadingCouponReducer(state, action) {
    if (action.type === VALIDATING_PROMOTION) return true;
    if (action.type === SET_PROMOTION_APPLIED) return false;
    return state;
  }

  function itemsReducer(state, action) {
    switch (action.type) {
      case ADD_PRODUCT_TO_ORDER: {
        const quantity = action.quantity || 1;
        const itemHasModifiers = !!action.modifiers?.size;
        const cartItems = state.toJS();
        const item = {
          ...action.item.toJS(),
          notes: action.notes,
          modifiers: action?.modifiers ? action.modifiers?.toJS() : [],
        };
        let existingItemIndex = itemHasModifiers
          ? getCustomisedProductExistingIndex(cartItems, item)
          : getSimpleProductExistingIndex(cartItems, item);
        if (action.notes) existingItemIndex = -1;

        let newList;

        const valueForcesVat = value => value.get('force_vat');
        const modifierForcesVat = modifier => modifier.get('values').some(valueForcesVat);
        const modifiersForceVat = modifiers => !!modifiers && modifiers.some(modifierForcesVat);

        const itemPrice = action.item.getIn(['prices', action.menuType.get('id').toString(), 'price']);
        const baseModifierProd = getSelectedBaseModifierProduct(action.modifiers);

        let taxIds = action.item.getIn(['product', 'tax_categories']) || null;
        taxIds = baseModifierProd ? baseModifierProd?.get('tax_categories') : taxIds;

        const orderItem = fromJS({
          includes_tax: action.menuType.get('inclusive_tax') || modifiersForceVat(action.modifiers),
          modifiers: action.modifiers,
          notes: action.notes,
          quantity,
          id: null,
          base_product_id: action.item.getIn(['product', 'id']),
          unit_price: baseModifierProd?.get('price') || itemPrice,
          product: baseModifierProd?.get('id') || action.item.getIn(['product', 'id']),
          name: baseModifierProd?.get('name') || action.item.getIn(['product', 'name']),
          tax_category_ids: taxIds,
          type: productType,
          is_upsell: action.item.get('is_upsell') || null,
        });

        if (existingItemIndex === -1) {
          newList = state.push(action.item.merge(orderItem));
        } else {
          const newQuantity = state.getIn([existingItemIndex, 'quantity']) + quantity;
          newList = state.setIn([existingItemIndex, 'quantity'], newQuantity);
        }

        return newList;
      }

      case ADD_DONATION_TO_ORDER: {
        const quantity = action.quantity || 1;
        const cartItems = state.toJS();
        const existingItemIndex = getDonationExistingIndex(cartItems, action.item?.toJS());
        let newList;

        if (existingItemIndex === -1) {
          newList = state.push(
            action.item.merge(
              fromJS({
                id: null,
                includes_tax: true,
                quantity,
                unit_price: action.item.get('price'),
                type: productType,
                modifier_groups: [],
                base_product_id: action.item.get('product'),
                reporting_category: REPORTING_CAT_SERVICES,
                key: action.source,
              })
            )
          );
        } else {
          const newQuantity = state.getIn([existingItemIndex, 'quantity']) + quantity;
          newList = state.setIn([existingItemIndex, 'quantity'], newQuantity);
        }

        return newList;
      }

      case REMOVE_PRODUCT_FROM_ORDER:
        return removeProduct(action, state);

      case REMOVE_DONATION_FROM_ORDER:
        return removeDonation(action, state);

      case DECREASE_ITEM_QUANTITY: {
        const itemIndex = state.findKey(item => item.get('section_product_id') === action.id);
        if (itemIndex === undefined) return state;

        const item = state.get(itemIndex);
        if (item.get('quantity') > 1) {
          return state.setIn([itemIndex, 'quantity'], item.get('quantity') - 1);
        }

        return removeProduct({ index: itemIndex, source: 'Decrease quantity' }, state);
      }

      case DECREASE_DONATION_QUANTITY: {
        const itemIndex = state.findIndex(item => item.get('product') === action.id);
        if (itemIndex === undefined) return state;

        const item = state.get(itemIndex);
        if (item.get('quantity') > 1) {
          return state.setIn([itemIndex, 'quantity'], item.get('quantity') - 1);
        }

        return removeProduct({ index: itemIndex, source: 'Decrease quantity' }, state);
      }

      case UPDATE_ITEM: {
        const baseModifierProd = getSelectedBaseModifierProduct(action.modifiers);
        const currentItem = state.get(action.itemIndex);

        return state.mergeIn([action.itemIndex], {
          modifiers: action.modifiers,
          notes: action.notes,
          quantity: action.quantity,
          unit_price: baseModifierProd?.get('price') || currentItem.get('unit_price'),
          product: baseModifierProd?.get('id') || currentItem.get('product'),
          name: baseModifierProd?.get('name') || currentItem.get('name'),
          tax_category_ids: baseModifierProd
            ? baseModifierProd?.get('tax_categories')
            : currentItem.get('tax_category_ids'),
        });
      }

      case SET_ITEM_NOTES:
        return state.setIn([action.itemIndex, 'notes'], action.notes);

      case UPDATE_ORDER_FROM_SERVER:
      case REPLACE_ORDER:
        return fromJS(action?.items || []);

      default:
        return state;
    }
  }

  function taxTotalsReducer(state, action) {
    if (action.type === UPDATE_ORDER_FROM_SERVER) return action.taxTotals;

    return state;
  }
}
export default orderReducer;
