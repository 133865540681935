import styled from 'styled-components';

export const BadgeWrapper = styled.div`
  width: 30px;
  background: ${({ theme }) => theme.color.success};
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & > span {
    color: ${({ theme }) => theme.color.brandTextColor};
  }
`;

export const ProductListingWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 0 10px 20px;
  width: 100%;
  gap: 20px;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.color.borderColor};
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 20px 0;
  width: 100%;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > .description {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 38px;
    line-height: 130%;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  @media (min-width: 440px) {
    & > .description {
      font-size: 18px;
      height: 48px;
    }
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  gap: 5px;

  & > .name {
    font-weight: 600;
    line-height: 120%;
  }
  @media (min-width: 440px) {
    & > .name {
      font-size: 24px;
    }
  }
`;

export const ProductDetails = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 440px) {
    font-size: 21px;
  }
`;

export const ProductImageWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.brandPrimary};
  width: 40%;
  max-width: 200px;
  aspect-ratio: 1 / 1;
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;

  & > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
