import styled, { css } from 'styled-components';
import { Link } from 'react-router';

export const HeaderFixed = styled.header`
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.nine};
  height: 48px;
  overflow: hidden;
  width: 100%;
  justify-content: space-between;
  padding: 0 5px;

  ${props =>
    props.sidebarOpen &&
    css`
      position: sticky;
    `}
`;

export const LogoContainer = styled.div`
  display: inline-block;
  padding: 8px 10px;
  position: relative;
  text-decoration: none !important;
  width: 100%;
  text-align: center;

  html[dir='rtl'] & {
    left: initial;
    right: 10px;
  }
`;

export const StyledLink = styled(Link)`
  display: inline-block;
`;

export const LogoImg = styled.img`
  height: 32px;
  max-width: 100%;
  width: auto;
  float: left;
`;

export const ButtonsContainer = styled.div`
  position: absolute;
  top: 0;

  ${props => (props.position === 'left' ? 'left: 0;' : 'right: 0;')}

  html[dir='rtl'] & {
    float: left;
  }
`;

export const HeaderButton = styled.button`
  cursor: pointer;
  float: right;
  height: 48px;
  position: relative;

  html[dir='rtl'] & {
    float: left;
  }
`;
