import React, { useEffect } from 'react';
import { Map, List } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';
import { browserHistory } from 'react-router';

import { setBrowseInfo } from 'actions/browse';
import Loading from 'components/Loading/index';
import ContentArea from 'components/ContentArea';
import SectionLink from 'components/SectionLink';
import MessageBlock from 'components/MessageBlock';
import {
  getMenuById,
  isMenuActive,
  getMenuNextActiveDetails,
  selectSections,
  menuIsLoading,
  menuHasFilteredSections,
} from 'selectors/browse';
import RadiusMessage from 'components/RadiusMessage';
import OutOfServiceMessage from 'components/OutOfServiceMessage';
import { getServiceById, getServiceId } from 'selectors/root';
import FilteredItemsMessage from 'components/FilteredItemsMessage';
import { getLocale } from 'selectors/user';

import { MenuContainer } from './styles';

const messages = defineMessages({
  loadingMenus: {
    defaultMessage: 'Loading Menus...',
  },
  noProductsTitle: {
    defaultMessage: 'Sorry',
  },
  noProducts: {
    defaultMessage:
      'There are currently no products available in the section at this time. Please check back soon?',
  },
  loading: {
    defaultMessage: 'Loading',
  },
});

const propTypes = {
  menu: PropTypes.instanceOf(Map),
  serviceId: PropTypes.string.isRequired,
  sections: PropTypes.instanceOf(List),
  menuId: PropTypes.string.isRequired,
  setBrowseInfo: PropTypes.func,
  isLoading: PropTypes.bool,
  menuHasFilteredSections: PropTypes.bool,
  nextAvailableTime: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.string,
      time: PropTypes.number,
    }),
  ]),
  menuActive: PropTypes.bool,
  currentLocale: PropTypes.string,
};

const Menu = ({
  menu,
  serviceId,
  sections,
  menuId,
  setBrowseInfo,
  isLoading,
  menuHasFilteredSections,
  nextAvailableTime,
  menuActive,
  currentLocale,
}) => {
  useEffect(() => {
    if (!menu) browserHistory.replace(`/service/${serviceId}`);
    if (sections?.size === 1)
      browserHistory.replace(`/service/${serviceId}/menu/${menuId}/section/${sections.first()?.get('id')}`);

    if (menu) {
      setBrowseInfo(
        menu?.get('translations')?.get(currentLocale)?.get('name') ?? menu.get('name'),
        menu?.get('translations')?.get(currentLocale)?.get('description') ?? menu.get('description'),
        menu.getIn(['images', 0, 'path'])
      );
    }
  }, [menu, currentLocale]);

  if (isLoading) {
    return (
      <Loading>
        <FormattedMessage {...messages.loadingMenus} />
      </Loading>
    );
  }
  if (menu && sections?.size === 0) {
    return (
      <MessageBlock
        header={<FormattedMessage {...messages.noProductsTitle} />}
        body={<FormattedMessage {...messages.noProducts} />}
      />
    );
  }

  const sectionHasImages = !!sections.find(section => section.getIn(['images', 0, 'path']));

  return (
    <>
      <ContentArea area="menu_start" position="top" />
      {menuHasFilteredSections && <FilteredItemsMessage type="sections" />}
      <RadiusMessage />
      <OutOfServiceMessage nextAvailableTime={nextAvailableTime} />

      <MenuContainer>
        {sections?.map(section => {
          if (section.get('parent_id') !== null) {
            return null;
          }

          return (
            <SectionLink
              key={section.get('id')}
              section={section}
              serviceId={serviceId}
              menuId={menuId}
              menuActive={menuActive}
              listView={!sectionHasImages}
            />
          );
        })}
      </MenuContainer>

      <ContentArea area="menu_end" position="bottom" />
    </>
  );
};

Menu.propTypes = propTypes;

export default connect(
  (state, { params }) => {
    const menuId = params?.menuId;
    const serviceId = getServiceId(state);
    const menu = getMenuById(state, menuId);
    const isLoading = menuIsLoading(state);

    return {
      menuId,
      serviceId,
      sections: isLoading ? new List() : selectSections(state, menu, getServiceById(state, serviceId)),
      menu,
      nextAvailableTime: getMenuNextActiveDetails(state, menuId, serviceId),
      menuActive: isMenuActive(state, menuId, serviceId),
      isLoading,
      menuHasFilteredSections: menuHasFilteredSections(state, menu),
      currentLocale: getLocale(state),
    };
  },
  { setBrowseInfo }
)(Menu);
