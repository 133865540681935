import styled, { css } from 'styled-components';
import { Link } from 'react-router';
import { fontSizeSmall } from 'assets/styles/fonts';
import CartButton from 'components/CartButton';
import { CartCount } from 'components/CartButton/styles';

export const StyledFooter = styled.div`
  bottom: 0;
  border-top-width: 1px;
  border-top-style: solid;
  display: block;
  position: fixed;
  width: 100%;
  height: 53px;
  z-index: ${({ theme }) => theme.zIndex.nine};

  ${props =>
    props.settingsPage &&
    css`
      background: ${({ theme }) => theme.color.footerBg};
      color: ${({ theme }) => theme.color.footerColor};
      border-top: 1px solid ${({ theme }) => theme.color.borderColor};

      .icon {
        fill: ${({ theme }) => theme.color.footerColor};
      }
    `}

  ${props =>
    props.sidebarOpen &&
    css`
      position: sticky;
    `}
`;

export const ViewOrderFooter = styled.div`
  bottom: 0;
  display: block;
  position: fixed;
  width: 100%;

  z-index: ${({ theme }) => theme.zIndex.nine};

  ${props =>
    props.sidebarOpen &&
    css`
      position: sticky;
    `}
`;

export const IconMenu = styled.ul`
  list-style: none;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-x: auto;
  display: flex;
  justify-content: space-between;

  ::before,
  ::after {
    content: '';
  }

  @media (max-width: 768px) {
    justify-content: space-between;
  }

  html[dir='rtl'] & {
    padding-left: 48px;
    padding-right: 0;
  }
`;

export const StyledItem = styled.li`
  text-align: center;

  svg {
    max-height: 98%;
  }
`;

const sharedStyles = css`
  display: block;
  cursor: pointer;
  min-width: 48px;
  padding: 5px;
  position: relative;
  width: auto;
  height: 70%;

  svg {
    margin-bottom: 3px;
    width: auto;
  }
`;

export const StyledLink = styled(Link)`
  ${sharedStyles}
`;

export const StyledHref = styled.a`
  ${sharedStyles}
`;

export const StyledLabel = styled.span`
  display: block;
  ${fontSizeSmall}
  text-align: center;
  white-space: nowrap;
`;

export const StyledCartButton = styled(CartButton)`
  display: inline;
  border-left: 0;
  float: none;
  padding: 0;

  ${CartCount} {
    top: -4px;
    left: ${({ theme }) => theme.dir === 'rtl' && '0'};

    ${props =>
      props.settingsPage &&
      css`
        background-color: ${({ theme }) => theme.color.footerColor};
        color: ${({ theme }) => theme.color.footerBg};
      `};
  }

  svg {
    margin-bottom: 3px;
    width: auto;
  }
`;
