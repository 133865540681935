import styled, { css } from 'styled-components';

export const SidebarStyled = styled.div`
  background-color: ${({ theme }) => theme.color.primaryBackground};
  height: 100%;
  left: 100%;
  position: fixed;
  top: 0;
  transform: translateX(${({ isOpen }) => (isOpen ? '-100%' : '0')});
  transition: transform 200ms ease-in;
  width: calc(100% - 48px);
  z-index: ${({ isOpen, theme }) => isOpen && theme.zIndex.nine};

  ${props =>
    props.theme.dir === 'rtl' &&
    css`
      left: auto;
      right: 100%;
      transform: translateX(${({ isOpen }) => isOpen && '100%'});
    `}

  .powered-by {
    position: absolute;
    bottom: 10px;
  }

  @media (min-width: 768px) {
    & {
      width: 320px;
    }
  }
`;
