import { defineMessages } from 'react-intl';

const globalMessages = defineMessages({
  cancel: {
    defaultMessage: 'Cancel',
  },
  close: {
    defaultMessage: 'Close',
  },
  continue: {
    defaultMessage: 'Continue',
  },
  confirm: {
    defaultMessage: 'Confirm',
  },
  confirmAddress: {
    defaultMessage: 'Confirm Address',
  },
  previous: {
    defaultMessage: 'Previous',
  },
  next: {
    defaultMessage: 'Next',
  },
  no: {
    defaultMessage: 'No',
  },
  yes: {
    defaultMessage: 'Yes',
  },
  view: {
    defaultMessage: 'View',
  },
  viewOrder: {
    defaultMessage: 'View Order',
  },
  viewTab: {
    defaultMessage: 'View Tab',
  },
  home: {
    defaultMessage: 'Home',
  },
  menu: {
    defaultMessage: 'Menu',
  },
  menus: {
    defaultMessage: 'Menus',
  },
  returnToMenu: {
    defaultMessage: 'Return to Menu',
  },
  back: {
    defaultMessage: 'Back',
  },
  searchMenu: {
    defaultMessage: 'Search Menu',
  },
  loading: {
    defaultMessage: 'Loading...',
  },
  error: {
    defaultMessage: 'Error',
  },
  warning: {
    defaultMessage: 'Warning',
  },
  wrongDates: {
    defaultMessage: 'From Date Should Be smaller Than To Date.',
  },
  orderSummary: {
    defaultMessage: 'Order Summary',
  },
  tabSummary: {
    defaultMessage: 'Tab Summary',
  },
  yourOrder: {
    defaultMessage: 'Your Order',
  },
  checkout: {
    defaultMessage: 'Checkout',
  },
  cart: {
    defaultMessage: 'Cart',
  },
  payTab: {
    defaultMessage: 'Pay Tab',
  },
  payNow: {
    defaultMessage: 'Pay Now',
  },
  orderNow: {
    defaultMessage: 'Order Now',
  },
  location: {
    defaultMessage: 'Location',
  },
  get_started: {
    defaultMessage: 'Get Started',
  },
  enable: {
    defaultMessage: 'enable',
  },
  disable: {
    defaultMessage: 'disable',
  },
  enabled: {
    defaultMessage: 'enabled',
  },
  disabled: {
    defaultMessage: 'disabled',
  },
  unavailable: {
    defaultMessage: 'unavailable',
  },
  optionUnavailable: {
    defaultMessage: '{label} (unavailable)',
  },
  leaveFeedback: {
    defaultMessage: 'Leave Feedback',
  },
  filter: {
    defaultMessage: 'Filter',
  },
  filtered: {
    defaultMessage: 'Filtered',
  },
  filterYourOrders: {
    defaultMessage: 'Filter your orders',
  },
  noResults: {
    defaultMessage: 'No results found.',
  },
  checkFilters: {
    defaultMessage: 'Please check your filters.',
  },
  edit: {
    defaultMessage: 'Edit',
  },
  delete: {
    defaultMessage: 'Delete',
  },
  apply: {
    defaultMessage: 'Apply',
  },
  clearFilters: {
    defaultMessage: 'Clear filters',
  },
  applyFilters: {
    defaultMessage: 'Apply filters',
  },
  dateRange: {
    defaultMessage: 'Date range',
  },
  from: {
    defaultMessage: 'From',
  },
  to: {
    defaultMessage: 'To',
  },
  today: {
    defaultMessage: 'Today',
  },
  tomorrow: {
    defaultMessage: 'Tomorrow',
  },
  loadMore: {
    defaultMessage: 'Load More',
  },
  haveAccount: {
    defaultMessage: 'Already have a wi-Q account?',
  },
  noAccount: {
    defaultMessage: "Don't have a wi-Q account?",
  },
  signUp: {
    defaultMessage: 'Sign Up',
  },
  login: {
    defaultMessage: 'Login',
  },
  loggedOut: {
    defaultMessage: 'Logged Out',
  },
  submitRequest: {
    defaultMessage: 'Submit Request',
  },
  clearSession: {
    defaultMessage: 'Clear Session',
  },
  exit: {
    defaultMessage: 'Exit',
  },
  telephone: {
    defaultMessage: 'Telephone:',
  },
  address: {
    defaultMessage: 'Address:',
  },
  email: {
    defaultMessage: 'Email:',
  },
  exportPdf: {
    defaultMessage: 'Export order to PDF',
  },
  confirmExport: {
    defaultMessage: 'Confirm Export',
  },
  exportOrders: {
    defaultMessage: 'Export orders',
  },
  errorMsg: {
    defaultMessage: 'There was a problem processing this request.',
  },
  awaitingImage: {
    defaultMessage: 'Awaiting Image',
  },
  taxIncluded: {
    defaultMessage: '(included in price)',
  },
  table: {
    defaultMessage: 'Table',
  },
  room: {
    defaultMessage: 'Room',
  },
  seat: {
    defaultMessage: 'Seat',
  },
  sunbed: {
    defaultMessage: 'Sunbed',
  },
  car: {
    defaultMessage: 'Car Registration',
  },
  enterYourPostcode: {
    defaultMessage: 'Enter your postcode',
  },
  isDefaultText: {
    defaultMessage: 'Default',
  },
  deliveryAddressesMessage: {
    defaultMessage: 'Please select a delivery address.',
  },
  deliveryAddressError: {
    defaultMessage: 'Your address is outside of our delivery area. Please select an alternative address.',
  },
  deliveryAddress: {
    defaultMessage: 'Delivery Address',
  },
  deliveryAddressUnavailable: {
    defaultMessage: 'Delivery Unavailable',
  },
  deliveryAddressAvailable: {
    defaultMessage: 'Delivery Available',
  },
  deliveryAddressMsg: {
    defaultMessage: 'Please enter your delivery details to confirm that you are within our delivery zone.',
  },
  deliveryAddressMsgUnavailable: {
    defaultMessage:
      'We are sorry to say that your address "{ address }" is outside of our delivery area. Please select an alternative address.',
  },
  recentAddresses: {
    defaultMessage: 'for your recent addresses',
  },
  enterYourAddress: {
    defaultMessage: 'Enter your address',
  },
  missingAddressFields: {
    defaultMessage: 'Please make sure your address contains a town/city and your street/road',
  },
  verify: {
    defaultMessage: 'Verify',
  },
  dineIn: {
    defaultMessage: 'Dine in',
  },
  takeAway: {
    defaultMessage: 'Takeaway',
  },
  collection: {
    defaultMessage: 'Collection',
  },
  delivery: {
    defaultMessage: 'Delivery',
  },
  roomService: {
    defaultMessage: 'Room Service',
  },
  careem: {
    defaultMessage: 'Careem',
  },
  deliveroo: {
    defaultMessage: 'Deliveroo',
  },
  doordash: {
    defaultMessage: 'Doordash',
  },
  grubHub: {
    defaultMessage: 'Grub Hub',
  },
  uberEats: {
    defaultMessage: 'Uber Eats',
  },
  talabat: {
    defaultMessage: 'Talabat',
  },
  hungerstation: {
    defaultMessage: 'Hunger Station',
  },
  zomato: {
    defaultMessage: 'Zomato',
  },
  jahez: {
    defaultMessage: 'Jahez',
  },
  toyou: {
    defaultMessage: 'Toyou',
  },
  mrsool: {
    defaultMessage: 'Mrsool',
  },
  retry: {
    defaultMessage: 'Retry',
  },
  orderSyncFailed: {
    defaultMessage: 'There was a problem syncing your order',
  },
  optIn: {
    defaultMessage: 'Opt-in',
  },
  findOutMore: {
    defaultMessage: 'Find out more',
  },
  sendToKitchen: {
    defaultMessage: 'Send to Kitchen',
  },
  enterPasscode: {
    defaultMessage: 'Enter your passcode',
  },
  yourPasscode: {
    defaultMessage: 'Your passcode',
  },
  orderAgain: {
    defaultMessage: 'Order again',
  },
  sharePasscode: {
    defaultMessage: 'Share your passcode',
  },
  redeemYourVoucherCode: {
    defaultMessage: 'Redeem your voucher code',
  },
  selectLanguage: {
    defaultMessage: 'Please select a language',
  },
  submit: {
    defaultMessage: 'Submit',
  },
  xItems: {
    defaultMessage: `{itemCount, plural,
      one {{itemCount} item}
      other {{itemCount} items}
    }`,
  },
  remove: {
    defaultMessage: 'Remove',
  },
  total: {
    defaultMessage: 'Total',
  },
});

export default globalMessages;
