import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getMenuNextActiveDetails } from 'selectors/browse';

import HeroLink from 'components/HeroLink';

const propTypes = {
  serviceId: PropTypes.string,
  menuId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sectionId: PropTypes.number,
  name: PropTypes.string,
  imagePath: PropTypes.string,
  active: PropTypes.bool,
  isFiltered: PropTypes.bool,
  menuActiveTime: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

const BrowseLink = ({
  serviceId,
  menuId,
  sectionId,
  name,
  imagePath,
  active,
  isFiltered,
  menuActiveTime,
}) => (
  <HeroLink
    image={imagePath}
    text={name}
    filtered={isFiltered}
    availableTime={!active && menuActiveTime && !sectionId ? menuActiveTime : null}
    url={{
      pathname: sectionId
        ? `/service/${serviceId}/menu/${menuId}/section/${sectionId}`
        : `/service/${serviceId}/menu/${menuId}`,
    }}
  />
);

BrowseLink.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => ({
  menuActiveTime: getMenuNextActiveDetails(state, ownProps.menuId, ownProps.serviceId),
});

export default connect(mapStateToProps)(BrowseLink);
