// Order states
export const ORDER_STATE_CART = 'cart';
export const ORDER_STATE_NEW = 'new';
export const ORDER_STATE_PENDING = 'pending';
export const ORDER_STATE_REQUIRES_REVIEW = 'requires_review';
export const ORDER_STATE_APPROVED = 'approved';
export const ORDER_STATE_REJECTED = 'rejected';
export const ORDER_STATE_CONFIRMED = 'confirmed';
export const ORDER_STATE_FAILED = 'failed';

// Payment states
export const PAYMENT_STATE_NEW = 'new';
export const PAYMENT_STATE_PENDING = 'pending';
export const PAYMENT_STATE_PROCESSING = 'processing';
export const PAYMENT_STATE_COMPLETED = 'completed';
export const PAYMENT_STATE_AUTHORIZED = 'authorized';
export const PAYMENT_STATE_FAILED = 'failed';
export const PAYMENT_STATE_CANCELLED = 'cancelled';
export const PAYMENT_STATE_VOID = 'void';
export const PAYMENT_STATE_REFUNDED = 'refunded';
export const PAYMENT_STATE_UNKNOWN = 'unknown';

// Tab states
export const TAB_STATE_OPEN = 'new';
export const TAB_STATE_CLOSED = 'closed';

// Reporting
export const REPORTING_CAT_AMENITIES = 1;
export const REPORTING_CAT_BEVERAGES = 2;
export const REPORTING_CAT_FOOD = 3;
export const REPORTING_CAT_SERVICES = 4;

// Location types
export const LOCATION_TYPE_POINT = 'point';
export const LOCATION_TYPE_TABLE = 'table';
export const LOCATION_TYPE_ADDRESS = 'address';
export const LOCATION_TYPE_ROOM = 'room';
export const LOCATION_TYPE_SEAT = 'seat';
export const LOCATION_TYPE_SUNBED = 'sunbed';
export const LOCATION_TYPE_CAR = 'car';

// Alerts
export const DONATIONS_ALERT = 'DONATIONS_ALERT';
export const CLEAR_NOTES = 'CLEAR_NOTES';
export const MIXED_MENU = 'MIXED_MENU';
export const FEEDBACK_THANKS = 'FEEDBACK_THANKS';
export const LOGOUT_CHECK = 'LOGOUT_CHECK';
export const UNAVAILABLE_ITEMS_ALERT = 'UNAVAILABLE_ITEMS_ALERT';
export const LANGUAGE_ALERT = 'LANGUAGE_ALERT';
export const SELECT_LANGUAGE_ALERT = 'SELECT_LANGUAGE_ALERT';
export const VENUES_LIST_ERROR_ALERT = 'VENUES_LIST_ERROR_ALERT';
export const DELETE_ADDRESS_ALERT = 'DELETE_ADDRESS_ALERT';
export const DELETE_PAYMENT_SOURCE_ALERT = 'DELETE_PAYMENT_SOURCE_ALERT';
export const DELETE_PAYMENT_SOURCE_ERROR_ALERT = 'DELETE_PAYMENT_SOURCE_ERROR_ALERT';
export const MENU_TYPE_PROMPT = 'MENU_TYPE_PROMPT';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const TERMS_AND_CONDITIONS_ALERT = 'TERMS_AND_CONDITIONS_ALERT';
export const LOGIN_REGISTER_ALERT = 'LOGIN_REGISTER_ALERT';
export const LOGGED_OUT_ALERT = 'LOGGED_OUT_ALERT';
export const CONFIRM_LOCATION_ALERT = 'CONFIRM_LOCATION_ALERT';
export const INACTIVE_ALERT = 'INACTIVE_ALERT';
export const KIOSK_ALERT = 'KIOSK_ALERT';
export const IFRAME_ALERT = 'IFRAME_ALERT';
export const CLEAR_ORDER_ALERT = 'CLEAR_ORDER_ALERT';
export const EXPORT_SINGLE_ORDER_ALERT = 'EXPORT_SINGLE_ORDER_ALERT';
export const PRODUCT_OPTION_INFORMATION = 'PRODUCT_OPTION_INFORMATION';
export const PRODUCT_CUSTOMISATION = 'PRODUCT_CUSTOMISATION';
export const PRODUCT_OPTINS = 'PRODUCT_OPTINS';
export const ALLERGEN_INFORMATION = 'ALLERGEN_INFORMATION';
export const NUTRITIONAL_INFORMATION = 'NUTRITIONAL_INFORMATION';
export const ALLERGEN_FILTER = 'ALLERGEN_FILTER';
export const ORDER_INFORMATION = 'ORDER_INFORMATION';
export const ORDER_REVIEW = 'ORDER_REVIEW';
export const ADD_ADDRESS_ALERT = 'ADD_ADDRESS_ALERT';
export const RADIUS_ALERT = 'RADIUS_ALERT';
export const DELIVERY_PARTNERS_ALERT = 'DELIVERY_PARTNERS_ALERT';
export const EXPORT_ORDERS_ALERT = 'EXPORT_ORDERS_ALERT';
export const CARD_CONFIRMED_ALERT = 'CARD_CONFIRMED_ALERT';
export const LOYALTY_MODAL = 'LOYALTY_MODAL';

// Notifications
export const PRIVACY_SETTINGS_NOTIFICATION = 'PRIVACY_SETTINGS_NOTIFICATION';

// Service ordering flow types
export const ORDERING_FLOW_DINE_IN = 'dine-in';
export const ORDERING_FLOW_FULL_CHECKOUT = 'full-checkout';
export const DIGIT_1 = 'DIGIT_1';
export const DIGIT_2 = 'DIGIT_2';
export const DIGIT_3 = 'DIGIT_3';
export const DIGIT_4 = 'DIGIT_4';
export const REQUESTED_DIGITS = [DIGIT_1, DIGIT_2, DIGIT_3, DIGIT_4];
