import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Header, Title, RequiredTxt } from './styles';

const messages = defineMessages({
  checkoutHeader_orderDetails: {
    defaultMessage: 'Your Details',
  },
  checkoutHeader_deliveryDetails: {
    defaultMessage: 'Delivery Details',
  },
  requiredMessage: {
    defaultMessage: '* Required Field',
  },
});

const propTypes = {
  class: PropTypes.string,
  intlIdentifier: PropTypes.string,
  intlLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  requiredFields: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const FormHeader = ({ title, intlIdentifier, intlLabel, requiredFields }) => (
  <Header>
    <Title>{title || <FormattedMessage {...messages[intlIdentifier]} />}</Title>

    {!intlLabel && requiredFields && (
      <RequiredTxt>
        <FormattedMessage {...messages.requiredMessage} />
      </RequiredTxt>
    )}
  </Header>
);

FormHeader.propTypes = propTypes;

export default FormHeader;
