import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { FormattedMessage, defineMessages } from 'react-intl';
import FormattedPrice from 'components/FormattedPrice';
import SuitableFor from 'components/SuitableFor';
import { Chilli } from 'components/Chilli';
import InfoIcon from 'components/Icons/InfoIcon';
import Checkbox from 'components/Checkbox';

import { Container, StyledListOption, OptionInfo, SuitableForContainer, InfoButton } from './styles';
import QuantityOption from '../QuantityOption';
import { addCssPrefixTo } from '../../utils';

const messages = defineMessages({
  priceAdjustment: {
    defaultMessage: '({plus}{ adjustment })',
  },
});

const getOptionComponent = ({ browseOnly, allowMultiple }) => {
  if (browseOnly) return StyledListOption;

  if (allowMultiple) {
    return QuantityOption;
  }

  return Checkbox;
};

const ListOption = ({
  checked,
  choice,
  onCheck,
  onInfo,
  price,
  plusPrice,
  browseOnly,
  maxQuantity,
  allowMultiple,
}) => {
  const Option = useMemo(
    () => getOptionComponent({ browseOnly, allowMultiple }),
    [browseOnly, allowMultiple]
  );

  return (
    <Container className={addCssPrefixTo('lines')}>
      <Option checked={checked} onChange={onCheck} maxQuantity={maxQuantity}>
        <OptionInfo>
          <span>
            {choice.value}
            &nbsp;
            {price ? (
              <FormattedMessage
                {...messages.priceAdjustment}
                values={{ adjustment: <FormattedPrice value={price} />, plus: plusPrice ? '+ ' : '' }}
              />
            ) : (
              ''
            )}
          </span>
          {choice?.product && (
            <SuitableForContainer>
              <SuitableFor product={fromJS(choice.product)} />
              <Chilli product={fromJS(choice.product)} />
            </SuitableForContainer>
          )}
        </OptionInfo>
      </Option>
      {onInfo ? (
        <InfoButton onClick={onInfo}>
          <InfoIcon width="18px" height="18px" />
        </InfoButton>
      ) : (
        ''
      )}
    </Container>
  );
};

ListOption.propTypes = {
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  choice: PropTypes.object,
  onCheck: PropTypes.func,
  onInfo: PropTypes.func,
  price: PropTypes.number,
  plusPrice: PropTypes.bool,
  browseOnly: PropTypes.bool,
  maxQuantity: PropTypes.number,
  allowMultiple: PropTypes.bool,
};

export default ListOption;
