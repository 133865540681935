import styled from 'styled-components';

export const CrossSellsContainer = styled.ul`
  text-align: left;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  list-style: none;

  html[dir='rtl'] & {
    text-align: right;
  }
`;

export const CrossSellList = styled.div`
  display: flex;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
  overflow: auto;
`;

export const SliderItem = styled.div`
  display: inline-block;
  margin-right: 10px;
  white-space: normal;
  scroll-snap-align: start;
  flex: 1;
  max-width: 50%;

  html[dir='rtl'] & {
    margin-right: 0;
    margin-left: 10px;
  }
`;
