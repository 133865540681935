import styled, { css } from 'styled-components';

export const Item = styled.li`
  width: 100%;
  cursor: pointer;

  ${props =>
    props.gridView &&
    css`
      margin-bottom: 10px;
      border: 1px solid ${({ theme }) => theme.color.borderColor};
      border-radius: 5px;
      overflow: hidden;
    `}

  ${props =>
    props.filtered &&
    css`
      opacity: 0.4;
    `}

  ${props =>
    props.searched &&
    css`
      border: 1px solid ${({ theme }) => theme.color.success};
    `}
  }
`;
