import { fromJS } from 'immutable';
import { camelCase } from 'lodash';
import {
  selectFulfilmentMethod,
  selectBlockRequired,
  selectFulfilmentMethodId,
  selectPaymentMethods,
  getCustomFields,
  serviceAcceptsOrderNotes,
  getServiceId,
  selectFulfilmentMethodRequireCovers,
  selectFulfilmentMethodRequireCarMake,
  selectFulfilmentMethodRequireCarModel,
  selectFulfilmentMethodRequireCarColour,
  selectFulfilmentMethodRequireBayNumber,
  selectFulfilmentMethodRadius,
  isDeliveryToAddressEnabled,
  selectPreorderEnabled,
  getIsStaff,
  selectFulfilmentMethodDisplayCutleryQuantity,
  selectFulfilmentMethodFields,
  getVenueMarketingConsent,
} from 'selectors/root';
import {
  enableCollectionTime,
  disableCovers,
  kioskModeEnabled,
  requireTelephone,
  showAllergenCheckbox,
} from 'selectors/features';
import customFieldsFormatter from 'components/CheckoutForm/CustomFieldsFormatter';
import fieldTypes from 'components/Form/fieldTypes';
import { hasValue } from 'components/Form/utils';
import { getLocale, isLoggedIn, selectUserDetail, selectAddressesList } from 'selectors/user';
import { noFoodInCart } from 'selectors/browse';
import { selectLoyaltyTypeByService } from 'selectors/loyalty';
import { initialState } from './initialState';
import { selectOrderFinalTotal } from '../../selectors/order';

// WARNING: Absolute horrors lie on the next line
// See https://wiqlimited.atlassian.net/browse/WA-629 for more information
export const venuesWithMembershipNotDiscount = [215, 216, 217, 220, 365];

export const paymentsContainSiru = paymentMethods =>
  paymentMethods.filter(paymentMethod => paymentMethod.get('name') === 'Siru Mobile').size >= 1;

export const getFulfilmentFormHeader = state => {
  const fulfilmentId = selectFulfilmentMethodId(state);
  if (fulfilmentId) {
    return state.getIn(['fulfilment', 'fulfilmentForms', fulfilmentId.toString(), 'header']);
  }
};

export const getTabCheckoutFields = () => {
  const immutableFieldTypes = fromJS(fieldTypes);

  return [
    immutableFieldTypes.get('name').toJS(),
    {
      ...immutableFieldTypes.get('email').toJS(),
      helpMessage: immutableFieldTypes.get('email').toJS().optionalHelpMessage,
    },
    immutableFieldTypes.get('TabOrderSummary').toJS(),
    fieldTypes.payment,
  ];
};

export const getFulfilmentFormFields = state => {
  const fulfilmentId = selectFulfilmentMethodId(state);

  if (fulfilmentId) {
    const immutableFieldTypes = fromJS(fieldTypes);
    const kioskMode = kioskModeEnabled(state);
    const methodFields = selectFulfilmentMethodFields(state);

    let fields = fromJS(
      methodFields
        .filter(methodField => methodField.get(kioskMode ? 'kiosk_state' : 'state') !== 'disabled')
        .map(methodField => {
          const field = fieldTypes[camelCase(methodField.get('name'))];
          const required = methodField.get(kioskMode ? 'kiosk_state' : 'state') === 'required';

          return fromJS({
            ...field,
            required,
            sortOrder: methodField.get('sort_order'),
            ...(required ? {} : { helpMessage: field.optionalHelpMessage }),
          });
        })
    );

    // this is smelly and should be updated
    // Ideally, the api should specify whether to use a fulfilmentDateTimeCollection or a fulfilmentDateTimeDelivery
    if (
      fields.find(field => field.get('name') === 'fulfilmentDateTime') && // if fulfilmentDateTime exists in fields
      methodFields.find(methodField => methodField.get('name').toLowerCase().includes('collection')) // if method is of collection type
    )
      fields = fields
        .filter(field => field.get('name') !== 'fulfilmentDateTime')
        .push(fromJS({ ...fieldTypes.fulfilmentDateTime, intlIdentifier: 'collection' }));

    fields = fields.push(immutableFieldTypes.get('fulfilmentNotes'));

    if (selectBlockRequired(state)) fields = fields.push(immutableFieldTypes.get('block'));
    if (serviceAcceptsOrderNotes(state, getServiceId(state)))
      fields = fields.push(immutableFieldTypes.get('notes'));

    if (selectPreorderEnabled(state) && !fields.find(field => field.get('name') === 'fulfilmentDateTime')) {
      fields = fields.push(immutableFieldTypes.get('fulfilmentDateTime'));
    }

    if (!enableCollectionTime(state) && fields.find(field => field.get('name') === 'fulfilmentDateTime')) {
      const collectionTimeIndex = fields.findIndex(f => f.get('name') === 'fulfilmentDateTime');
      fields = fields.delete(collectionTimeIndex);
    }

    if (paymentsContainSiru(selectPaymentMethods(state)) && fulfilmentId === 2) {
      fields = fields.push(
        fromJS({
          ...fieldTypes.telephone,
          sortOrder: 350,
          required: true,
        })
      );
    }

    const paymentTotal = selectOrderFinalTotal(state);

    if (paymentTotal > 0) {
      fields = fields.push(fromJS(fieldTypes.payment));
    }

    fields = fields.push(immutableFieldTypes.get('voucher'));
    fields = fields.push(immutableFieldTypes.get('terms'));

    if (selectLoyaltyTypeByService(state, getServiceId(state))) {
      fields = fields.push(immutableFieldTypes.get('loyalty'));
    }

    if (showAllergenCheckbox(state)) {
      fields = fields.push(immutableFieldTypes.get('allergenCheckbox'));
    }
    if (getVenueMarketingConsent(state)) {
      fields = fields.push(immutableFieldTypes.get('consentCheckbox'));
    }
    if (requireTelephone(state)) {
      const telephoneIndex = fields.findIndex(field => field.get('name') === 'telephone');
      if (telephoneIndex !== -1) {
        fields = fields.setIn([telephoneIndex, 'required'], true);
      }
    }

    if (isLoggedIn(state)) {
      fields = fields.filter(field => {
        if (!field.get('userDetail')) return true;
        const detailName = field.get('name');
        const detail = selectUserDetail(state, detailName);
        return !hasValue(detail);
      });
      fields = fields.push(immutableFieldTypes.get('thirdPartyPermission'));
    }

    if (fulfilmentId === 2 && getIsStaff(state)) {
      const tableNumberIndex = fields.findIndex(field => field.get('name') === 'tableNumber');

      if (tableNumberIndex !== -1) {
        fields = fields.setIn([tableNumberIndex, 'storeDetail'], false);
      }
    }

    if (fulfilmentId === 3) {
      if (!isLoggedIn(state) || (isLoggedIn(state) && selectAddressesList(state)?.size === 0)) {
        if (isDeliveryToAddressEnabled(state) && selectFulfilmentMethodRadius(state) !== 0) {
          fields = fields.filter(field => !field.get('addressDetail') || field.get('radiusDetail'));

          fields = fields.push(
            fromJS({
              ...fieldTypes.addressInRadius,
              sortOrder: 900,
            })
          );
        }
      }

      if (isLoggedIn(state) && selectAddressesList(state)?.size === 0) {
        fields = fields.push(
          fromJS({
            ...fieldTypes.addressLabel,
            sortOrder: 550,
          })
        );
      }

      if (isLoggedIn(state) && selectAddressesList(state)?.size > 0) {
        fields = fields.filter(field => !field.get('addressDetail'));

        fields = fields.push(
          fromJS({
            ...fieldTypes.addressSelection,
            sortOrder: 1400,
          })
        );
      }
    }

    const coversIndex = fields.findIndex(field => field.get('name') === 'covers');

    if (coversIndex !== -1) {
      if (disableCovers(state) || noFoodInCart(state)) {
        fields = fields.setIn([coversIndex, 'hidden'], true);
      }
    }
    if (selectFulfilmentMethodRequireCovers(state) && coversIndex === -1) {
      fields = fields.push(immutableFieldTypes.get('covers'));
    }
    if (selectFulfilmentMethodRequireCarMake(state)) {
      fields = fields.push(immutableFieldTypes.get('carMake'));
    }
    if (selectFulfilmentMethodRequireCarModel(state)) {
      fields = fields.push(immutableFieldTypes.get('carModel'));
    }
    if (selectFulfilmentMethodRequireCarColour(state)) {
      fields = fields.push(immutableFieldTypes.get('carColour'));
    }
    if (selectFulfilmentMethodRequireBayNumber(state)) {
      fields = fields.push(immutableFieldTypes.get('bayNumber'));
    }
    if (selectFulfilmentMethodDisplayCutleryQuantity(state)) {
      fields = fields.push(immutableFieldTypes.get('cutlery'));
    }

    const customFields = getFormattedCustomFields(state);
    const fieldsInclCustom = fields.concat(customFields);

    return getSortedFields(fieldsInclCustom.toJS());
  }
};

export const getFormattedCustomFields = state => {
  const customFields = getCustomFields(state);
  const locale = getLocale(state);
  const fulfilmentId = selectFulfilmentMethodId(state);

  return customFieldsFormatter(customFields, locale, fulfilmentId);
};

export const getSortedFields = fields =>
  fields
    .filter(field => {
      if (field.sortOrder || field.sortOrder === 99999) return true;
      return false; // this is so we can filter custom fields out if doesn't have a position
    })
    .sort((a, b) => a.sortOrder - b.sortOrder);

export const getCheckoutDetail = state => index => {
  const fulfilmentId = selectFulfilmentMethod(state).get('fulfilment_method_id').toString();
  return state.getIn(['fulfilment', 'fulfilmentForms', fulfilmentId, 'fields', index, 'value']);
};

export default (state = initialState, action) => {
  switch (action.type) {
    /*
    case '@@CLIENT_INIT':
      return state.mergeDeep({
        fulfilmentForms: initialDetails,
      });
    case SET_FULFILMENTDETAILS: {
      return state.mergeDeep({
        fulfilmentForms: action.fulfilmentForms,
      });
    }
    */
    default:
      return state;
  }
};
