import styled from 'styled-components';
import { H1 } from 'components/Typography';

export const HeaderStyled = styled.div`
  position: relative;
  margin: 23px 0;
`;

export const HeaderContainer = styled.div`
  text-align: center;
  padding: 0 10px;
`;

export const HeaderTitle = styled(H1)`
  font-weight: ${({ settingsPage }) => (settingsPage ? 'normal' : 'bold')};
  margin: 0 auto;
  padding: 0 35px;
  color: ${({ theme }) => theme.color.primaryText};
`;

export const BackButtonContainer = styled.div`
  position: absolute;
  left: 10px;
  top: 0;
`;
