import styled, { css } from 'styled-components';

export const Container = styled.div`
  color: ${({ theme }) => theme.color.primaryText};
  display: inline-block;
  padding: 5px;
  width: 100%;
  font-size: 16px;
  text-align: center;
  margin: auto;
`;

const button = css`
  width: 35px;
  height: 35px;
  color: #fff;
`;

export const MinusButton = styled.button`
  ${button}
  background: ${({ theme }) => theme.buttons.quantityMinus};
`;

export const PlusButton = styled.button`
  ${button}
  background: ${({ theme }) => theme.buttons.quantityPlus};
`;

export const Quantity = styled.div`
  display: inline-block;
  font-size: 1.25em;
  padding: 5px 15px;
  position: relative;
  top: 3px;
`;

export const HiddenQuantityText = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;
