import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Page from 'components/Pages/container';
import PaymentInformation from 'components/PaymentInformation';
import CartFullIcon from 'components/Icons/CartIcon';
import CartEmptyIcon from 'components/Icons/CartEmptyIcon';
import Order from 'components/Order';
import DineInConfirmation from 'components/DineInConfirmation';
import { isDineInOrderingFlow } from 'selectors/root';
import { orderQuantity as selectOrderQuantity, selectOrder, selectOrderTotal } from 'selectors/order';
import globalMessages from 'components/globalMessages';
import orderReviewMessages from 'components/OrderReview/messages';
import CheckoutButton from 'components/Order/CheckoutButton';
import { sendEventBeginCheckout } from 'middleware/analyticsMiddleware';
import { StickyButton } from 'components/Form';
import styled from 'styled-components';
import FormattedPrice from '../../FormattedPrice';

const propTypes = {
  orderQuantity: PropTypes.number,
  isDineInOrderingFlow: PropTypes.bool,
  sendState: PropTypes.func,
};

const OrderReview = ({ orderQuantity, isDineInOrderingFlow, sendState }) => {
  const CartIcon = orderQuantity > 0 ? CartFullIcon : CartEmptyIcon;
  const orderTotal = useSelector(selectOrderTotal);

  const OrderInfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border-top: 1px solid ${({ theme }) => theme.color.borderColor};
  `;

  return (
    <Page
      settingsPage={true}
      titleMessage={globalMessages.orderSummary}
      Icon={CartIcon}
      hideCartButton={true}
    >
      <div className="container">
        <Order />
        {isDineInOrderingFlow ? (
          <DineInConfirmation />
        ) : (
          <>
            <StickyButton>
              <OrderInfoContainer className="primaryBackground">
                <div>
                  <FormattedMessage {...orderReviewMessages.subTotal} />
                </div>
                <div>
                  <FormattedPrice value={orderTotal} />
                </div>
              </OrderInfoContainer>
              <CheckoutButton onClick={sendState} />
            </StickyButton>
            <PaymentInformation />
          </>
        )}
      </div>
    </Page>
  );
};

OrderReview.propTypes = propTypes;

const mapStateToProps = (state, { params }) => ({
  orderQuantity: selectOrderQuantity(state),
  isDineInOrderingFlow: isDineInOrderingFlow(state, params?.serviceId),
  order: selectOrder(state),
  sendState: () => sendEventBeginCheckout(state),
});

export default connect(mapStateToProps)(OrderReview);
