import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MenuButton from 'components/MenuButton';
import CartButton from 'components/CartButton';
import { orderReview } from 'actions/order';
import { openClearOrderAlert, openSelectLanguageAlert } from 'actions/UI';
import { selectSidebarIsOpen } from 'selectors/sidebar';
import { kioskModeEnabled, showExitButton, isDarkModeEnabled } from 'selectors/features';
import KioskExitButton from 'components/KioskExitButton';
import LanguageIcon from 'components/Icons/LanguageIcon';
import { trackEvent } from 'utils/tracking';
import image from 'utils/image';

import { getLocales } from 'selectors/root';
import { openLoyaltyModal } from 'actions/loyalty';
import { selectServiceId } from 'selectors/browse';
import { selectLoyaltyTypeByService } from 'selectors/loyalty';
import { HeaderFixed, LogoContainer, StyledLink, LogoImg, ButtonsContainer, HeaderButton } from './styles';
import LoyaltyButton from '../LoyaltyButton';
import { addCssPrefixTo } from '../../utils';

if (typeof window !== 'undefined') {
  window.requestAnimFrame = (function reqAnimFrame() {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      (callback => {
        window.setTimeout(callback, 1000 / 60);
      })
    );
  })();
}

const Header = ({
  logoUrl,
  hideCartButton,
  hideSidebar,
  companyName,
  openOrder,
  sidebarOpen,
  showExitButton,
  openSelectLanguageAlert,
  showLanguageButton,
  openLoyaltyModal,
  loyaltyType,
  darkMode,
  kioskMode,
}) => (
  <HeaderFixed
    sidebarOpen={sidebarOpen}
    className={`primaryBackground primaryColor ${addCssPrefixTo('FIXED_HEADER')}`}
  >
    {kioskMode ? (
      <>
        {showExitButton && <KioskExitButton />}
        <LogoContainer>
          <StyledLink to="/">
            <LogoImg
              src={image(logoUrl, { height: 38 }) ?? (darkMode ? '/whiteLogo.png' : '/logo.png')}
              alt={`${companyName} Logo`}
            />
          </StyledLink>
        </LogoContainer>
        <div style={{ display: 'flex' }}>
          {!hideCartButton && (
            <HeaderButton onClick={openOrder}>
              <div
                onClick={() =>
                  trackEvent('initiate_checkout', {
                    category: 'checkout',
                    location: 'header',
                  })
                }
              >
                <CartButton className="primaryBackground" />
              </div>
            </HeaderButton>
          )}

          <ButtonsContainer>
            {!hideSidebar && <MenuButton customCssClass="menuBarColor" />}

            {showLanguageButton && (
              <HeaderButton onClick={openSelectLanguageAlert}>
                <LanguageIcon />
              </HeaderButton>
            )}
            {loyaltyType && (
              <HeaderButton onClick={openLoyaltyModal}>
                <LoyaltyButton className="primaryBackground" />
              </HeaderButton>
            )}
          </ButtonsContainer>
        </div>
      </>
    ) : (
      <>
        <ButtonsContainer position="left">
          {loyaltyType && (
            <HeaderButton onClick={openLoyaltyModal}>
              <LoyaltyButton className="primaryBackground" />
            </HeaderButton>
          )}
        </ButtonsContainer>
        <LogoContainer>
          <StyledLink to="/">
            <LogoImg src={image(logoUrl, { height: 38 }) ?? (darkMode ? '/whiteLogo.png' : '/logo.png')} />
          </StyledLink>
        </LogoContainer>
        <ButtonsContainer position="right">
          {!hideSidebar && <MenuButton customCssClass="menuBarColor" />}
          {!hideCartButton && (
            <HeaderButton onClick={openOrder}>
              <div
                onClick={() =>
                  trackEvent('initiate_checkout', {
                    category: 'checkout',
                    location: 'header',
                  })
                }
              >
                <CartButton className="primaryBackground" />
              </div>
            </HeaderButton>
          )}
        </ButtonsContainer>
      </>
    )}
  </HeaderFixed>
);

Header.propTypes = {
  logoUrl: PropTypes.string,
  hideCartButton: PropTypes.bool,
  hideSidebar: PropTypes.bool,
  companyName: PropTypes.string,
  openOrder: PropTypes.func,
  sidebarOpen: PropTypes.bool,
  showExitButton: PropTypes.bool,
  openSelectLanguageAlert: PropTypes.func,
  showLanguageButton: PropTypes.bool,
  openLoyaltyModal: PropTypes.func,
  loyaltyType: PropTypes.string,
  darkMode: PropTypes.bool,
  kioskMode: PropTypes.bool,
};

const mapStateToProps = state => {
  const serviceId = selectServiceId(state);

  return {
    logoUrl: state.getIn(['branding', 'app_logo_path']),
    companyName: state.getIn(['venue', 'name']),
    sidebarOpen: selectSidebarIsOpen(state),
    kioskMode: kioskModeEnabled(state),
    showExitButton: kioskModeEnabled(state) || showExitButton(state),
    showLanguageButton: kioskModeEnabled(state) && getLocales(state).size > 1,
    loyaltyType: selectLoyaltyTypeByService(state, serviceId),
    darkMode: isDarkModeEnabled(state),
  };
};

export default connect(mapStateToProps, {
  openOrder: orderReview,
  openLoyaltyModal,
  openClearOrderAlert,
  openSelectLanguageAlert,
})(Header);
