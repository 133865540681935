import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router';
import NextAvailableMessage from 'components/NextAvailableMessage';

interface Props {
  url: string;
  image: string;
  text: string;
  onClick?: () => void;
  availableTime?: {
    time: number;
    date: string;
  } | null;
  filtered?: boolean;
}

const Container = styled(Link)<{ background }>`
  aspect-ratio: 1 / 1;
  background: ${({ theme }) => theme.color.brandPrimary};
  display: block;
  position: relative;
  width: calc(100% - 20px);
  margin: 0 10px;

  @media (min-width: 480px) {
    aspect-ratio: 16 / 9;
  }

  ${({ background }) =>
    background &&
    css`
      background: url(${background});
      background-size: cover;
    `}
`;

const Backdrop = styled.div<{ filtered }>`
  background: rgba(0, 0, 0, 50%);
  inset: 0;
  position: absolute;

  ${({ filtered }) =>
    filtered &&
    css`
      background: rgb(202 202 202 / 52%);
    `}
`;

const Text = styled.div<{ filtered }>`
  border: 1px white solid;
  color: white;
  display: grid;
  font-size: 1.33rem;
  inset: 10%;
  place-items: center;
  position: absolute;
  text-align: center;
  padding: 5px;

  @media (min-width: 480px) {
    font-size: 1.7rem;
    inset: 0;
    margin: 3rem;
  }

  @media (min-width: 768px) {
    font-size: 2.13rem;
  }

  ${({ filtered }) =>
    filtered &&
    css`
      opacity: 0.5;
    `}
`;

export const ActiveIndicator = styled.div`
  font-size: 0.929em;
  line-height: 0.929em;
  padding: 5px;
  margin: 0 10px -20px;
  text-align: center;
  position: relative;
  border: 1px solid ${({ theme }) => theme.color.borderGray};
  z-index: ${({ theme }) => theme.zIndex.one};
`;

const HeroLink: React.FC<Props> = ({ image, text, url, onClick, availableTime, filtered = false }) => (
  <>
    {availableTime && (
      <ActiveIndicator className="primaryBackground primaryColor">
        <NextAvailableMessage
          nextAvailableTime={{ type: 'menu', time: availableTime.time, date: availableTime.date }}
        />
      </ActiveIndicator>
    )}
    <Container background={image} to={url} onClick={onClick}>
      <Backdrop filtered={filtered}>
        <Text filtered={filtered}>{text}</Text>
      </Backdrop>
    </Container>
  </>
);

export default HeroLink;
