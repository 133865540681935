import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';

import { shouldShowPriceExcludingTax } from 'selectors/features';
import { selectVenueCheckoutTerms } from 'selectors/root';
import { canShowCouponField } from 'selectors/order';
import { orderReview } from 'actions/order';

import globalMessages from 'components/globalMessages';
import Donations from 'components/Donations';
import { SummaryContainer, Title, StyledTaxBreakdown } from './styles';
import { CheckoutTerms } from '../styles';

import messages from '../messages';
import ProductLineItem from './ProductLineItem';
import PriceLineItem from './PriceLineItem';
import TaxBreakdown from '../TaxBreakdown';

const OrderSummary = ({
  productItems,
  promotionalItems,
  chargeItems,
  orderTotal,
  taxTotal,
  taxBreakdown,
  checkoutTerms,
  openCart,
  showEdit,
  isTaxExcluded,
  isTab,
}) => {
  const { tabSummary, orderSummary } = globalMessages;

  return (
    <>
      <SummaryContainer>
        <Title>
          <FormattedMessage {...(isTab ? tabSummary : orderSummary)} />
        </Title>
        {!productItems.size ? (
          <p>
            <FormattedMessage {...messages.emptyOrder} />
          </p>
        ) : (
          <div>
            {productItems.map((item, index) =>
              item.get('key') === 'donation' ? null : <ProductLineItem key={index} item={item} />
            )}

            {promotionalItems.map((item, index) => (
              <ProductLineItem key={index} item={item} />
            ))}

            {chargeItems.map((item, index) => (
              <PriceLineItem key={index} text={item.get('name')} price={item.get('unit_price')} />
            ))}

            <Donations />

            <PriceLineItem text={<FormattedMessage {...messages.subTotal} />} price={orderTotal} />

            {taxTotal !== 0 && (
              <StyledTaxBreakdown>
                <TaxBreakdown taxBreakdown={taxBreakdown} taxTotal={taxTotal} isTaxExcluded={isTaxExcluded} />
              </StyledTaxBreakdown>
            )}

            {checkoutTerms && <CheckoutTerms dangerouslySetInnerHTML={{ __html: checkoutTerms }} />}
          </div>
        )}
      </SummaryContainer>

      {showEdit && (
        <button className="u-textUnderline" type="button" onClick={openCart}>
          <FormattedMessage {...messages.editOrder} />
        </button>
      )}
    </>
  );
};

OrderSummary.defaultProps = {
  showEdit: true,
  showCoupon: false,
  productItems: new List(),
  chargeItems: new List(),
  promotionalItems: new List(),
  taxTotal: 0,
  taxBreakdown: new List(),
  isTab: false,
};

OrderSummary.propTypes = {
  showCoupon: PropTypes.bool,
  productItems: PropTypes.instanceOf(List),
  chargeItems: PropTypes.instanceOf(List),
  promotionalItems: PropTypes.instanceOf(List),
  orderTotal: PropTypes.number.isRequired,
  taxTotal: PropTypes.number,
  taxBreakdown: PropTypes.instanceOf(List),
  checkoutTerms: PropTypes.string,
  openCart: PropTypes.func,
  showEdit: PropTypes.bool,
  isTaxExcluded: PropTypes.bool,
  isTab: PropTypes.bool,
};

export default connect(
  (state, { showCoupon }) => ({
    showCoupon: showCoupon && canShowCouponField(state),
    checkoutTerms: selectVenueCheckoutTerms(state),
    isTaxExcluded: shouldShowPriceExcludingTax(state),
  }),
  { openCart: orderReview }
)(OrderSummary);
