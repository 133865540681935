import { defineMessages } from 'react-intl';

export default defineMessages({
  product_title: {
    defaultMessage: 'Customise {itemName}',
  },
  product_edit_title: {
    defaultMessage: 'Change Item Options',
  },
  product_option_title: {
    defaultMessage: 'Customise Item',
  },
  customise_item: {
    defaultMessage: 'Customise your selected item',
  },
  customise_item_step_indicator: {
    defaultMessage: 'Step {page} of {total}',
  },
  error_header: {
    defaultMessage: 'Error',
  },
  error_required: {
    defaultMessage: 'This option is required',
  },
  error_minimum: {
    defaultMessage: 'Please select at least {minimum} options',
  },
  update_item: {
    defaultMessage: 'Update Item',
  },
  add_option: {
    defaultMessage: 'Add Option',
  },
  remove_option: {
    defaultMessage: 'Remove Option',
  },
});
