import styled, { css } from 'styled-components';

export const LineItemContainer = styled.div`
  padding: 15px 10px;
  font-size: 16px;
  display: flex;
  color: ${({ theme }) => theme.color.primaryText};
`;

export const OrderLineItemContainer = styled(LineItemContainer)`
  flex-direction: column;
`;

export const LineItemPrice = styled.span`
  font-weight: bold;
`;

export const TextButtonContainer = styled.div`
  color: ${({ theme }) => theme.color.brandSecondary};
  text-transform: uppercase;
  padding: 0 15px;
  cursor: pointer;

  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.color.borderColor};
  }

  &:first-child {
    padding-left: 0;
  }
`;

export const TextButtonsContainer = styled.div`
  display: flex;
`;

export const Modifier = styled.div`
  font-style: italic;
  font-size: 13px;
  color: ${({ theme }) => theme.color.text};
`;

export const ModifierPrice = styled.div`
  float: right;
`;

export const ItemDetails = styled.div`
  display: flex;
`;

export const StyledName = styled.div`
  display: inline-block;
  padding-right: 70px;
  margin-bottom: 5px;
  flex-grow: 1;

  html[dir='rtl'] & {
    padding-right: 0;
    padding-left: 70px;
  }
`;

export const sharedLabelStyles = css`
  text-align: right;
  flex-grow: 1;
  padding: 0 20px;
`;

export const PriceLabel = styled.span`
  font-weight: bold;
  ${sharedLabelStyles};
`;

export const Price = styled.div`
  min-width: 75px;
  text-align: right;
  font-weight: bold;

  html[dir='rtl'] & {
    text-align: left;
  }
`;
