import React from 'react';
import { Map } from 'immutable';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Details, ProductName } from '../Product/ProductDetails/styles';
import SuitableFor from '../SuitableFor';
import { Chilli } from '../Chilli';
import ProductPrice from '../Product/price';
import BaseModifier from '../Product/BaseModifier';
import StandardButtons from '../Product/ProductAdditional/StandardButtons';
import image from '../../utils/image';
import BackIcon from '../Icons/BackIcon';
import { H1 } from '../Typography';
import { getLocale } from '../../selectors/user';

const StyledH1 = styled(H1)`
  display: inline-block;
  line-height: 1.2;
  margin-right: 10px;
`;

export const Description = styled.p`
  white-space: pre-line;
  margin: 10px 0;
`;

export const DescriptionContainer = styled.div`
  margin-bottom: 20px;
`;

export const Price = styled.p`
  color: ${({ theme }) => theme.color.primaryText};
  font-size: ${({ theme }) => theme.fonts.fontSizeBigOne};
  position: relative;
  float: right;
  padding-top: 10px;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 340px;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const FlexContainer = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
`;

const Content = styled.div`
  padding-top: 1px;
  position: relative;
  text-align: left;
  flex: 1;
  /* Bottom is size of alert box bottom buttons, plus a bit of spacing */
  margin: 0 15px 126px;
`;

interface ProductDetailsProps {
  product: any;
  price: number;
  additionalButtons: any[];
  productDescription: string;
  productImages: any[];
  baseModifier?: Record<string, any>;
  selectedBaseModifierId: number;
  pageView?: boolean;
  changeModifier: (modifier: any) => void;
  notesState?: [string, React.Dispatch<React.SetStateAction<string>>];
  browseOnly?: boolean;
  onClose?: () => void;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({
  productDescription,
  productImages,
  product,
  price,
  baseModifier,
  selectedBaseModifierId,
  changeModifier,
  pageView = false,
  additionalButtons = [],
  notesState,
  browseOnly = false,
  onClose,
}) => {
  const currentLocale = useSelector(getLocale);
  const productName = product?.translations?.[currentLocale]?.name ?? product.name;

  return (
    <Details pageView={pageView}>
      {productImages.length > 0 && (
        <ImageContainer>
          <img src={image(productImages[0].path, { width: 800 })} alt={productName} />
        </ImageContainer>
      )}
      <Content>
        <FlexContainer>
          {onClose && <BackIcon onClick={onClose} />}
          <SuitableFor product={Map(product)} />
        </FlexContainer>
        <ProductName>
          <StyledH1>{productName}</StyledH1>
          <Chilli product={Map(product)} />
        </ProductName>
        <DescriptionContainer>
          <Description className="description">{productDescription}</Description>
          <BaseModifier
            baseModifier={baseModifier}
            selectedId={selectedBaseModifierId}
            changeModifier={changeModifier}
          />
        </DescriptionContainer>
        <Price>
          <ProductPrice price={price} />
        </Price>
        <StandardButtons
          browseOnly={browseOnly}
          notesState={notesState}
          product={Map(product)}
          additionalButtons={additionalButtons}
        />
      </Content>
    </Details>
  );
};

export default ProductDetails;
