import HeroLink from 'components/HeroLink';
import { filterServicesByCategory } from 'components/Pages/Browse/filterCategoryServices';
import Page from 'components/Pages/container';

// import { filterServicesByCategory } from 'components/Pages/Browse/filterCategoryServices';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { selectMenuTypeId, selectServicesForMenuType } from 'selectors/browse';
import styled from 'styled-components';
import { trackEvent } from 'utils';
import { setUserProperties } from 'utils/tracking';
import { defineMessages, FormattedMessage } from 'react-intl';
import { partition } from 'lodash';
import { browserHistory } from 'react-router';
import { setMenuTypeId } from '../../../server/middleware/api/controllers/browse';
import { selectCompletedOrder } from '../../reducers/root';
import { highlightLastUsedService } from '../../selectors/features';

interface Service {
  id: string;
  images: { path: string }[];
  default_image: string;
  name: string;
  menu_types: { id: number }[];
}

interface Props {
  services: Service[];
  lastOrderedService: Service;
  setMenuTypeId: (id: number) => void;
}

const ServicesList = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  margin-top: 20px;
`;

const messages = defineMessages({
  lastOrderedFrom: {
    defaultMessage: 'Last ordered from:',
  },
  otherLocations: {
    defaultMessage: 'Other locations:',
  },
});

const ServiceSelectionHeader = styled.p`
  text-align: left;
  font-weight: bold;
`;

const ServiceSelection: React.FC<Props> = ({ services, setMenuTypeId, lastOrderedService }) => {
  const totalServices = services.length + (lastOrderedService ? 1 : 0);

  useEffect(() => {
    if (totalServices === 1) {
      if (lastOrderedService) {
        browserHistory.replace(`/service/${lastOrderedService.id}`);
      } else {
        browserHistory.replace(`/service/${services[0]?.id}`);
      }
    }
  }, [services, lastOrderedService]);

  return (
    <Page headerOnlyTitle={true} hideBackButton={true}>
      <ServicesList className="container">
        {lastOrderedService && (
          <>
            <ServiceSelectionHeader>
              <FormattedMessage {...messages.lastOrderedFrom} />
            </ServiceSelectionHeader>
            <HeroLink
              key={`service-${lastOrderedService.id}`}
              url={`/service/${lastOrderedService.id}`}
              image={lastOrderedService.images?.[0]?.path ?? lastOrderedService.default_image}
              text={lastOrderedService.name}
              onClick={() => {
                trackEvent('select_service', {
                  category: 'service',
                  service: lastOrderedService.id,
                });

                setUserProperties({
                  service: lastOrderedService.id,
                });

                if (lastOrderedService.menu_types.length === 1)
                  setMenuTypeId(lastOrderedService.menu_types[0].id);
              }}
            />
            <ServiceSelectionHeader>
              <FormattedMessage {...messages.otherLocations} />
            </ServiceSelectionHeader>
          </>
        )}
        {services.map(service => (
          <HeroLink
            key={`service-${service.id}`}
            url={`/service/${service.id}`}
            image={service.images?.[0]?.path ?? service.default_image}
            text={service.name}
            onClick={() => {
              trackEvent('select_service', {
                category: 'service',
                service: service.id,
              });

              setUserProperties({
                service: service.id,
              });

              if (service.menu_types.length === 1) setMenuTypeId(service.menu_types[0].id);
            }}
          />
        ))}
      </ServicesList>
    </Page>
  );
};

export default connect(
  (state, { params }: { params: { categoryId: string } }) => {
    const menuTypeId = selectMenuTypeId(state);
    const allServices = selectServicesForMenuType(state, menuTypeId);

    if (params?.categoryId) {
      const servicesInCategory = filterServicesByCategory(Number(params.categoryId), allServices);
      return { services: servicesInCategory, backLink: '/service-categories' };
    }

    if (!highlightLastUsedService(state)) {
      return { services: allServices };
    }

    const [[lastOrderedService], services] = partition(
      allServices,
      service => selectCompletedOrder(state).get('service') === Number(service.id)
    );

    return {
      lastOrderedService,
      services,
    };
  },
  { setMenuTypeId }
)(ServiceSelection);
