import styled, { css } from 'styled-components';
import { Link } from 'react-router';
import { fontSizeBig } from 'assets/styles/fonts';

const ButtonStyled = css`
  cursor: pointer;
  display: block;
  margin: 0;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  padding: 20px;
  background-color: ${({ theme }) => theme.buttons.defaultBg};
  color: ${({ theme }) => theme.buttons.defaultColor};
  border: 1px solid ${({ theme }) => theme.buttons.defaultBorder};
  min-height: 48px;

  svg {
    height: 1.143em;
    margin: 0 5px;
  }

  ${props =>
    props.primary &&
    css`
      background-color: ${({ theme }) => theme.buttons.primaryBg};
      color: ${({ theme }) => theme.buttons.primaryColor};
      border: 1px solid ${({ theme }) => theme.buttons.primaryBorder};
    `}

  ${props =>
    props.danger &&
    css`
      background-color: ${({ theme }) => theme.buttons.dangerBg};
      color: ${({ theme }) => theme.buttons.dangerColor};
      border: 1px solid ${({ theme }) => theme.buttons.dangerBorder};
    `}

  ${props =>
    props.disabled &&
    css`
      background: #999 !important;
      color: #fff;
      cursor: not-allowed;
    `}

  ${props =>
    props.loading &&
    css`
      padding: 10px !important;
      text-align: center;

      span {
        &::before {
          content: '';
          display: inline-block;
          border: 3px solid rgba(255, 255, 255, 0.8);
          -webkit-border-radius: 30px;
          height: 18px;
          width: 18px;
          position: relative;
          top: 4px;
          margin-right: 5px;
          animation: pulsate 1s ease-out;
          animation-iteration-count: infinite;
          opacity: 0;
        }
      }
    `}

  ${fontSizeBig};
`;

export const Container = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButton = styled.button`
  ${ButtonStyled}
`;

export const StyledInlineButton = styled.button`
  ${ButtonStyled} {
  }
`;

export const StyledLink = styled(Link)`
  ${ButtonStyled}
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledAnchor = styled.a`
  ${ButtonStyled}
  display: flex;
  align-items: center;
  justify-content: center;
`;
