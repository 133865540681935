import styled, { css } from 'styled-components';

import { AlertButtons, AlertContent } from 'assets/styles/sharedStyles';

export const StyledContent = styled(AlertContent)`
  ${props =>
    props.pageView &&
    css`
      overflow: initial;
      padding-top: 1rem;
    `}
`;

export const FlexBox = styled.div`
  @media (max-width: 767px) {
    padding: 5px 0;
  }

  @media (min-width: 768px) {
    padding-right: 10px;
    width: 30%;
    margin: 10px;
    height: 50%;
  }
`;

export const NotesFlexBox = styled(FlexBox)`
  text-align: left;

  html[dir='rtl'] & {
    text-align: right;
  }
`;

export const ButtonsContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.borderColor};
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: column-reverse;
  position: fixed;
  bottom: 65px;
  background: ${({ theme }) => theme.color.primaryBackground};
  z-index: 30;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 10px 20px 0;
    bottom: 85px;
  }
`;

export const AddNotesBtn = styled.button`
  color: #999;

  @media (min-width: 768px) {
    float: left;
    padding: 3px;
    margin: 0;

    html[dir='rtl'] & {
      float: right;
    }
  }
`;

export const StyledIcon = styled.div`
  display: inline-block;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.color.systemIcon};
  padding: 5px;
  width: 38px;
  height: 38px;
  margin: 0 5px;
`;

export const NotesTxt = styled.span`
  line-height: 38px;

  @media (min-width: 768px) {
    float: none;
  }
`;

export const StyledActionButtons = styled(AlertButtons)`
  margin-top: 20px;
  flex-direction: ${({ kiosk }) => kiosk && 'column-reverse'};
  display: flex;
  gap: 1px;

  ${props =>
    props.pageView &&
    css`
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      right: 0;
      margin: 0;
      z-index: ${({ theme }) => theme.zIndex.five};
      background: ${({ theme }) => theme.color.primaryBackground};
    `};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column-reverse;
  padding: 10px;
`;
