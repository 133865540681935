import styled from 'styled-components';
import { H4 } from 'components/Typography';

import { linkUnderlined } from 'assets/styles/linkStyles';
import { LineItemContainer, sharedLabelStyles } from './OrderItem/styles';
import { TaxLabel } from './TaxBreakdown';

export const Title = styled(H4)`
  font-weight: 400;
  margin-bottom: 10px;
  text-align: left;
  color: ${({ theme }) => theme.color.primaryText};
  padding-bottom: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.color.borderColor};

  html[dir='rtl'] & {
    text-align: right;
  }
`;

export const TaxBreakdownContainer = styled(LineItemContainer)`
  flex-direction: column;

  ${TaxLabel} {
    ${sharedLabelStyles}
  }
`;

export const ReviewContainer = styled.div`
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 5px;
`;

export const CheckoutTerms = styled.p`
  font-size: 13px;
  text-align: center;
  font-style: italic;
  padding: 10px 0;
  margin-top: 10px;
  word-wrap: break-word;
  word-break: break-word;

  a {
    ${linkUnderlined}
  }
`;

export const Summary = styled.div`
  @media (min-width: 800px) {
    display: none;
  }
`;

export const SubtotalContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.borderColor};
`;
