import { fetchHelper } from 'utils';
import { selectTabId } from 'selectors/session';
import { selectCompletedOrderTabId } from 'reducers/root';
import { SET_TAB_ORDERS } from 'actions/session/constants';

export const fetchTab = () => (dispatch, getState) => {
  const state = getState();
  const tabId = selectTabId(state) || selectCompletedOrderTabId(state);
  if (!tabId) return false;

  const success = res => {
    dispatch({
      type: SET_TAB_ORDERS,
      tabId,
      orders: res.orders,
      state: res.state,
    });
  };

  return fetchHelper(`/api/tabs/${tabId}`, 'GET', null, success, () => ({}));
};
