import React from 'react';
import PropTypes from 'prop-types';

import { Container, Option, OptionContainer, BaseModifierIconContainer, BaseModifierIcon } from './styles';

const BaseModifier = ({ baseModifier, selectedId, changeModifier }) => {
  if (!baseModifier) return null;

  return (
    <Container>
      {baseModifier.get('values').map(modifierValue => (
        <OptionContainer className={modifierValue.get('id') === selectedId ? 'selected' : ''}>
          {modifierValue.get('icon') !== null && (
            <BaseModifierIconContainer>
              <BaseModifierIcon
                src={modifierValue.getIn(['icon', 'path'])}
                alt={modifierValue.getIn(['icon', 'alt'])}
              />
            </BaseModifierIconContainer>
          )}
          <Option key={modifierValue.get('id')} onClick={() => changeModifier(modifierValue)}>
            <span>{modifierValue.get('value')}</span>
          </Option>
        </OptionContainer>
      ))}
    </Container>
  );
};

BaseModifier.propTypes = {
  baseModifier: PropTypes.object,
  selectedId: PropTypes.number,
  changeModifier: PropTypes.func,
};

export default BaseModifier;
