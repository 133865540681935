import React from 'react';
import { Map, List } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import ProductDetails from 'components/Product/ProductDetails';
import { AlertContent, AlertFooter, AlertButtons } from 'assets/styles/sharedStyles';
import ActionButton from 'components/ActionButton';
import { getModifierPrice } from 'selectors/browse';
import globalMessages from 'components/globalMessages';
import messages from './messages';

const DetailsContainer = styled.div`
  @media (max-width: 767px) {
    position: fixed;
    left: 0;
  }
`;

const ModifierProductInfo = ({
  modifier,
  taxCategories,
  onCancel,
  settings,
  modifierValues,
  updateValues,
  getModifierPrice,
  browseOnly,
}) => {
  const product = modifier.get('product');
  const isSelected = modifierValues.find(val => val.id === modifier.get('id'));
  let canAdd = true;
  if (
    (settings.get('maximum') && modifierValues.length >= settings.get('maximum')) ||
    (!settings.get('multiple') && !!modifierValues.length)
  ) {
    canAdd = false;
  }

  return (
    <>
      <AlertContent>
        <DetailsContainer>
          <ProductDetails
            product={product}
            price={getModifierPrice(modifier, taxCategories, settings.get('display_type'))}
            productImages={product.get('images')}
            productDescription={product.get('description')}
            pageView={window.innerWidth < 768}
          />
        </DetailsContainer>
      </AlertContent>
      <AlertFooter>
        <AlertButtons>
          <ActionButton
            dangerButton={true}
            onClick={onCancel}
            label={<FormattedMessage {...globalMessages.back} />}
          />
          {isSelected && (
            <ActionButton
              primaryButton={true}
              onClick={() => {
                updateValues(modifierValues.filter(val => val.id !== modifier.get('id')));
                onCancel();
              }}
              label={<FormattedMessage {...messages.remove_option} />}
            />
          )}
          {!isSelected && canAdd && !browseOnly && (
            <ActionButton
              primaryButton={true}
              onClick={() => {
                modifierValues.push(modifier);
                updateValues(modifierValues);
                onCancel();
              }}
              label={<FormattedMessage {...messages.add_option} />}
            />
          )}
        </AlertButtons>
      </AlertFooter>
    </>
  );
};

ModifierProductInfo.propTypes = {
  modifier: PropTypes.instanceOf(Map).isRequired,
  taxCategories: PropTypes.instanceOf(List),
  onCancel: PropTypes.func.isRequired,
  getModifierPrice: PropTypes.func,
  settings: PropTypes.instanceOf(Map).isRequired,
  modifierValues: PropTypes.array,
  updateValues: PropTypes.func,
  browseOnly: PropTypes.bool,
};

const mapStateToProps = state => ({
  getModifierPrice: (modifier, taxCategories, displayType) =>
    getModifierPrice(state, modifier, taxCategories, displayType),
});

export default connect(mapStateToProps)(ModifierProductInfo);
